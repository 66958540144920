import { Injectable } from '@angular/core';
import { AdminService } from '@app/modules/admin/services/admin.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import * as AdminActions from '../actions/admin.actions';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { CoreService } from '@app/modules/core/services/core.service';
import { AppResourceTypes } from '../enums/app-resources-types.enum';
import { Store } from '@ngrx/store';
import { selectAdminState } from '../selectors/admin.selectors';

@Injectable()
export class AdminEffects {

    fetchQRs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminActions.fetchQRs),
            switchMap(({ params }) => from(this.adminService.getUserQRs(params))
                .pipe(
                    map(response => AdminActions.fetchQRsSuccess({ response })),
                    catchError(error => of(AdminActions.fetchQRsFailure({ error: this.coreService.handleError(error) })))
                )
            )
        )
    );

    fetchinvoices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminActions.fetchInvoices),
            switchMap(({ params }) => from(this.adminService.getUserInvoices(params))
                .pipe(
                    map(response => AdminActions.fetchInvoicesSuccess({ response })),
                    catchError(error => of(AdminActions.fetchInvoicesFailure({ error: this.coreService.handleError(error) })))
                )
            )
        )
    );

    fetchreferrals$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminActions.fetchReferrals),
            switchMap(({ params }) => from(this.adminService.getReferrals(params))
                .pipe(
                    map(response => AdminActions.fetchReferralsSuccess({ response })),
                    catchError(error => of(AdminActions.fetchReferralsFailure({ error: this.coreService.handleError(error) })))
                )
            )
        )
    );

    fetchcheckoutsessions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminActions.fetchCheckoutSessions),
            switchMap(({ params }) => from(this.adminService.getCheckoutSessions(params))
                .pipe(
                    map(response => AdminActions.fetchCheckoutSessionsSuccess({ response })),
                    catchError(error => of(AdminActions.fetchCheckoutSessionsFailure({ error: this.coreService.handleError(error) })))
                )
            )
        )
    );

    fetchusers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminActions.fetchUsers),
            switchMap(({ params }) => from(this.adminService.getUsers(params))
                .pipe(
                    map(response => AdminActions.fetchUsersSuccess({ response })),
                    catchError(error => of(AdminActions.fetchUsersFailure({ error: this.coreService.handleError(error) })))
                )
            )
        )
    );

    fetchadmins$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminActions.fetchAdmins),
            switchMap(({ params }) => from(this.adminService.getAdmins(params))
                .pipe(
                    map(response => AdminActions.fetchAdminsSuccess({ response })),
                    catchError(error => of(AdminActions.fetchAdminsFailure({ error: this.coreService.handleError(error) })))
                )
            )
        )
    );

    createadmin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminActions.createAdmin),
            switchMap(({ data }) => from(this.adminService.createAdmin(data))
                .pipe(
                    map(response => AdminActions.createAdminSuccess({ response })),
                    catchError(error => of(AdminActions.createAdminFailure({ error: this.coreService.handleError(error) })))
                )
            )
        )
    );

    fetchResourcesOrganizations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminActions.fetchResources),
            withLatestFrom(this.store.select(selectAdminState)),
            filter(([action, adminState]) => action.resourceType === AppResourceTypes.organizations && !adminState.data.resources?.organizations?.length),
            switchMap(() => from(this.adminService.getAdminResourceOrganization())
                .pipe(
                    map(organizations => AdminActions.fetchResourcesOrganizationsSuccess({ organizations })),
                    catchError(error => of(AdminActions.fetchResourcesFailure({ error: this.coreService.handleError(error) })))
                )
            )
        )
    );

    fetchResourcesRoles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminActions.fetchResources),
            withLatestFrom(this.store.select(selectAdminState)),
            filter(([action, adminState]) => action.resourceType === AppResourceTypes.roles && !adminState.data.resources?.roles?.length),
            switchMap(() => from(this.adminService.getAdminResourceRoles())
                .pipe(
                    map(roles => AdminActions.fetchResourcesRolesSuccess({ roles })),
                    catchError(error => of(AdminActions.fetchResourcesFailure({ error: this.coreService.handleError(error) })))
                )
            )
        )
    );

    constructor(
        private store: Store,
        private actions$: Actions,
        private coreService: CoreService,
        private adminService: AdminService
    ) { }
}


