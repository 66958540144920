export enum UserTabRootPages {
  POPUPS = "popups",
  QRS = "my-qr-codes",
  QRS_TAGS = "qr-tags",
  REFERRALS = "referrals",
  DASHBOARD = "dashboard",
  EDIT_QR = "edit-qr-code",
  EDIT_POPUPS = "edit-pop-up",
  CREATE_QR = "create-qr-code",
  REDEEM_VOUCHER = "redemption",
  CREATE_POPUPS = "create-pop-up",
  ACCOUNT_SETTINGS = "account-settings",
  OFF_THE_SHELF_QR_CODES = "off-the-shelf-qr-codes",
  OFF_THE_SHELF_QR_CODES_BATCH = "off-the-shelf-qr-codes-batch",
}
