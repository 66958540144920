import { select, Store } from "@ngrx/store";
import { lastValueFrom } from "rxjs";
import { take } from "rxjs/operators";
import { StateStatuses } from "../enums/state-statuses.enum";
import { NewAdmin } from "../models/new-admin.interface";
import { selectAdminState } from "../selectors/admin.selectors";
import { RequestFilters } from "../models/request-filters.interface";
import { ReferralHistoryRecordInterface } from "../models/referral-history-record.interface";
import { AdminUserQrsRequestParameters } from "../models/admin-userqrs-request-parameters.interface";
import { AdminReferralsRequestParameters } from "../models/admin-referrals-request-parameters.interface";
import { AdminUserInvoicesRequestParameters } from "../models/admin-invoices-request-parameters.interface";
import { AlaCarteCheckoutSession, AppResource, InvoiceInterface, Paginated, PreparedQrGeneratedRecordInterface, PublicUserDetails } from "shared-library";
import { AdminCheckoutSessionsRequestParameters } from "../models/admin-checkout-sessions-request-parameters.interface";

export interface AdminState {
  data: {
    qrs: {
      error: string;
      status: StateStatuses;
      params: AdminUserQrsRequestParameters;
      response: Paginated<PreparedQrGeneratedRecordInterface>;
    };
    users: {
      error: string;
      status: StateStatuses;
      params: RequestFilters;
      response: Paginated<PublicUserDetails>;
    };
    admins: {
      new: NewAdmin;
      error: string;
      status: StateStatuses;
      params: RequestFilters;
      response: Paginated<PublicUserDetails>;
    };
    invoices: {
      error: string;
      status: StateStatuses;
      response: Paginated<InvoiceInterface>;
      params: AdminUserInvoicesRequestParameters;
    };
    referrals: {
      error: string;
      status: StateStatuses;
      params: AdminReferralsRequestParameters;
      response: Paginated<ReferralHistoryRecordInterface>;
    };
    checkout_sessions: {
      error: string;
      status: StateStatuses;
      response: Paginated<AlaCarteCheckoutSession>;
      params: AdminCheckoutSessionsRequestParameters;
    };
    resources: AppResource;
  };
}

export const getAdminState = (store: Store): Promise<AdminState> => {
  return lastValueFrom(store.pipe(
    select(selectAdminState),
    take(1)
  ));
}
