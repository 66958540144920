import { lastValueFrom } from "rxjs";
import { take } from "rxjs/operators";
import { select, Store } from "@ngrx/store";
import { selectAppState } from "../selectors/app.selectors";
import { SessionInfo } from "../models/session-info.interface";
import { PromotionalDiscounts } from "../models/promotional-discounts.interface";
import { AlaCarteCheckoutSession, AlaCarteProductInterface, AlaCarteQrTypes, AppResource, Pricings } from "shared-library";

export interface AppState {
  currency: string;
  sessionInfo: SessionInfo;
  data: {
    // invoices: {
    //   error: string;
    //   status: StateStatuses;
    //   response: Paginated<InvoiceInterface>;
    //   params: AdminUserInvoicesRequestParameters;
    // };
    resources: AppResource;
    cache: {
      refresh_pricing: string;
      alacarte_session?: AlaCarteCheckoutSession;
      refresh_alacarte: Record<AlaCarteQrTypes, string>;
      products: {
        scans: Pricings[];
        plans: Pricings[];
        alacarte: Record<AlaCarteQrTypes, AlaCarteProductInterface[]>;
      };
      promo_codes: PromotionalDiscounts[];
    }
  }
}

export const getAppState = (store: Store): Promise<AppState> => {
  return lastValueFrom(store.pipe(
    select(selectAppState),
    take(1)
  ));
}
