export const environment = {
  production: true,
  app_url: `brandqrcodes.com`,
  app_url_full: `https://brandqrcodes.com/`,
  app_version: '1.200.14', // Updating this will force logout and reload
  google_keys: {
    api: `AIzaSyCHE9RrgHMG02CC-gk6zNmN3QsjbLifrOA`,
    recaptcha: "6Lf2sdMdAAAAACXn7PPlVVfhEM-4WaZ-hl6iDj6v"
  },
  referral_cookie_key: 'ref',
  files_url: `https://files.brandqrcodes.com/`,
  api_endpoint: "https://api.brandqrcodes.com/v1/",
  encryption_key: 'BPLrqAghLizie1swS2tmj9pnKSOJTHwMzI0KCOS8KPvhdRbxY',
  stripe_key: 'pk_live_51JikjADfC57n3Eh28k0bZhR0FTP2ap8Fw5NpcoG1u030a78kLhyms3Y9gUZtrlryFgEGHzMwmOTMxNk7QtX7NwG300mZHxwLWw',
};
