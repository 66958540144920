import { createAction, props } from '@ngrx/store';
import { NewAdmin } from '../models/new-admin.interface';
import { StateStatuses } from '../enums/state-statuses.enum';
import { AppResourceTypes } from '../enums/app-resources-types.enum';
import { RequestFilters } from '../models/request-filters.interface';
import { ReferralHistoryRecordInterface } from '../models/referral-history-record.interface';
import { AdminUserQrsRequestParameters } from '../models/admin-userqrs-request-parameters.interface';
import { AdminReferralsRequestParameters } from '../models/admin-referrals-request-parameters.interface';
import { AdminUserInvoicesRequestParameters } from '../models/admin-invoices-request-parameters.interface';
import { AdminCheckoutSessionsRequestParameters } from '../models/admin-checkout-sessions-request-parameters.interface';
import { APIResponse, AppResourceOrganization, InvoiceInterface, AppRole, PreparedQrGeneratedRecordInterface, PublicUserDetails, Paginated, AlaCarteCheckoutSession } from 'shared-library';

export const fetchQRs = createAction('[Admin] Fetching QR Codes initiated', props<{ params: AdminUserQrsRequestParameters, status: StateStatuses }>());
export const fetchQRsFailure = createAction('[Admin] Fetching QR Codes Failure', props<{ error: APIResponse }>());
export const fetchQRsSuccess = createAction('[Admin] Fetching QR Codes Success', props<{ response: Paginated<PreparedQrGeneratedRecordInterface> }>());

export const fetchInvoices = createAction('[Admin] Fetching Invoice list initiated', props<{ params: AdminUserInvoicesRequestParameters, status: StateStatuses }>());
export const fetchInvoicesFailure = createAction('[Admin] Fetching Invoices Failure', props<{ error: APIResponse }>());
export const fetchInvoicesSuccess = createAction('[Admin] Fetching Invoices Success', props<{ response: Paginated<InvoiceInterface> }>());

export const fetchReferrals = createAction('[Admin] Fetching Referral history initiated', props<{ params: AdminReferralsRequestParameters, status: StateStatuses }>());
export const fetchReferralsFailure = createAction('[Admin] Fetching Referrals Failure', props<{ error: APIResponse }>());
export const fetchReferralsSuccess = createAction('[Admin] Fetching Referrals Success', props<{ response: Paginated<ReferralHistoryRecordInterface> }>());

export const replaceCheckoutSession = createAction('[Admin] Replacing Checkout Session', props<{ data: AlaCarteCheckoutSession }>());
export const fetchCheckoutSessions = createAction('[Admin] Fetching Checkout Session history initiated', props<{ params: AdminCheckoutSessionsRequestParameters, status: StateStatuses }>());
export const fetchCheckoutSessionsFailure = createAction('[Admin] Fetching Checkout Sessions Failure', props<{ error: APIResponse }>());
export const fetchCheckoutSessionsSuccess = createAction('[Admin] Fetching Checkout Sessions Success', props<{ response: Paginated<AlaCarteCheckoutSession> }>());

export const fetchUsers = createAction('[Admin] Fetching User list initiated', props<{ params: RequestFilters, status: StateStatuses }>());
export const fetchUsersFailure = createAction('[Admin] Fetching Users Failure', props<{ error: APIResponse }>());
export const fetchUsersSuccess = createAction('[Admin] Fetching Users Success', props<{ response: Paginated<PublicUserDetails> }>());

export const fetchAdmins = createAction('[Admin] Fetching Admin list initiated', props<{ params: RequestFilters, status: StateStatuses }>());
export const fetchAdminsFailure = createAction('[Admin] Fetching Admins Failure', props<{ error: APIResponse }>());
export const fetchAdminsSuccess = createAction('[Admin] Fetching Admins Success', props<{ response: Paginated<PublicUserDetails> }>());
export const createAdmin = createAction('[Admin] Creating Admin initiated', props<{ data: NewAdmin }>());
export const createAdminFailure = createAction('[Admin] Creating Admin Failure', props<{ error: APIResponse }>());
export const createAdminSuccess = createAction('[Admin] Creating Admin Success', props<{ response: PublicUserDetails }>());

export const fetchResources = createAction('[Admin] Fetching resources initiated', props<{ resourceType: AppResourceTypes }>());
export const fetchResourcesFailure = createAction('[Admin] Fetching Resources Failure', props<{ error: APIResponse }>());
export const fetchResourcesRolesSuccess = createAction('[Admin] Fetching Role Resources Success', props<{ roles: AppRole[] }>());
export const fetchResourcesOrganizationsSuccess = createAction('[Admin] Fetching Organization Resources Success', props<{ organizations: AppResourceOrganization[] }>());

export const adminTearDown = createAction('[Admin] State teardown');
