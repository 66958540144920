import { Store } from '@ngrx/store';
import { filter, Subscription } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { appKey } from './store/reducers/app.reducers';
import { getAppState } from './store/states/app.states';
import { environment } from 'src/environments/environment';
import { adminKey } from './store/reducers/admin.reducers';
import { getAdminState } from './store/states/admin.states';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { selectAppState } from './store/selectors/app.selectors';
import { StateStatuses } from './store/enums/state-statuses.enum';
import { CoreService } from './modules/core/services/core.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { FunctionsService } from './modules/core/services/functions.service';
import { SeoEventNames, SeoService } from './modules/core/services/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = []
  readonly busy_app_state = StateStatuses.PROCESSING

  readonly appState$ = this.store.select(selectAppState);

  constructor(
    private store: Store,
    private router: Router,
    private metaService: Meta,
    private route: ActivatedRoute,
    private seoService: SeoService,
    private cookieService: CookieService,
    private readonly coreService: CoreService,
    private readonly functionService: FunctionsService
  ) {
    this.coreService.initializeSession()
    this.subscriptions.push(this.store.subscribe(async () => {
      const data = await Promise.all([
        getAppState(this.store),
        getAdminState(this.store),
      ])
      this.functionService.saveToDisk(appKey, JSON.stringify(data[0]))
      this.functionService.saveToDisk(adminKey, JSON.stringify(data[1]))
    }));
    this.seoService.initTag()

  }

  ngOnInit() {
    const navEndEvent$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd));
    this.subscriptions.push(navEndEvent$.subscribe((e: NavigationEnd) => {
      window.scrollTo(0, 0)
      this.seoService.pageChanged(e.urlAfterRedirects)
    }));

    this.subscriptions.push(this.route.queryParams.subscribe(params => {
      const refQueryParam = params['ref'];
      // Check if the 'ref' query parameter exists
      if (refQueryParam) {
        // Store the 'ref' value in a cookie for 90 days
        this.cookieService.set(environment.referral_cookie_key, refQueryParam, 90);
        // this.seoService.eventEmitter(SeoEventNames.UserActions, "newReferredUser", refQueryParam)
      }
    }));

    if (!window.location.href.includes(".loc:") && this.coreService.isLoggedIn()) {
      this.coreService.refreshSession();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }
}
