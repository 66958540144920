import { DOCUMENT } from '@angular/common';
import { CoreService } from './core.service';
import { Inject, Injectable } from '@angular/core';
import { FunctionsService } from './functions.service';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { CoreSeoPages } from '@app/store/enums/core-seo-pages';
import { BlogInterface } from '@app/modules/blog/services/blog.service';
import { CoreProductUseCases } from '@app/store/enums/core-product-use-cases.enum';
import { QrGeneratorNavigatorCategories, enumValuesToArray, ucFirst } from 'shared-library';
import { PublicInformationPageMessageTypes } from '@app/modules/shared/components/public-information-page/public-information-page.enum';

declare const gtag: Function

@Injectable({
    providedIn: 'root'
})
export class SeoService {

    private readonly TAG_ID = 'GTM-MW2ZJQ6B'

    constructor(
        private title: Title,
        private metaService: Meta,
        private coreService: CoreService,
        @Inject(DOCUMENT) private doc: Document,
        private functionService: FunctionsService
    ) { }

    generateMetaTags(page: CoreSeoPages | QrGeneratorNavigatorCategories | CoreProductUseCases | PublicInformationPageMessageTypes, settings: {
        description?: string[];
        title_keywords?: string[];
        blog_post?: BlogInterface;
    } = {}): void {

        const joiner = ' • ';
        const { blog_post, title_keywords, description = [] } = settings;

        this.metaService.removeTag("name='author'")
        this.metaService.removeTag("name='keywords'")
        this.metaService.removeTag("property='og:url'")
        this.metaService.removeTag("name='description'")
        this.metaService.removeTag("property='og:title'")
        this.metaService.removeTag("name='twitter:site'")
        this.metaService.removeTag("name='twitter:title'")
        this.metaService.removeTag("property='og:description'")
        this.metaService.removeTag("name='twitter:description'")

        const titleGenerator = (p_title: string) => {
            let items: string[] = []
            items.push(blog_post?.title || p_title)
            if (title_keywords) items = [...items, ...title_keywords.filter(tk => tk && !this.functionService.is_valid_url(tk)).map(tk => ucFirst(tk))]
            return [...items, 'BrandQRCodes'].join(joiner)
        }

        const descriptionGenerator = (p_description: string) => blog_post?.meta_description || description.join(joiner) || p_description

        if (CoreSeoPages.HOME === page) {
            const title = titleGenerator(`Branded QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Set up a business or personal web page to share anything - social media links, business menu, three cards, image gallery, event promotion or job listings.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords() },
            ]);
        }

        if (CoreSeoPages.FAQS === page) {
            const title = titleGenerator(`FAQs`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Help Center | Frequently Asked Questions on Brand QR codes')
            this.metaService.addTags([
                { name: 'description', content },
                { name: 'keywords', content: "" },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
            ]);
        }

        if (CoreSeoPages.PRICING === page) {
            const title = titleGenerator(`Pricing`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Pricing | You can unlock everything brandqrcodes has to offer with any of our affordable plans.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords(["brand QR codes pricing", "brandqrcodes pricing", "brandqrcodes free", "brandqrcodes plans"]) },
            ]);
        }

        if ([CoreSeoPages.PROMOTIONS, PublicInformationPageMessageTypes.DISCOUNT].includes(page as PublicInformationPageMessageTypes)) {
            const title = titleGenerator(`Discounts`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Discounts | You can unlock everything brandqrcodes has to offer with any of our affordable plans using any of these discount codes.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords(["brand QR codes discounts", "brandqrcodes promotion", "brandqrcodes discounts"]) },
            ]);
        }

        if (CoreSeoPages.POPUP_LANDING === page) {
            const title = titleGenerator(`Collect leads with Pop-up forms`)
            this.title.setTitle(title)
            const content = descriptionGenerator("Pop-up forms allow you to easily create user-friendly pop-up forms for any of your dynamic QR codes to collect potential business leads.")
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: "QR code generator with popup form, branded QR code generator for leads" },
            ]);
        }

        if (CoreSeoPages.REFERRAL === page) {
            const title = titleGenerator(`Referral Program`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Refer your audience to earn 20% commission on brandqrcodes.com!')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: "brand QR codes referral, referral program on brand QR codes" },
            ]);
        }

        if (CoreSeoPages.REDEMPTION_LANDING === page) {
            const title = titleGenerator(`Redeem voucher code`)
            this.title.setTitle(title)
            const content = descriptionGenerator('We offer a free and simple way to generate QR Codes which you can use in your marketing campaigns, product packaging, on websites, on business cards and so on.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: "brand QR codes voucher redemption, redeem voucher on brand QR codes" },
            ]);
        }

        if (CoreSeoPages.BLOG === page) {
            const title = titleGenerator(`Blog`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Unleash the potential of QR codes with BrandQRCodes! Discover creative use cases, design tips, industry trends, and insights to elevate your marketing and streamline your business.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'author', content: settings?.blog_post?.author || "BrandQrCodes Team" },
                { name: 'keywords', content: this.getDefaultKeywords(["QR code blog"]) },
            ]);
        }

        if (CoreSeoPages.API_DOCUMENTATION === page) {
            const title = titleGenerator(`API Documentation`)
            this.title.setTitle(title)
            const content = descriptionGenerator("Automate your QR Code generation with our API. Create numerous Static QR Codes, and integrate them into your application with your choice of branded styles.")
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords(["QR code api", "QR code api with logo"]) },
            ]);
        }

        if (CoreSeoPages.SIGN_IN === page) {
            const title = titleGenerator(`Sign in`)
            this.title.setTitle(title)
            const content = descriptionGenerator("Get Started - QR code-accessible web pages for all use cases")
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords(["brand QR codes sign in", "brand QR codes get started"]) },
            ]);
        }

        if (CoreSeoPages.SIGN_UP === page) {
            const title = titleGenerator(`Sign up`)
            this.title.setTitle(title)
            const content = descriptionGenerator("Get Started - QR code-accessible web pages for all use cases")
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords(["brand QR codes sign up", "brand QR codes get started"]) },
            ]);
        }

        if (CoreSeoPages.PRIVACY_POLICY === page) {
            const title = titleGenerator(`Privacy policy`)
            this.title.setTitle(title)
            const content = descriptionGenerator('At BrandQRCodes, your privacy is our priority. Learn how we handle your data, what information we collect, and your rights regarding your information.')
            this.metaService.addTags([
                { name: 'description', content },
                { name: 'keywords', content: "" },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
            ]);
        }

        if (CoreSeoPages.TERMS === page) {
            const title = titleGenerator(`Terms and Conditions`)
            this.title.setTitle(title)
            const content = descriptionGenerator('BrandQRCodes Terms & Conditions outline the terms of service for using our platform. Please review them before using our services.')
            this.metaService.addTags([
                { name: 'description', content },
                { name: 'keywords', content: "" },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
            ]);
        }

        if (QrGeneratorNavigatorCategories.WEBSITE === page) {
            const title = titleGenerator(`Website QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Set up a business or personal web page to share anything - social media links, business menu, three cards, image gallery, event promotion or job listings.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.URL === page) {
            const title = titleGenerator(`URL QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Set up a business or personal web page to share anything - social media links, business menu, three cards, image gallery, event promotion or job listings.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.SMS === page) {
            const title = titleGenerator(`SMS QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Set up a branded QR code where your audience can scan to initiate a text message to your mobile number.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.TEXT === page) {
            const title = titleGenerator(`Text QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Set up a branded QR code for plain text up to 300 characters.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`, "QR code text message"]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.LONG_PLAIN_TEXT === page) {
            const title = titleGenerator(`Long text QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Use branded QR codes to craft a web page for lengthy text with our user-friendly, efficient function with simplified sharing.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`, "qr codes for texts", "qr code for text", "qr code for landing pages"]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.EMAIL === page) {
            const title = titleGenerator(`Email QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Set up a branded QR code where your audience can scan to initiate a message to your email.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.VCARD === page) {
            const title = titleGenerator(`VCard QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Set up a branded QR code that allows your audience to simply scan and add you to their contact list on their mobile phone.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`, "vcard"]) },
            ]);
        }

        if ([QrGeneratorNavigatorCategories.LOCATION, QrGeneratorNavigatorCategories.LOCATION_PLUS].includes(page as QrGeneratorNavigatorCategories)) {
            const title = titleGenerator(`Location QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Set up a branded QR code that allows location sharing with your audience.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`, "location QR codes", "google map QR code generator"]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.BITCOIN === page) {
            const title = titleGenerator(`Bitcoin QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Set up a branded QR code that allows your audience to send cryptocurrency to your wallet address.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`, `generate branded QR codes for cryptocurrency`]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.WIFI === page) {
            const title = titleGenerator(`Wifi QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Set up a branded QR code that allows your audience to automatically connect to a WiFi network.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`, `generate branded QR codes for wifi`, `wifi QR code generator`]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.MP3 === page) {
            const title = titleGenerator(`MP3 QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Use branded QR codes to share your music and MP3 files, and include links to other platforms where your audience can listen or download.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`, "mp3 QR code", "mp3 to QR code free", "mp3 to QR code", "QR code mp3", "QR mp3", "QR code generator mp3", "mp3 QR code free"]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.PDF === page) {
            const title = titleGenerator(`PDF QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Use branded QR codes to share your PDF documents, your audience can easily view or download the documents from your page by scanning your generated QR code.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.EVENT === page) {
            const title = titleGenerator(`Event QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Use branded QR codes to promote events by creating an event page with all important information like date, location, directions etc.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.IMAGES === page) {
            const title = titleGenerator(`Images QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Use branded QR codes to share your images with your audience by adding them to your image gallery page and sharing with a single link or QR code. All the while getting detailed analytics on page visits.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.RATING === page) {
            const title = titleGenerator(`Rating QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Use branded QR codes to request for ratings from your customers after a sale or service. Set this up and share the created QR code or link to the concerned customers.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`, "QR rating", "rating QR code"]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.COUPONS === page) {
            const title = titleGenerator(`Coupon QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Use branded QR codes to create coupons which your customers can redeem and use in your online or physical stores to get discounts. Simply create the coupon, add the details and share the link with your customers.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`, "brand's coupon"]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.VACANCY === page) {
            const title = titleGenerator(`Vacancy QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Use branded QR codes to create a vacancy page to post job listings for your business. You can include all information about the job and accept applications on the same page or redirect to a different application page.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.FEEDBACK === page) {
            const title = titleGenerator(`Feedback QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Use branded QR codes to collect feedbacks and get rated from your customers with a simple link. Set up your feedback page and share the link or QR code on your different social platforms or as part of your follow up emails or texts.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.VIDEOS === page) {
            const title = titleGenerator(`YouTube videos QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Use branded QR codes to create a video gallery which you can share with your audience with a simple link or QR code. This can serve as a portfolio if you’re a video creator or content producer.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`, "qr code link to youtube video", "dynamic qr code for youtube", "dynamic QR code youtube", "qr code for youtube video"]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.APP === page) {
            const title = titleGenerator(`Mobile application QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Use branded QR codes to share your mobile apps here and include links to all the different platforms from where it can be downloaded, all the while getting detailed analytics on link visits.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.VCARD_PRO === page) {
            const title = titleGenerator(`Digital business card QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Use branded QR codes to create a page that allows you to have a header where you can give a summary about yourself or the type of service you offer. It also includes a banner with colours of your choice, giving your vCard Pro a beautiful personal touch.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`, "vcard with social media links", "vcard pro"]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.PET === page) {
            const title = titleGenerator(`Pet tag QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Use branded QR codes to create a trackable page that allows you to have a header where you can give a summary about your pet.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`, "pet tag QR code", "pet tag"]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.SOCIAL_MEDIA === page) {
            const title = titleGenerator(`Social media accounts QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Use branded QR codes to create a reference landing page for all your social handles and links.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`, "one QR code for all social media", "QR code for social media links", "QR code for social media accounts"]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.BUSINESS_PAGE === page) {
            const title = titleGenerator(`Business page QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Use branded QR codes to create a business page with all the important information for your customers.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`, "business page QR code"]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.RESTAURANT_MENU === page) {
            const title = titleGenerator(`Restaurant menu QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Use branded QR codes to create a restaurant menu with all the important information for your customers.')
            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                { name: 'keywords', content: this.getDefaultKeywords([`generate branded QR codes for ${page}`, "restaurant menu qr code"]) },
            ]);
        }

        if (QrGeneratorNavigatorCategories.MEMORIES === page) {
            const title = titleGenerator(`Shared online gallery for weddings and events QR Code generator`)
            this.title.setTitle(title)
            const content = descriptionGenerator('Use QR codes to collect and create lasting moments: let your guests capture and share their memories with ease by simply scanning a QR code.')

            const details = this.coreService.allProductUseCases()
            let children_keywords: string[] = []
            for (const { seo_keywords } of details) children_keywords = [...children_keywords, ...seo_keywords]

            this.metaService.addTags([
                { name: 'description', content },
                { property: 'og:description', content },
                { name: 'twitter:description', content },
                { property: 'og:title', content: title },
                { name: 'twitter:title', content: title },
                { property: 'og:url', content: window.location.href },
                { name: 'twitter:site', content: window.location.href },
                {
                    name: 'keywords', content: this.getDefaultKeywords([
                        ...children_keywords,
                        "event qr code",
                        "wedding qr code",
                        "wedding photo qr code",
                        "qr code wedding photos",
                        "online gallery qr code",
                        "wedding photos qr code",
                        "wedding photos qr codes",
                        "qr code for wedding photos",
                        "wedding qr code for photos",
                        "qr codes for wedding photos",
                        "wedding photo share qr code",
                        "qr code for wedding pictures",
                        "wedding photo sharing qr code",
                        "shared online gallery qr code",
                        "free qr code for wedding photos",
                        "qr code to upload wedding photos",
                        `generate branded QR codes for ${page}`,
                    ])
                },
            ]);
        }

        if (enumValuesToArray(PublicInformationPageMessageTypes).includes(page as PublicInformationPageMessageTypes)) {

            if (PublicInformationPageMessageTypes.QR_NOT_FOUND === page) {
                const title = titleGenerator(`QR Code not found`)
                this.title.setTitle(title)
                const content = descriptionGenerator('It appears that the QR Code page you were looking for has been deleted')
                this.metaService.addTags([
                    { name: 'description', content },
                    { property: 'og:description', content },
                    { name: 'twitter:description', content },
                    { property: 'og:title', content: title },
                    { name: 'twitter:title', content: title },
                    { property: 'og:url', content: window.location.href },
                    { name: 'twitter:site', content: window.location.href },
                    { name: 'keywords', content: this.getDefaultKeywords(["brand QR codes", "branded QR codes"]) },
                ]);
            }

            if (PublicInformationPageMessageTypes.QR_IS_DISABLED === page) {
                const title = titleGenerator(`QR Code is disabled`)
                this.title.setTitle(title)
                const content = descriptionGenerator('It appears that the QR Code page you were looking for has been disabled.')
                this.metaService.addTags([
                    { name: 'description', content },
                    { property: 'og:description', content },
                    { name: 'twitter:description', content },
                    { property: 'og:title', content: title },
                    { name: 'twitter:title', content: title },
                    { property: 'og:url', content: window.location.href },
                    { name: 'twitter:site', content: window.location.href },
                    { name: 'keywords', content: this.getDefaultKeywords(["brand QR codes", "branded QR codes"]) },
                ]);
            }

            if (PublicInformationPageMessageTypes.QR_IS_SCANNABLE === page) {
                const title = titleGenerator(`QR Code is scannable`)
                this.title.setTitle(title)
                const content = descriptionGenerator('Dear valued user we would like to inform you that the QR code you scanned is fully functional and scannable. You can use any QR code scanner application to scan it and access the information that it contains.')
                this.metaService.addTags([
                    { name: 'description', content },
                    { property: 'og:description', content },
                    { name: 'twitter:description', content },
                    { property: 'og:title', content: title },
                    { name: 'twitter:title', content: title },
                    { property: 'og:url', content: window.location.href },
                    { name: 'twitter:site', content: window.location.href },
                    { name: 'keywords', content: this.getDefaultKeywords(["brand QR codes", "branded QR codes"]) },
                ]);
            }

            if (PublicInformationPageMessageTypes.QR_VISITS_REACHED === page) {
                const title = titleGenerator(`QR Code visit reached`)
                this.title.setTitle(title)
                const content = descriptionGenerator('The visit limit to this QR Code has reached. If you are the owner, please head to your dashboard now to top up visits or consider changing your plan to accommodate the growing interest.')
                this.metaService.addTags([
                    { name: 'description', content },
                    { property: 'og:description', content },
                    { name: 'twitter:description', content },
                    { property: 'og:title', content: title },
                    { name: 'twitter:title', content: title },
                    { property: 'og:url', content: window.location.href },
                    { name: 'twitter:site', content: window.location.href },
                    { name: 'keywords', content: this.getDefaultKeywords(["brand QR codes", "branded QR codes"]) },
                ]);
            }

            if (PublicInformationPageMessageTypes.NEWSLETTERS_UNSUBSCRIPTION === page) {
                const title = titleGenerator(`QR Code visit reached`)
                this.title.setTitle(title)
                const content = descriptionGenerator("We're confirming that you've been successfully unsubscribed from our promotional emails list.")
                this.metaService.addTags([
                    { name: 'description', content },
                    { property: 'og:description', content },
                    { name: 'twitter:description', content },
                    { property: 'og:title', content: title },
                    { name: 'twitter:title', content: title },
                    { property: 'og:url', content: window.location.href },
                    { name: 'twitter:site', content: window.location.href },
                    { name: 'keywords', content: this.getDefaultKeywords(["brand QR codes", "branded QR codes"]) },
                ]);
            }

        }

        if (enumValuesToArray(CoreProductUseCases).includes(page as CoreProductUseCases)) {

            const details = this.coreService.allProductUseCases().find(({ type }) => type === page)
            if (details) {
                const { description: content, title: p_title, seo_keywords } = details
                const title = `BrandQRCodes | Generate trackable branded QR code for ${p_title.toLowerCase()}`
                this.title.setTitle(title)
                this.metaService.addTags([
                    { name: 'description', content },
                    { property: 'og:description', content },
                    { name: 'twitter:description', content },
                    { property: 'og:title', content: title },
                    { name: 'twitter:title', content: title },
                    { property: 'og:url', content: window.location.href },
                    { name: 'twitter:site', content: window.location.href },
                    { name: 'keywords', content: this.getDefaultKeywords([...seo_keywords, p_title.toLowerCase()]) },
                ]);
            }
        }
    }

    pageChanged(page: string) {
        if (environment.production && gtag) {
            this.eventEmitter(SeoEventNames.PageView, 'page_path', page)
        }
        this.createLinkForCanonicalURL()
    }

    initTag() {
        if (environment.production && gtag) {
            gtag('config', this.TAG_ID, { 'debug_mode': true });

            gtag('consent', 'default', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'analytics_storage': 'denied',
                'ad_personalization': 'denied',
            });

            setTimeout(() => {
                gtag('consent', 'update', {
                    'ad_storage': 'granted',
                    'ad_user_data': 'granted',
                    'analytics_storage': 'granted',
                    'ad_personalization': 'granted'
                });
            }, 1000)

        }
    }

    eventEmitter(eventName: SeoEventNames, eventLabel: string, eventValue: string, extra = {}): void {
        if (environment.production && gtag) {
            gtag('event', eventName, {
                'debug_mode': true,
                eventValue,
                eventLabel,
                ...extra
            })
        }
    }

    private createLinkForCanonicalURL() {
        if (!this.doc?.URL) return;
        const existing_links = this.doc.querySelectorAll("link[rel=canonical]")
        for (let index = 0; index < existing_links.length; index++) {
            this.doc.head.removeChild(existing_links.item(index))
        }
        let link: HTMLLinkElement = this.doc.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', this.doc.URL?.includes("://www.") ? this.doc.URL?.replace("www.", "") : this.doc.URL?.replace("://", "://www."));
        this.doc.head.appendChild(link);
    }

    private getDefaultKeywords(more: string[] = []) {
        return this.functionService.array_unique([
            ...more,
            "website qr code",
            "branded qr code",
            "branded qr codes",
            "qr code generator",
            "fast qr code generator",
            "free qr code generator",
            "qr code generator free",
            "best qr code generator",
            "qr code generator google",
            "qr code generator with logo",
            "free qr code generator google",
            "best free qr code generator",
            "google qr code generator",
            "the qr code generator",
            "qr code generator login",
            "wifi qr code generator",
            "qr code generator free online",
            "custom qr code generator",
            "instagram qr code generator",
            "dynamic qr code generator",
            "qr code generator log in",
            "qr code generator for instagram",
            "qr code generator free google",
            "free qr code generator no sign up",
            "facebook qr code generator",
            "google qr code generator free",
            "qr code generator customizable",
            "qr code generator dynamic",
            "ig qr code generator",
            "google free qr code generator",
            "free google qr code generator",
            "customizable qr code generator",
            "branded qr code generator",
            "qr code generator custom",
            "qr code generator pdf",
            "qr code generator online",
            "free qr code generator for pdf",
            "free qr code generator with logo",
            "random qr code generator",
            "qr code generator for apps",
            "free qr code generator for pdfs",
            "qr code generator from pdf",
            "qr code generator pdf free",
            "free qr code generator pdf",
            "pdf to qr code generator",
            "qr code generator free pdf",
            "free pdf qr code generator",
            "app qr code generator",
            "pdf qr code generator",
            "qr code generator for pdf",
            "qr code generator to pdf",
            "pdf qr code generator free",
            "qr code generator app",
            "online qr code generator",
            "pdf qr code generator free online",
            "qr code generator software",
            "qr code generator for website",
            "qr code generator for url",
            "free logo qr code generator",
            "qr code generator logo free",
            "qr code generator free with logo",
            "website qr code generator",
            "qr code generator website",
            "logo qr code generator free",
            "free qr code generator logo",
            "qr code generator bulk",
            "qr code generator free logo",
            "qr code generator with logo free",
            "qr code generator facebook",
            "free online qr code generator",
            "qr code generator google form",
            "excel qr code generator",
            "qr code generator vector",
            "online free qr code generator",
            "free qr code generator by shopify",
            "free qr code generator online",
            "qr code generator online free",
            "qr code generator reviews",
            "qr code generator feedback",
            "qr code generator rating",
            "qr code generator javascript",
            "open source qr code generator",
            "javascript qr code generator",
            "image qr code generator",
            "cash app qr code generator",
            "qr code generator with image",
            "google authenticator qr code generator",
            "qr code generator for image",
            "trackable qr code generator",
            "trackable qr code",
            "free qr code generator with analytics",
            "qr code generator for images",
            "image to qr code generator",
            "qr code generator image",
            "qr code generator from image",
            "qr code generator for business",
            "qr code generator business card",
            "business card qr code generator",
            "qr code generator for business cards",
            "qr code generator business cards",
            "qr code generator link",
            "qr code generator for business card",
            "qr Code Generator with Logo",
            "Free qr Code Generator Online",
            "qr Code Management Platform",
            "Professional qr Code Generator",
            "Business qr Code Generator",
            "custom qr Codes",
        ]).join(", ")
    }
}

export enum SeoEventNames {
    Purchase = "purchase",
    PageView = "page_view",
    UserActions = "user_actions"
}
