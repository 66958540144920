import { createReducer, on } from '@ngrx/store';
import * as AppActions from '../actions/app.actions';
import { AlaCarteCheckoutSession, Statuses, AlaCarteQrTypes, QrGeneratorNavigatorCategories, RecordTypes, PopupCategories, SortDirections } from 'shared-library';
import { StateStatuses } from '../enums/state-statuses.enum';
import { AppState } from '../states/app.states';
import { environment } from 'src/environments/environment';
import moment from 'moment';

export const appKey = 'app';

let initialState: AppState = {
  currency: 'USD',
  sessionInfo: {
    token: "",
    proxy_user: "",
    app_busy: false,
    version: environment.app_version,
    last_routes: {
      new_home: "/",
      b4payment: "",
      lastvisited: "",
      tabs: {
        admin: {
          profile: null
        },
        operations: {
          loginas: null
        },
        users: {
          root: null,
          profile: null,
        },
      }
    },
    requested_payment_route: "",
    payment: {
      order_details: null,
      alacarte_session: null,
      selected_subscription_price: null
    },
    resources: {
      roles: [],
      prices: [],
      qr_logos: [],
      currencies: [],
      organizations: []
    },
    user_details: null,
    miscellaneous: null,
    cache: {
      qrs: [],
      popups: [],
      users: [],
      admins: [],
      roles: [],
      qr_viewing: null,
      popup_viewing: null,
      last_dashboard_user_id: "",
      preferences: {
        warnings: {
          qr_untag: true,
          qr_tag_deletion: true,
          popup_tag_deletion: true,
          workspace_view_grid: true,
        }
      },
      qr_themes: {
        error: "",
        templates: [],
        status: StateStatuses.PENDING,
      },
      pages: {
        qrs: {
          error: "",
          status: StateStatuses.PENDING,
          params: {
            page: 1,
            limit: 20,
            tag_id: "",
            search: "",
            status: Statuses.ACTIVE,
            sort: SortDirections.DESC
          },
          response: null
        },
        popups: {
          error: "",
          status: StateStatuses.PENDING,
          params: {
            page: 1,
            limit: 20,
            tag_id: "",
            search: "",
            status: Statuses.ACTIVE,
            sort: SortDirections.DESC
          },
          response: null
        },
        off_the_shelf_qr_codes: {
          error: "",
          status: StateStatuses.PENDING,
          params: {
            page: 1,
            limit: 20,
            search: "",
            sort: SortDirections.DESC
          },
          response: null
        },
        referrals: {
          error: "",
          status: StateStatuses.PENDING,
          params: {
            page: 1,
            limit: 100,
            search: "",
            year: moment().year(),
            sort: SortDirections.DESC
          },
          response: null
        }
      }
    },
    qr_generating: {
      drafts: [],
      updating: null,
      last_viewed_category: QrGeneratorNavigatorCategories.BUSINESS_PAGE,
    },
    popup_generating: {
      drafts: [],
      updating: null,
      last_viewed_category: PopupCategories.LIGHTBOX
    }
  },

  data: {
    resources: {
      roles: [],
      prices: [],
      qr_logos: [],
      currencies: [],
      organizations: []
    },
    cache: {
      products: {
        scans: [],
        plans: [],
        alacarte: {
          memories: []
        }
      },
      promo_codes: [],
      refresh_pricing: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      refresh_alacarte: {
        memories: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
      }
    }
  }
};

const initialStateCloned: AppState = { ...initialState }
const savedState = localStorage.getItem(appKey) || sessionStorage.getItem(appKey)
if (savedState && initialState.sessionInfo.version === JSON.parse(savedState)?.sessionInfo.version) {
  initialState = JSON.parse(savedState)
}

export const appReducer = createReducer(
  initialState,

  on(AppActions.fetchSubscriptionPrices, (state) => ({ ...state })),
  on(AppActions.fetchSubscriptionPricesSuccess, (state, { response }) => {
    let currency = initialState.currency;
    if (response.data.prices.length) {
      currency = response.data.prices[0].currency
    }
    return {
      ...state,
      currency,
      data: {
        ...state.data,
        cache: {
          ...state.data.cache,
          promo_codes: response.data.promo_codes,
          refresh_pricing: moment().add(30, "minutes").format('YYYY-MM-DD HH:mm:ss'),
          products: {
            ...state.data.cache.products,
            plans: response.data.prices
          }
        }
      }
    }
  }),
  on(AppActions.fetchSubscriptionPricesFailure, (state) => ({ ...state })),

  on(AppActions.fetchAlaCartePrices, (state) => ({ ...state })),
  on(AppActions.fetchAlaCartePricesSuccess, (state, { response }) => {
    let currency = initialState.currency;
    let qr_category: AlaCarteQrTypes;
    if (response.data.length) {
      qr_category = response.data[0].qr_category
      if (response.data[0].prices.length) {
        currency = response.data[0].prices[0].currency
      }
    }
    if (!qr_category) return { ...state }
    return {
      ...state,
      currency,
      data: {
        ...state.data,
        cache: {
          ...state.data.cache,
          refresh_alacarte: {
            ...state.data.cache.refresh_alacarte,
            [qr_category]: response.data.length ? moment().add(30, "minutes").format('YYYY-MM-DD HH:mm:ss') : moment().add(30, "seconds").format('YYYY-MM-DD HH:mm:ss'),
          },
          products: {
            ...state.data.cache.products,
            alacarte: {
              ...state.data.cache.products.alacarte,
              [qr_category]: response.data
            }
          }
        }
      }
    }
  }),
  on(AppActions.fetchAlaCartePricesFailure, (state) => ({ ...state })),

  on(AppActions.initialiseAlaCarteSession, (state) => ({
    ...state,
    data: {
      ...state.data,
      cache: {
        ...state.data.cache,
        alacarte_session: {
          ...(state.data.cache.alacarte_session ? state.data.cache.alacarte_session : {
            ip: "",
            cart: {
              id: "",
              email: "",
              items: []
            },
            paid: false,
            timezone: "",
            referral_id: "",
            client_secret: "",
            invoice_number: "",
            id: "",
            sortKey: "",
            status: Statuses.ACTIVE,
            creationDate: "",
            recordType: RecordTypes.ALACARTECHECKOUTSESSIONS
          } as AlaCarteCheckoutSession),
          busy: true
        },
      }
    }
  })),
  on(AppActions.initialiseAlaCarteSessionSuccess, (state, { response }) => {
    return {
      ...state,
      data: {
        ...state.data,
        cache: {
          ...state.data.cache,
          alacarte_session: { ...response.data, busy: false }
        }
      }
    }
  }),
  on(AppActions.initialiseAlaCarteSessionFailure, (state) => ({
    ...state,
    data: {
      ...state.data,
      cache: {
        ...state.data.cache,
        alacarte_session: {
          ...state.data.cache.alacarte_session,
          busy: false,
        },
      }
    }
  })),

  on(AppActions.removeAlaCarteSession, (state) => {
    return {
      ...state,
      data: {
        ...state.data,
        cache: {
          ...state.data.cache,
          alacarte_session: null,
        }
      }
    }
  }),

  on(AppActions.removeCartInAlaCarteSession, (state, { index }) => {
    return {
      ...state,
      data: {
        ...state.data,
        cache: {
          ...state.data.cache,
          alacarte_session: {
            ...state.data.cache.alacarte_session,
            busy: false,
            cart: {
              ...state.data.cache.alacarte_session.cart,
              items: state.data.cache.alacarte_session.cart.items.filter((_, indexx) => indexx !== index)
            }
          },
        }
      }
    }
  }),

  on(AppActions.addToCartInAlaCarteSession, (state, { data }) => {
    let items = state.data.cache.alacarte_session.cart.items;
    const index = items.findIndex(item => item.product_id === data.product_id && item.product_price_id === data.product_price_id);
    return {
      ...state,
      data: {
        ...state.data,
        cache: {
          ...state.data.cache,
          alacarte_session: {
            ...state.data.cache.alacarte_session,
            busy: false,
            cart: {
              ...state.data.cache.alacarte_session.cart,
              items: index === -1 ? [...items, data] : [...items.map((item, indexx) => indexx === index ? { ...item, quantity: item.quantity + data.quantity, product_title: data.product_title, product_url: data.product_url, product_description: data.product_description } : item)]
            }
          },
        }
      }
    }
  }),

  on(AppActions.updateCartInAlaCarteSession, (state, { data, index }) => {
    let items = state.data.cache.alacarte_session.cart.items;
    return {
      ...state,
      data: {
        ...state.data,
        cache: {
          ...state.data.cache,
          alacarte_session: {
            ...state.data.cache.alacarte_session,
            busy: false,
            cart: {
              ...state.data.cache.alacarte_session.cart,
              items: [...items.map((item, indexx) => indexx === index ? { ...data } : item)]
            }
          },
        }
      }
    }
  }),

  on(AppActions.fetchProductScansPrices, (state) => ({ ...state })),
  on(AppActions.fetchProductScansPricesSuccess, (state, { response }) => ({
    ...state,
    data: {
      ...state.data,
      cache: {
        ...state.data.cache,
        promo_codes: response.data.promo_codes,
        products: {
          ...state.data.cache.products,
          scans: response.data.prices
        }
      }
    }
  })),
  on(AppActions.fetchProductScansPricesFailure, (state) => ({ ...state })),

  on(AppActions.QRsTeardown, (state) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        pages: {
          ...state.sessionInfo.cache.pages,
          qrs: {
            ...initialStateCloned.sessionInfo.cache.pages.qrs,
          }
        }
      }
    }
  })),
  on(AppActions.fetchQRs, (state, { params, status }) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        pages: {
          ...state.sessionInfo.cache.pages,
          qrs: {
            ...state.sessionInfo.cache.pages.qrs,
            params,
            status,
            error: ""
          }
        }
      }
    }
  })),
  on(AppActions.fetchQRsSuccess, (state, { response }) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        pages: {
          ...state.sessionInfo.cache.pages,
          qrs: {
            ...state.sessionInfo.cache.pages.qrs,
            error: "",
            status: StateStatuses.SUCCESS,
            response
          }
        }
      }
    }
  })),
  on(AppActions.fetchQRsFailure, (state, { error }) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        pages: {
          ...state.sessionInfo.cache.pages,
          qrs: {
            ...state.sessionInfo.cache.pages.qrs,
            error: error.error,
            status: StateStatuses.FAILED,
            response: null
          }
        }
      }
    }
  })),
  on(AppActions.replaceQR, (state, { qr }) => {
    const updated_data = state.sessionInfo.cache.pages.qrs.response.data.map((qr_) => qr.id === qr_.id ? qr : qr_);
    return {
      ...state,
      sessionInfo: {
        ...state.sessionInfo,
        cache: {
          ...state.sessionInfo.cache,
          pages: {
            ...state.sessionInfo.cache.pages,
            qrs: {
              ...state.sessionInfo.cache.pages.qrs,
              response: {
                ...state.sessionInfo.cache.pages.qrs.response,
                data: updated_data
              }
            }
          }
        }
      }
    };
  }),
  on(AppActions.deleteQRLocal, (state, { qr }) => {
    const updated_data = state.sessionInfo.cache.pages.qrs.response.data.filter((qr_) => qr.id !== qr_.id);
    return {
      ...state,
      sessionInfo: {
        ...state.sessionInfo,
        cache: {
          ...state.sessionInfo.cache,
          pages: {
            ...state.sessionInfo.cache.pages,
            qrs: {
              ...state.sessionInfo.cache.pages.qrs,
              response: {
                ...state.sessionInfo.cache.pages.qrs.response,
                data: updated_data
              }
            }
          }
        }
      }
    };
  }),

  on(AppActions.PopupsTeardown, (state) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        pages: {
          ...state.sessionInfo.cache.pages,
          popups: {
            ...initialStateCloned.sessionInfo.cache.pages.popups,
          }
        }
      }
    }
  })),
  on(AppActions.fetchPopups, (state, { params, status }) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        pages: {
          ...state.sessionInfo.cache.pages,
          popups: {
            ...state.sessionInfo.cache.pages.popups,
            params,
            status,
            error: ""
          }
        }
      }
    }
  })),
  on(AppActions.fetchPopupsSuccess, (state, { response }) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        pages: {
          ...state.sessionInfo.cache.pages,
          popups: {
            ...state.sessionInfo.cache.pages.popups,
            error: "",
            status: StateStatuses.SUCCESS,
            response
          }
        }
      }
    }
  })),
  on(AppActions.fetchPopupsFailure, (state, { error }) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        pages: {
          ...state.sessionInfo.cache.pages,
          popups: {
            ...state.sessionInfo.cache.pages.popups,
            error: error.error,
            status: StateStatuses.FAILED,
            response: null
          }
        }
      }
    }
  })),
  on(AppActions.replacePopup, (state, { popup }) => {
    const updated_data = state.sessionInfo.cache.pages.popups.response.data.map((popup_) => popup.id === popup_.id ? popup : popup_);
    return {
      ...state,
      sessionInfo: {
        ...state.sessionInfo,
        cache: {
          ...state.sessionInfo.cache,
          pages: {
            ...state.sessionInfo.cache.pages,
            popups: {
              ...state.sessionInfo.cache.pages.popups,
              response: {
                ...state.sessionInfo.cache.pages.popups.response,
                data: updated_data
              }
            }
          }
        }
      }
    };
  }),
  on(AppActions.deletePopupLocal, (state, { popup }) => {
    const updated_data = state.sessionInfo.cache.pages.popups.response.data.filter((popup_) => popup.id !== popup_.id);
    return {
      ...state,
      sessionInfo: {
        ...state.sessionInfo,
        cache: {
          ...state.sessionInfo.cache,
          pages: {
            ...state.sessionInfo.cache.pages,
            popups: {
              ...state.sessionInfo.cache.pages.popups,
              response: {
                ...state.sessionInfo.cache.pages.popups.response,
                data: updated_data
              }
            }
          }
        }
      }
    };
  }),

  on(AppActions.fetchQRThemes, (state) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        qr_themes: {
          ...state.sessionInfo.cache.qr_themes,
          error: "",
          status: !state.sessionInfo.cache.qr_themes.templates.length ? StateStatuses.PROCESSING : StateStatuses.PENDING,
        }
      }
    }
  })),
  on(AppActions.fetchQRThemesSuccess, (state, { templates }) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        qr_themes: {
          ...state.sessionInfo.cache.qr_themes,
          templates,
          status: StateStatuses.SUCCESS,
          error: ""
        }
      }
    }
  })),
  on(AppActions.fetchQRThemesFailure, (state, { error }) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        qr_themes: {
          ...state.sessionInfo.cache.qr_themes,
          error: error.error,
          status: StateStatuses.FAILED,
        }
      }
    }
  })),

  on(AppActions.fetchOTSQrCodes, (state, { params, status }) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        pages: {
          ...state.sessionInfo.cache.pages,
          off_the_shelf_qr_codes: {
            ...state.sessionInfo.cache.pages.off_the_shelf_qr_codes,
            params,
            error: "",
            status: status || (!state.sessionInfo.cache.pages.off_the_shelf_qr_codes.response ? StateStatuses.PROCESSING : StateStatuses.PENDING),
          }
        }
      }
    }
  })),
  on(AppActions.fetchOTSQrCodesSuccess, (state, { response }) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        pages: {
          ...state.sessionInfo.cache.pages,
          off_the_shelf_qr_codes: {
            ...state.sessionInfo.cache.pages.off_the_shelf_qr_codes,
            response,
            error: "",
            status: StateStatuses.SUCCESS,
          }
        }
      }
    }
  })),
  on(AppActions.fetchOTSQrCodesFailure, (state, { error }) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        pages: {
          ...state.sessionInfo.cache.pages,
          off_the_shelf_qr_codes: {
            ...state.sessionInfo.cache.pages.off_the_shelf_qr_codes,
            error: error.error,
            status: StateStatuses.FAILED,
          }
        }
      }
    }
  })),
  on(AppActions.setOTSQrCodesStatus, (state, { status }) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        pages: {
          ...state.sessionInfo.cache.pages,
          off_the_shelf_qr_codes: {
            ...state.sessionInfo.cache.pages.off_the_shelf_qr_codes,
            error: "",
            status,
          }
        }
      }
    }
  })),
  on(AppActions.replaceOTSQrCodes, (state, { batch }) => {
    const updated_data = state.sessionInfo.cache.pages.off_the_shelf_qr_codes.response.data.map((batch_) => batch.id === batch_.id ? batch : batch_);
    return {
      ...state,
      sessionInfo: {
        ...state.sessionInfo,
        cache: {
          ...state.sessionInfo.cache,
          pages: {
            ...state.sessionInfo.cache.pages,
            off_the_shelf_qr_codes: {
              ...state.sessionInfo.cache.pages.off_the_shelf_qr_codes,
              response: {
                ...state.sessionInfo.cache.pages.off_the_shelf_qr_codes.response,
                data: updated_data
              }
            }
          }
        }
      }
    };
  }),

  on(AppActions.fetchReferralHistory, (state, { params, status }) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        pages: {
          ...state.sessionInfo.cache.pages,
          referrals: {
            ...state.sessionInfo.cache.pages.referrals,
            params,
            error: "",
            status: status || (!state.sessionInfo.cache.pages.referrals.response ? StateStatuses.PROCESSING : StateStatuses.PENDING),
          }
        }
      }
    }
  })),
  on(AppActions.fetchReferralHistorySuccess, (state, { response }) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        pages: {
          ...state.sessionInfo.cache.pages,
          referrals: {
            ...state.sessionInfo.cache.pages.referrals,
            response,
            error: "",
            status: StateStatuses.SUCCESS,
          }
        }
      }
    }
  })),
  on(AppActions.fetchReferralHistoryFailure, (state, { error }) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        pages: {
          ...state.sessionInfo.cache.pages,
          referrals: {
            ...state.sessionInfo.cache.pages.referrals,
            error: error.error,
            status: StateStatuses.FAILED,
          }
        }
      }
    }
  })),
  on(AppActions.setReferralHistoryStatus, (state, { status }) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        pages: {
          ...state.sessionInfo.cache.pages,
          referrals: {
            ...state.sessionInfo.cache.pages.referrals,
            error: "",
            status,
          }
        }
      }
    }
  })),

  on(AppActions.setOTSBatchCustomizing, (state, { customizing, qr_theme }) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        pages: {
          ...state.sessionInfo.cache.pages,
          off_the_shelf_qr_codes: {
            ...state.sessionInfo.cache.pages.off_the_shelf_qr_codes,
            customizing: {
              ...customizing,
              batch: {
                ...customizing.batch,
                batch_data: {
                  ...customizing.batch.batch_data,
                  qr_theme: qr_theme ? qr_theme : customizing.batch.batch_data.qr_theme
                }
              }
            }
          }
        }
      }
    }
  })),
  on(AppActions.resetSetOTSBatchCustomizing, (state) => ({
    ...state,
    sessionInfo: {
      ...state.sessionInfo,
      cache: {
        ...state.sessionInfo.cache,
        pages: {
          ...state.sessionInfo.cache.pages,
          off_the_shelf_qr_codes: {
            ...state.sessionInfo.cache.pages.off_the_shelf_qr_codes,
            customizing: null
          }
        }
      }
    }
  })),

  on(AppActions.appTearDown, () => initialStateCloned),
  on(AppActions.setSessionInfoQrGeneratingUpdateDraft, (state, { data }) => {
    const drafts = state.sessionInfo.qr_generating.drafts.filter(d => d.selected_category === data.selected_category)
    return {
      ...state,
      sessionInfo: {
        ...state.sessionInfo,
        qr_generating: {
          ...state.sessionInfo.qr_generating,
          drafts: [...drafts, data]
        }
      }
    }
  }),
  on(AppActions.setSessionInfoQrGenerating, (state, { qr_generating }) => {
    return {
      ...state,
      sessionInfo: {
        ...state.sessionInfo,
        qr_generating: {
          ...state.sessionInfo.qr_generating,
          drafts: qr_generating.drafts ? qr_generating.drafts : state.sessionInfo.qr_generating.drafts,
          updating: qr_generating.updating ? qr_generating.updating : state.sessionInfo.qr_generating.updating,
          next_preferred_step: qr_generating.next_preferred_step ? qr_generating.next_preferred_step : state.sessionInfo.qr_generating.next_preferred_step,
          last_viewed_category: qr_generating.last_viewed_category ? qr_generating.last_viewed_category : state.sessionInfo.qr_generating.last_viewed_category,
        }
      }
    }
  }),
  on(AppActions.setSessionInfoCache, (state, { cache }) => {
    return {
      ...state,
      sessionInfo: {
        ...state.sessionInfo,
        cache: {
          ...state.sessionInfo.cache,
          qrs: cache.qrs ? cache.qrs : state.sessionInfo.cache.qrs,
          users: cache.users ? cache.users : state.sessionInfo.cache.users,
          pages: cache.pages ? cache.pages : state.sessionInfo.cache.pages,
          roles: cache.roles ? cache.roles : state.sessionInfo.cache.roles,
          admins: cache.admins ? cache.admins : state.sessionInfo.cache.admins,
          popups: cache.popups ? cache.popups : state.sessionInfo.cache.popups,
          qr_themes: cache.qr_themes ? cache.qr_themes : state.sessionInfo.cache.qr_themes,
          qr_viewing: cache.qr_viewing ? cache.qr_viewing : state.sessionInfo.cache.qr_viewing,
          preferences: cache.preferences ? cache.preferences : state.sessionInfo.cache.preferences,
          popup_viewing: cache.popup_viewing ? cache.popup_viewing : state.sessionInfo.cache.popup_viewing,
          last_dashboard_user_id: cache.last_dashboard_user_id ? cache.last_dashboard_user_id : state.sessionInfo.cache.last_dashboard_user_id,
        }
      }
    }
  }),
  on(AppActions.setSessionInfo, (state, { sessionInfo }) => {
    return {
      ...state,
      sessionInfo
    }
  }),
);
