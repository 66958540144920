export enum CoreProductUseCases {
  MEMORIES_CHARITY = "qr-code-for-charity",
  MEMORIES_CONCERT = "qr-code-for-concerts",
  MEMORIES_PROMS = "qr-code-for-school-proms",
  MEMORIES_WEDDING = "qr-code-for-wedding-photos",
  MEMORIES_HOLIDAY = "qr-code-for-holiday-photos",
  MEMORIES_BABY_SHOWER = "qr-code-for-baby-showers",
  MEMORIES_BIRTHDAY = "qr-code-for-birthday-parties",
  MEMORIES_CORPORATE = "qr-code-for-corporate-events",
  MEMORIES_CHRISTMAS = "qr-code-for-christmas-photos",
  MEMORIES_FAMILY_REUNIONS = "qr-code-for-family-reunions",
  MEMORIES_BACHELORETTE = "qr-code-for-bachelorette-parties",
  MEMORIES_SPORT_CEREMONIES = "qr-code-for-sports-award-ceremonies",

  EVENT_CONCERT = "qr-code-for-concerts",
  EVENT_WEDDING = "qr-code-for-wedding-photos",
  EVENT_BIRTHDAY = "qr-code-for-birthday-parties",
  EVENT_CORPORATE = "qr-code-for-corporate-events",
  EVENT_BACHELORETTE = "qr-code-for-bachelorette-parties",
  EVENT_SPORT_CEREMONIES = "qr-code-for-sports-award-ceremonies",

  PDF_MENU = "qr-code-for-restaurant-menu",
  PDF_REAL_ESTATE = "qr-code-for-real-estate",
  PDF_SPAS_SALONS = "qr-code-for-spas-and-salons",
  PDF_MANUFACTURING = "qr-code-for-product-manuals",
  PDF_EDUCATION = "qr-code-for-educational-materials",
  PDF_MARKETING = "qr-code-for-marketing-materials",
  PDF_GOVERNMENT = "qr-code-for-government",
  PDF_HEALTHCARE = "qr-code-for-medical-records",
  PDF_CONSTRUCTION = "qr-code-for-safety-protocols",
  PDF_HOSPITALITY = "qr-code-for-hospitality",

  IMAGES = "qr-code-for-photos",
  IMAGES_MENU = "qr-code-restaurant-menu",
  IMAGES_REAL_ESTATE = "qr-code-for-real-estate",
  IMAGES_SPAS_SALONS = "qr-code-for-spas-and-salons",

  RESTAURANT_MENU_CAFES = "qr-code-for-cafes", // Cafes & Bakeries
  RESTAURANT_MENU = "qr-code-for-restaurant-menu",
  RESTAURANT_MENU_CATERING = "qr-code-for-catering", // Catering Services and Event Planning
  RESTAURANT_MENU_FOOD_TRUCKS = "qr-code-for-food-trucks", // Food trucks
  RESTAURANT_MENU_HOSPITALITY = "qr-code-for-hospitality",
  RESTAURANT_MENU_RETAIL_STORES = "qr-code-for-retail-stores",

  VCARD_PRO_COMPANY_PROFILE = "qr-code-for-company-profile", // Company Profile
  VCARD_PRO_EMPLOYEE_DIRECTORY = "qr-code-for-employee-directory", // Employee Directory
  VCARD_PRO_CLIENT = "qr-code-for-client-communication", // Client Communication
  VCARD_PRO_NETWORKING = "qr-code-for-networking-events", // Professional Networking
  VCARD_PRO_PERSONAL_BRANDING = "qr-code-for-personal-branding", // Personal Branding
  VCARD_PRO_TRADE_SHOWS = "qr-code-for-trade-shows", // Trade Shows & Events

  PET_PARKS = "qr-code-for-pet-parks", // Dog Parks
  PET_SHOPS = "qr-code-for-pet-shops", // Dog Shops
  PET_EVENTS = "qr-code-for-pet-events", // Pet-Friendly Events
  PET_SITTERS = "qr-code-for-pet-sitters", // Pet Sitters & Pet Walkers
  PET_BOARDING = "qr-code-for-pet-boarding", // Pet Boarding
  PET_MISSING_PET = "qr-code-for-missing-pet", // Lost Pet Recovery & Emergency Situations
  PET_COMPETITIONS = "qr-code-for-pet-competitions", // Pet Competitions & Shows
  PET_GROOMING = "qr-code-for-pet-grooming-services", // Pet Grooming Services
  PET_ADOPTION = "qr-code-for-pet-adoption-centers", // Pet Adoption Centers & Animal Shelters
  PET_MEDICAL = "qr-code-for-medical-information", // Medical Info & Veterinary Visits
  PET_TRANSPORTATION = "qr-code-for-travel-transportation", // Travel & Transportation
  PET_INSURANCE = "qr-code-for-pet-insurance-claims", // Pet Insurance Claims
  PET_INVENTORY_MANAGEMENT = "qr-code-for-pet-inventory-management", // Inventory Management & Farming Livestock Management
  PET_FARMING = "qr-code-for-livestock-farming-management", // Farming Livestock Management

  BUSINESS_PAGE_WEBSITE = "qr-code-for-business-page", // Website URL & Online Store Link
  BUSINESS_PAGE_CONTACT_INFO = "qr-code-for-business-contact-info", // Business Contact Information
  BUSINESS_PAGE_SOCIALS = "qr-code-for-business-social-media", // Social Media Profiles
  BUSINESS_PAGE_BLOG_LINK = "qr-code-for-business-blog-page", // Blog Link & About Us
  BUSINESS_PAGE_SERVICE_SUMMARY = "qr-code-for-business-service-summary", // Product/Service Summary
  BUSINESS_PAGE_OPERATING_HOURS = "qr-code-for-business-operationg-hours", // Operating Hours & On-Site Facilities
  BUSINESS_PAGE_GOOGLE_MAPS = "qr-code-for-business-google-map-location", // Google Maps Location
  BUSINESS_PAGE_VIDEO_CONTENT = "qr-code-for-business-downloadable-resources", // Downloadable Resources & Video Content

  SOCIAL_MEDIA_PODCAST = "qr-code-for-podcast-links", // Podcast Links
  SOCIAL_MEDIA_CONTACT_INFO = "qr-code-for-contact-information", // Contact Information & Profile Links
  SOCIAL_MEDIA_VIDEO_CHANNELS = "qr-code-for-video-channels", // Video Channels
  SOCIAL_MEDIA_UNIFIED_SOCIAL_HUB = "qr-code-for-link-in-bio", // Link-In-Bio & Bio Links Consolidation
  SOCIAL_MEDIA_MUSIC = "qr-code-for-playlists", // Music & Playlists
  SOCIAL_MEDIA_DM = "qr-code-for-direct-messaging", // Direct Messaging

  COUPONS_EXCLUSIVE = "qr-code-for-exclusive-discounts", // Exclusive Discounts & Seasonal Promotions
  COUPONS_LOYALTY = "qr-code-for-loyalty-rewards", // Loyalty Rewards & First-Time Customer Offer
  COUPONS_BIRTHDAY = "qr-code-for-birthday-discounts", // Birthday Discounts
  COUPONS_FLASH = "qr-code-for-flash-sales", // Flash Sales
  COUPONS_BUNDLE = "qr-code-for-bundle-offers", // Bundle Offers & Bulk Purchase Discounts
  COUPONS_BOGO = "qr-code-for-buy-one-get-one", // Buy One, Get One (BOGO)
  COUPONS_CART = "qr-code-for-cart-abandonment", // Cart Abandonment & Newsletter Signup
  COUPONS_IN_STORE = "qr-code-for-in-store-promotions", // In-Store Promotions & Online Store Discounts
  COUPONS_NEW_PRODUCT = "qr-code-for-new-product-launches", // New Product Launches & Clearance Sales**: Provides discounts on newly launched products.
  COUPONS_STUDENT = "qr-code-for-student-discounts", // Student Discounts
  COUPONS_MILITARY = "qr-code-for-military-discounts", // Military Discounts & Healthcare Worker Discounts
  COUPONS_PRODUCT = "qr-code-for-product-review-incentives", // Product Review Incentives
  COUPONS_PARTNER = "qr-code-for-affiliate-discounts", // Partner & Affiliate Discounts
  COUPONS_EARLY = "qr-code-for-early-birds", // Early Birds & Early Access Offers

  FEEDBACK_CUSTOMER = "qr-code-for-customer-feedback", // Customer Satisfaction Surveys
  FEEDBACK_PRODUCT_REVIEW = "qr-code-for-product-feedback", // Product Feedback
  FEEDBACK_SERVICES = "qr-code-for-service-feedback", // Service Quality Feedback
  FEEDBACK_RENTAL = "qr-code-for-rental-service-feedback", // Rental Service Feedback
  FEEDBACK_PET = "qr-code-for-pet-service-feedback", // Pet Service Feedback
  FEEDBACK_AUTOMOTIVE = "qr-code-for-automotive-service-feedback", // Automotive Service Feedback
  FEEDBACK_DELIVERY_SERVICES = "qr-code-for-delivery-service-feedback", // Delivery Service Feedback
  FEEDBACK_POST_PURCHASE = "qr-code-for-post-purchase-feedback", // Post-Purchase & Retail Experience Feedback
  FEEDBACK_EVENT = "qr-code-for-event-feedback", // Event Feedback
  FEEDBACK_RESTAURANT = "qr-code-for-restaurant-feedback", // Restaurant Dining Experience
  FEEDBACK_HOTEL = "qr-code-for-hotel-stay-feedback", // Hotel Stay Feedback
  FEEDBACK_HEALTHCARE = "qr-code-for-healthcare-services-feedback", // Healthcare Services
  FEEDBACK_COURSE = "qr-code-for-course-training-feedback", // Course & Training Evaluation
  FEEDBACK_WORKSHOP = "qr-code-for-workshop-seminar-feedback", // Workshop & Seminar Evaluation
  FEEDBACK_CLASSROOM = "qr-code-for-lecture-feedback", // Classroom & Lecture Feedback
  FEEDBACK_SUPPORT = "qr-code-for-customer-support-feedback", // Customer Support Feedback
  FEEDBACK_WEBSITE = "qr-code-for-website-usability-feedback", // Website Usability
  FEEDBACK_APP = "qr-code-for-app-performance-feedback", // App Performance
  FEEDBACK_NEW_PRODUCT = "qr-code-for-new-product-feedback", // New Product Launch Feedback
  FEEDBACK_TRANSPORT = "qr-code-for-public-transport-feedback", // Public Transport Feedback
  FEEDBACK_GYM = "qr-code-for-gym-feedback", // Gym & Fitness Center Feedback
  FEEDBACK_SPA = "qr-code-for-spa-feedback", // Spa & Salon Feedback
  FEEDBACK_EMPLOYEE = "qr-code-for-employee-feedback", // Employee Feedback
  FEEDBACK_ONLINE_COURSE = "qr-code-for-online-course-feedback", // Online Course Feedback
  FEEDBACK_COMMUNITY = "qr-code-for-community-feedback", // Community Feedback
  FEEDBACK_MEMBERSHIP = "qr-code-for-membership-experience-feedback", // Membership Experience Feedback

  RATING_CUSTOMER = "qr-code-for-customer-rating", // Customer Satisfaction Surveys
  RATING_PRODUCT_REVIEW = "qr-code-for-product-rating", // Product Rating
  RATING_SERVICES = "qr-code-for-service-rating", // Service Quality Rating
  RATING_RENTAL = "qr-code-for-rental-service-rating", // Rental Service Ratings
  RATING_PET = "qr-code-for-pet-service-rating", // Pet Service Ratings
  RATING_AUTOMOTIVE = "qr-code-for-automotive-service-rating", // Automotive Service Ratings
  RATING_DELIVERY_SERVICES = "qr-code-for-delivery-service-rating", // Delivery Service Rating
  RATING_POST_PURCHASE = "qr-code-for-post-purchase-rating", // Post-Purchase & Retail Experience Rating
  RATING_EVENT = "qr-code-for-event-rating", // Event Rating
  RATING_RESTAURANT = "qr-code-for-restaurant-rating", // Restaurant Dining Experience
  RATING_HOTEL = "qr-code-for-hotel-stay-rating", // Hotel Stay Rating
  RATING_HEALTHCARE = "qr-code-for-healthcare-services-rating", // Healthcare Services
  RATING_COURSE = "qr-code-for-course-training-rating", // Course & Training Evaluation
  RATING_WORKSHOP = "qr-code-for-workshop-seminar-rating", // Workshop & Seminar Evaluation
  RATING_CLASSROOM = "qr-code-for-lecture-rating", // Classroom & Lecture Rating
  RATING_SUPPORT = "qr-code-for-customer-support-rating", // Customer Support Rating
  RATING_WEBSITE = "qr-code-for-website-usability-rating", // Website Usability
  RATING_APP = "qr-code-for-app-performance-rating", // App Performance
  RATING_NEW_PRODUCT = "qr-code-for-new-product-rating", // New Product Launch Rating
  RATING_TRANSPORT = "qr-code-for-public-transport-rating", // Public Transport Rating
  RATING_GYM = "qr-code-for-gym-rating", // Gym & Fitness Center Rating
  RATING_SPA = "qr-code-for-spa-rating", // Spa & Salon Rating
  RATING_EMPLOYEE = "qr-code-for-employee-rating", // Employee Rating
  RATING_ONLINE_COURSE = "qr-code-for-online-course-rating", // Online Course Rating
  RATING_MEMBERSHIP = "qr-code-for-membership-experience-rating", // Membership Experience Rating
  RATING_STAFF = "qr-code-for-retail-staff-rating", // Retail Staff Rating

  VIDEOS_PRODUCT_DEMONSTRATION = "qr-code-for-product-demonstration", // Product Demonstrations
  VIDEOS_CUSTOMER_TESTIMONIALS = "qr-code-for-customer-testimonials", // Customer Testimonials
  VIDEOS_EVENT_HIGHLIGHT = "qr-code-for-event-highlights", // Event Highlights
  VIDEOS_EDUCATIONAL_SERIES = "qr-code-for-educational-series", // Educational Series
  VIDEOS_BRAND_STORIES = "qr-code-for-brand-stories", // Brand Stories and Documentaries
  VIDEOS_ENTERTAINMENT = "qr-code-for-promotional-content", // Entertainment and Promotional Content

  VACANCY_WALK_IN = "qr-code-for-walk-in-interviews", // Walk-In Interviews
  VACANCY_COMPREHENSIVE = "qr-code-for-job-listing", // Comprehensive Job Listings
  VACANCY_DEPARTMENT = "qr-code-for-department-job-opening", // Department-Specific Openings
  VACANCY_INTERNSHIP = "qr-code-for-internship-positions", // Internship and Entry-Level Positions

  APP_CROSS_PLATFORM = "qr-code-for-app-download-page", // Cross-Platform App Downloads
  APP_LAUNCH_PROMOTION = "qr-code-for-app-launch-promotion", // App Launch Promotion
  APP_EXCLUSIVE_ACCESS = "qr-code-for-exclusive-app-access", // Exclusive Content Access
  APP_BETA_TESTING = "qr-code-for-app-beta-testing", // Beta Testing & Feedback

  MP3_ALBUM_RELEASES = "qr-code-for-album-releases", // Album Releases
  MP3_SINGLE_TRACK_DOWNLOAD = "qr-code-for-mp3-singles", // Single Track Downloads
  MP3_EXCLUSIVE_CONTENT = "qr-code-for-exclusive-mp3-songs", // Exclusive Content and Bonuses
  MP3_PRE_RELEASE = "qr-code-for-mp3-pre-release-access", // Pre-Release Access

  LONG_PLAIN_TEXT_DETAILED_ARTICLE = "qr-code-for-article", // Detailed Articles and Essays
  LONG_PLAIN_TEXT_MANUAL = "qr-code-for-instruction-manuals", // Instruction Manuals and Guides
  LONG_PLAIN_TEXT_EVENT_AGENDA = "qr-code-for-event-agenda", // Event Agendas and Schedules
  LONG_PLAIN_TEXT_POLICIES = "qr-code-for-company-policies", // Company Policies and Terms

}
