import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { lastValueFrom, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { FunctionsService } from '../modules/core/services/functions.service';
import { from } from "rxjs";
import { CoreService } from '@app/modules/core/services/core.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  session_notification_displayed = false
  constructor(
    private coreService: CoreService,
    private functionsService: FunctionsService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(request, next))
  }

  async handle(request: HttpRequest<any>, next: HttpHandler) {

    let skip_intercept = false
    let skip_error_message = false
    if (request.headers.has('skip-message')) {
      skip_error_message = true
      request = request.clone({
        headers: request.headers.delete('skip-message')
      });
    }

    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    if (request.headers.has('skip')) {
      skip_intercept = true
      request = request.clone({
        headers: request.headers.delete('skip')
      });
    } else {

      const si = this.coreService.getSessionInfo()
      if (si?.token) request = request.clone({ headers: request.headers.set('Authorization', si.token) });
      if (!request.headers.has('Content-Type')) {
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
      }
    }
    return await lastValueFrom(next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.functionsService.logToConsole(event)
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        const error_m = error?.error?.error
        if (!skip_intercept) {
          if (!error_m) {
            // Token expired, access denied or 403
            if (!this.session_notification_displayed && !skip_error_message) {
              this.functionsService.showAlertMessage("Session expired!")
              this.session_notification_displayed = true
            }
            this.coreService.logMeOut()
          } else {
            if (!skip_error_message) {
              this.functionsService.showErrorMessage(error_m)
            }
          }
        }
        return throwError(() => new Error(error_m))
      })
    ));
  }
}
