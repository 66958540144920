import { createAction, props } from '@ngrx/store';
import { StateStatuses } from '../enums/state-statuses.enum';
import { SessionInfo } from '../models/session-info.interface';
import { QrGeneratorNavigator } from '../models/qr-generator-navigator.interface';
import { UserQrsRequestFilters } from '../models/user-qrs-request-filters.interface';
import { SessionInfoCacheAllOptional } from '../models/session-info-cache.interface';
import { ReferralHistoryRecordInterface } from '../models/referral-history-record.interface';
import { QR_GENERATED_STYLE_TEMPLATE } from '../models/qr-generated-style-template.interface';
import { UserReferralsRequestFilters } from '../models/user-referral-request-filters.interface';
import { BatchOperationOTSBatchCustomizingInterface } from '../models/batch-operation.interface';
import { SessionInfoQrGeneratingAllOptional } from '../models/session-info-qr-generating.interface';
import { PreparedPopupGeneratedRecordInterface } from '../models/prepared-popup-generated-record.interface';
import { UserOffTheShelfQrCodesRequestFilters } from '../models/user-off-the-shelf-qr-codes-request-filters.interface';
import { APIResponse, AlaCarteCheckoutInitializeBody, AlaCarteCheckoutSession, AlaCarteProductInterface, AlaCarteQrTypes, BatchOperationInterface, CartItem, Paginated, PreparedQrGeneratedRecordInterface, PricingRequestResponse, QrTheme } from 'shared-library';

export const setSessionInfo = createAction('[App] Setting session info', props<{ sessionInfo: SessionInfo }>());
export const setSessionInfoCache = createAction('[App] Setting session info cache', props<{ cache: SessionInfoCacheAllOptional }>());
export const setSessionInfoQrGeneratingUpdateDraft = createAction('[App] Setting session info qr generating drafts', props<{ data: QrGeneratorNavigator }>());
export const setSessionInfoQrGenerating = createAction('[App] Setting session info qr generating', props<{ qr_generating: SessionInfoQrGeneratingAllOptional }>());

export const fetchSubscriptionPrices = createAction('[User] Fetching Subscription Prices initiated', props<{ url: string; force?: boolean; }>());
export const fetchSubscriptionPricesFailure = createAction('[User] Fetching Subscription Prices Failure', props<{ error: APIResponse }>());
export const fetchSubscriptionPricesSuccess = createAction('[User] Fetching Subscription Prices Success', props<{ response: { data: PricingRequestResponse } }>());

export const fetchProductScansPrices = createAction('[User] Fetching Product Scans Prices initiated', props<{ url: string }>());
export const fetchProductScansPricesFailure = createAction('[User] Fetching Product Scans Prices Failure', props<{ error: APIResponse }>());
export const fetchProductScansPricesSuccess = createAction('[User] Fetching Product Scans Prices Success', props<{ response: { data: PricingRequestResponse } }>());

export const fetchAlaCartePrices = createAction('[Public] Fetching AlaCarte Prices initiated', props<{ qr_category: AlaCarteQrTypes; force?: boolean; }>());
export const fetchAlaCartePricesFailure = createAction('[Public] Fetching AlaCarte Prices Failure', props<{ error: APIResponse }>());
export const fetchAlaCartePricesSuccess = createAction('[Public] Fetching AlaCarte Prices Success', props<{ response: { data: AlaCarteProductInterface[] } }>());

export const syncAlaCarteSession = createAction('[Public] Syncing AlaCarte Checkout Session initiated');
export const syncAlaCarteSessionFailure = createAction('[Public] Syncing AlaCarte Checkout Session Failure', props<{ error: APIResponse }>());
export const syncAlaCarteSessionSuccess = createAction('[Public] Syncing AlaCarte Checkout Session Success', props<{ response: { data: AlaCarteCheckoutSession } }>());

export const initialiseAlaCarteSession = createAction('[Public] Initialising AlaCarte Checkout Session initiated', props<{ data: AlaCarteCheckoutInitializeBody }>());
export const initialiseAlaCarteSessionFailure = createAction('[Public] Initialising AlaCarte Checkout Session Failure', props<{ error: APIResponse }>());
export const initialiseAlaCarteSessionSuccess = createAction('[Public] Initialising AlaCarte Checkout Session Success', props<{ response: { data: AlaCarteCheckoutSession } }>());

export const removeAlaCarteSession = createAction('[Public] Removing AlaCarte Session');
export const removeCartInAlaCarteSession = createAction('[Public] Removing AlaCarte Cart Item', props<{ index: number }>());
export const addToCartInAlaCarteSession = createAction('[Public] Add to AlaCarte Checkout Cart Item', props<{ data: CartItem }>());
export const updateCartInAlaCarteSession = createAction('[Public] Updating AlaCarte Checkout Session initiated', props<{ index: number, data: CartItem }>());

export const QRsTeardown = createAction('[User] Resetting QRs cache');
export const replaceQR = createAction('[User] Replacing Qr', props<{ qr: PreparedQrGeneratedRecordInterface }>());
export const fetchQRsFailure = createAction('[User] Fetching Qrs Failure', props<{ error: APIResponse }>());
export const deleteQRLocal = createAction('[User] Deleting Qr', props<{ qr: PreparedQrGeneratedRecordInterface }>());
export const fetchQRsSuccess = createAction('[User] Fetching Qrs Success', props<{ response: Paginated<PreparedQrGeneratedRecordInterface> }>());
export const fetchQRs = createAction('[User] Fetching Qr list initiated', props<{ params: UserQrsRequestFilters, url: string, status: StateStatuses }>());

export const PopupsTeardown = createAction('[User] Resetting Popups cache');
export const fetchPopupsFailure = createAction('[User] Fetching Popups Failure', props<{ error: APIResponse }>());
export const replacePopup = createAction('[User] Replacing Popups', props<{ popup: PreparedPopupGeneratedRecordInterface }>());
export const deletePopupLocal = createAction('[User] Deleting Popup', props<{ popup: PreparedPopupGeneratedRecordInterface }>());
export const fetchPopupsSuccess = createAction('[User] Fetching Popups Success', props<{ response: Paginated<PreparedPopupGeneratedRecordInterface> }>());
export const fetchPopups = createAction('[User] Fetching Popup list initiated', props<{ params: UserQrsRequestFilters, url: string, status: StateStatuses }>());

export const fetchQRThemes = createAction('[User] Fetching QR Themes initiated', props<{ url: string }>());
export const fetchQRThemesFailure = createAction('[User] Fetching QR Themes Failure', props<{ error: APIResponse }>());
export const fetchQRThemesSuccess = createAction('[User] Fetching QR Themes Success', props<{ templates: QR_GENERATED_STYLE_TEMPLATE[] }>());

export const setOTSQrCodesStatus = createAction('[User] Setting OTS Qr codes status', props<{ status: StateStatuses }>());
export const replaceOTSQrCodes = createAction('[User] Replacing OTS Qr code', props<{ batch: BatchOperationInterface }>());
export const fetchOTSQrCodesFailure = createAction('[User] Fetching OTS Qr codes Failure', props<{ error: APIResponse }>());
export const fetchOTSQrCodesSuccess = createAction('[User] Fetching OTS Qr codes Success', props<{ response: Paginated<BatchOperationInterface> }>());
export const fetchOTSQrCodes = createAction('[User] Fetching OTS Qr codes initiated', props<{ params: UserOffTheShelfQrCodesRequestFilters, url: string, status?: StateStatuses }>());

export const setReferralHistoryStatus = createAction('[User] Setting Referral History status', props<{ status: StateStatuses }>());
export const fetchReferralHistoryFailure = createAction('[User] Fetching Referral History Failure', props<{ error: APIResponse }>());
export const fetchReferralHistorySuccess = createAction('[User] Fetching Referral History Success', props<{ response: Paginated<ReferralHistoryRecordInterface> }>());
export const fetchReferralHistory = createAction('[User] Fetching Referral History initiated', props<{ params: UserReferralsRequestFilters, url: string, status?: StateStatuses }>());

export const resetSetOTSBatchCustomizing = createAction('[User] Resetting OTS batch customizing qr codes');
export const setOTSBatchCustomizing = createAction('[User] Setting OTS batch customizing qr codes', props<{ customizing: BatchOperationOTSBatchCustomizingInterface, qr_theme?: QrTheme }>());

export const appTearDown = createAction('[App] State teardown');
