import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@app/modules/core/config/config';
import { FunctionsService } from '@app/modules/core/services/functions.service';
import { AdminCheckoutSessionsRequestParameters } from '@app/store/models/admin-checkout-sessions-request-parameters.interface';
import { AdminUserInvoicesRequestParameters } from '@app/store/models/admin-invoices-request-parameters.interface';
import { AdminReferralsRequestParameters } from '@app/store/models/admin-referrals-request-parameters.interface';
import { AdminUserQrsRequestParameters } from '@app/store/models/admin-userqrs-request-parameters.interface';
import { CampaignHelper } from '@app/store/models/campaign-helper.interface';
import { IAppConfig } from '@app/store/models/config.interface';
import { NewAdmin } from '@app/store/models/new-admin.interface';
import { NewOffTheShelfQrCodesInterface } from '@app/store/models/new-off-the-shelf-qr-codes.interface';
import { ReferralHistoryRecordInterface } from '@app/store/models/referral-history-record.interface';
import { RequestFilters } from '@app/store/models/request-filters.interface';
import { Observable, firstValueFrom, lastValueFrom, map } from 'rxjs';
import { InvoiceInterface, AppResourceOrganization, AppRole, BatchOperationInterface, ManageBatchOperationOTSQrsRequestValidator, PreparedQrGeneratedRecordInterface, PublicUserDetails, QrTheme, SortDirections, MarketingRecordTypes, ReferralRecordTypes, Paginated, ManageUserResponse, ManageUserModes, AlaCarteCheckoutSession, MarketingReferralResponse, MarketingLeadsResponse, MarketingWeMissYouResponse, MarketingResponse } from 'shared-library';

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    public campaigns: CampaignHelper[] = [
        {
            notify: 0,
            fetched: false,
            processing: false,
            campaign: MarketingRecordTypes.LEADS,
            notify_emails: [{ email: '', name: '' }],
            description: "View leads invitation history",
            title: this.functionService.ucfirst(MarketingRecordTypes.LEADS),
            data: {
                ignored: null,
                responded: null,
                organizations_notified: null,
                emails_invited_by_users: null,
                emails_invited_by_admin: null,
            }
        },
        {
            notify: 50,
            fetched: false,
            processing: false,
            notify_emails: [],
            campaign: MarketingRecordTypes.REFERRAL,
            description: "View referral email history",
            title: this.functionService.ucfirst(MarketingRecordTypes.REFERRAL),
            data: {
                ignored: null,
                responded: null,
                organizations_notified: null,
                emails_invited_by_users: null,
                emails_invited_by_admin: null,
            }
        },
        {
            notify: 50,
            fetched: false,
            processing: false,
            notify_emails: [],
            title: "WE MISS YOU",
            campaign: MarketingRecordTypes.ITS_BEEN_A_WHILE_6_MONTHS,
            description: "Send login reminder to users who have not logged-in in 6 months",
            data: {
                ignored: null,
                responded: null,
                organizations_notified: null,
                emails_invited_by_users: null,
                emails_invited_by_admin: null,
            }
        }
    ]

    constructor(
        private http: HttpClient,
        private functionService: FunctionsService,
        @Inject(APP_CONFIG) private config: IAppConfig,
    ) { }

    getCampaignData(specific_campaign: MarketingRecordTypes, force = false) {
        const campaign = this.campaigns.find(({ campaign }) => campaign === specific_campaign)
        if (!campaign || (campaign.fetched && !force)) return
        campaign.processing = true
        lastValueFrom(this.http.get<{ data: MarketingResponse }>(`${this.config.apiEndpoints.admin.marketing}${campaign.campaign}`))
            .then(({ data }) => {
                campaign.processing = false

                if (typeof data !== typeof false) {
                    campaign.fetched = true
                    campaign.data = {
                        ...campaign.data,
                        ...data as MarketingReferralResponse
                    }
                }

                this.functionService.initializeTooltip()
            })
            .catch(e => {
                this.functionService.logToConsole(e)
                campaign.processing = false
            })
    }

    getUserQRs(data: AdminUserQrsRequestParameters): Observable<Paginated<PreparedQrGeneratedRecordInterface>> {
        const data_clone = { ...data }
        if (!data_clone.status) delete data_clone.status
        if (!data_clone.category) delete data_clone.category
        const url = data.qr_id ? `${this.config.apiEndpoints.admin.qrs}/${data.qr_id}` : this.config.apiEndpoints.admin.qrs
        return this.http.get<{ history: Paginated<PreparedQrGeneratedRecordInterface>; success: boolean; }>(url, { params: data_clone as any }).pipe(map(response => response.history));
    }

    getUserInvoices(data: AdminUserInvoicesRequestParameters): Observable<Paginated<InvoiceInterface>> {
        return this.http.get<{ invoices: Paginated<InvoiceInterface>; success: boolean; }>(this.config.apiEndpoints.admin.invoices.list, { params: data as any }).pipe(map(response => response.invoices));
    }

    getReferrals(data: AdminReferralsRequestParameters): Observable<Paginated<ReferralHistoryRecordInterface>> {
        const url = data.record_type === ReferralRecordTypes.CASH_OUT_REQUEST ? this.config.apiEndpoints.admin.referrals.cash_out : this.config.apiEndpoints.admin.referrals.history
        return this.http.get<{ data: Paginated<ReferralHistoryRecordInterface>; success: boolean; }>(url, { params: data as any }).pipe(map(response => response.data));
    }

    getUsers(data: RequestFilters): Observable<Paginated<PublicUserDetails>> {
        return this.http.get<{ data: ManageUserResponse }>(this.config.apiEndpoints.user.basic, { params: data as any }).pipe(map(response => response.data.users));
    }

    getCheckoutSessions(data: AdminCheckoutSessionsRequestParameters): Observable<Paginated<AlaCarteCheckoutSession>> {
        return this.http.get<{ data: Paginated<AlaCarteCheckoutSession> }>(this.config.apiEndpoints.admin.invoices.checkout_sessions, { params: data as any }).pipe(map(response => response.data));
    }

    async getLoginLink(id: string) {
        let { data: response } = await lastValueFrom(this.http.get<{ data: ManageUserResponse }>(`${this.config.apiEndpoints.user.basic}/${id}/${ManageUserModes.SIGN_IN_LINK}`))
        return response.sign_in_link
    }

    getAdmins(data: RequestFilters): Observable<Paginated<PublicUserDetails>> {
        return this.http.get<{ admins: Paginated<PublicUserDetails>; success: boolean; }>(this.config.apiEndpoints.admin.basic, { params: data as any }).pipe(map(response => response.admins));
    }

    createAdmin(data: NewAdmin): Observable<PublicUserDetails> {
        return this.http.post<{ admin: PublicUserDetails; success: boolean; }>(this.config.apiEndpoints.admin.basic, data).pipe(map(response => response.admin));
    }

    getAdminResourceOrganization(): Observable<AppResourceOrganization[]> {
        return this.http.get<{ data: AppResourceOrganization[]; success: boolean; }>(`${this.config.apiEndpoints.resources.admin}organizations`).pipe(map(response => response.data));
    }

    getAdminResourceRoles(): Observable<AppRole[]> {
        return this.http.get<{ data: AppRole[]; success: boolean; }>(`${this.config.apiEndpoints.resources.admin}role`).pipe(map(response => response.data));
    }

    async getOffTheShelfQrCodesBatch(filters = {
        page: 1,
        limit: 100,
        search: "",
        batch_id: "",
        alacarte_product_id: "",
        sort: SortDirections.DESC
    }): Promise<Paginated<BatchOperationInterface>> {
        const { data } = await firstValueFrom(this.http.get<{ data: Paginated<BatchOperationInterface>; }>(this.config.apiEndpoints.admin.batch_operations.off_the_shelf_qr_codes, { params: filters }));
        return data;
    }

    async checkBatchStatus(batch_id: string): Promise<BatchOperationInterface> {
        const { data } = await firstValueFrom(this.http.get<{ data: BatchOperationInterface; }>(`${this.config.apiEndpoints.admin.batch_operations.status_check}${batch_id}`));
        return data;
    }

    async createOffTheShelfBatch(param: NewOffTheShelfQrCodesInterface): Promise<true> {
        const { data } = await firstValueFrom(this.http.post<{ data: true }>(this.config.apiEndpoints.admin.batch_operations.off_the_shelf_qr_codes, param));
        return data;
    }

    async updateOffTheShelfBatchQRCodes(batch_id: string, param: { qr_theme: QrTheme, qr: ManageBatchOperationOTSQrsRequestValidator }): Promise<boolean> {
        const { data } = await firstValueFrom(this.http.patch<{ data: boolean }>(`${this.config.apiEndpoints.admin.batch_operations.update_off_the_shelf_qr_codes}${batch_id}`, param));
        return data;
    }

}
