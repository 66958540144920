import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { AppState } from '../states/app.states';

import * as fromApp from './app.reducers';
import * as fromAdmin from './admin.reducers';
import { AdminState } from '../states/admin.states';

export interface State {
  [fromApp.appKey]: AppState;
  [fromAdmin.adminKey]: AdminState;
}

export const reducers: ActionReducerMap<State> = {
  [fromApp.appKey]: fromApp.appReducer,
  [fromAdmin.adminKey]: fromAdmin.adminReducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
