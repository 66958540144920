import { Router } from '@angular/router';
import { CoreService } from './core.service';
import { APP_CONFIG } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom, map, Observable } from 'rxjs';
import { FunctionsService } from './functions.service';
import { IAppConfig } from '@app/store/models/config.interface';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { OrderDetails } from '@app/store/models/order-details.interface';
import { PaymentMethod } from '@app/store/models/payment-method.interface';
import { CreditCardBillingAddress } from '@app/store/models/credit-card-billing-address.interface';
import { AlaCarteApplyCouponToOrders, AlaCarteOrderDetails } from '@app/store/models/alacarte-order-details.interface';
import { InvoiceStatus, CouponTypes, AlaCarteCheckoutInitializeBody, CouponValidatedResponse, AlaCarteCheckoutSession, AlaCarteProductInterface, AlaCarteQrTypes, PricingRequestResponse, Pricings, ProductTypes, SystemUserTypes, PaymentMethodTypes, RedeemVoucherResponse } from 'shared-library';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private coreService: CoreService,
    private functionService: FunctionsService,
    @Inject(APP_CONFIG) private readonly config: IAppConfig
  ) { }

  async verifyCoupon(code: string, type: CouponTypes, product_price_id: string, user_id = ""): Promise<CouponValidatedResponse> {
    const si = this.coreService.session_data
    const url = si.user_details.user_category === SystemUserTypes.ADMIN ? this.config.apiEndpoints.user.payments.validate_coupons.replace("/{id}", `/${user_id}`) : this.config.apiEndpoints.user.payments.validate_coupons.replace("/{id}", ``)
    return (await this.httpClient.get(`${url}${code}/verify/${type}/${product_price_id}`).toPromise() as any).result
  }

  async redeemVoucher(code: string, mode: "verify" | "apply" = "verify", user_id = ""): Promise<RedeemVoucherResponse> {
    const si = this.coreService.session_data
    const url = si.user_details.user_category === SystemUserTypes.ADMIN ? this.config.apiEndpoints.user.payments.redeem_voucher.replace("/{id}", `/${user_id}`) : this.config.apiEndpoints.user.payments.redeem_voucher.replace("/{id}", ``)
    const { data } = await lastValueFrom(this.httpClient.get<{ data: RedeemVoucherResponse }>(`${url}${code}/${mode}`, { headers: { "skip-message": "true" } }))
    return data
  }

  initOrderDetails(): OrderDetails {
    const si = this.coreService.session_data
    if (!si) return null
    return {
      tax: 0,
      name: "",
      amount: 0,
      invoice_no: "",
      product_id: "",
      description: [],
      amount_to_pay: 0,
      product_amount: 0,
      product_type: null,
      product_price_id: "",
      status: InvoiceStatus.PENDING,
      plan_subscription_check_result: null,
      stripe_payment_intent_client_secret: "",
      payment_method: PaymentMethodTypes.CREDIT_CARD,
      currency: si.user_details.current_organization_details.currency,
      tax_percentage: si.user_details.current_organization_details.tax_percentage,
      card_id: si.user_details.current_organization_details.payment_methods.primary,
      billing_address: si.user_details.current_organization_details.payment_methods.cards.find(c => c.id == si.user_details.current_organization_details.payment_methods.primary)?.billing_address || this.initBillingAddress(si),
      company_details: si.user_details.current_organization_details.company_details,
      coupons: {
        voucher: null,
        discount: null,
      }
    }
  }

  initBillingAddress(si: SessionInfo): CreditCardBillingAddress {
    return {
      address: "",
      email: si.user_details.email,
      full_name: si.user_details.name,
      mobile: si.user_details.mobile_no,
      city: si.miscellaneous?.location?.city,
      zip: si.miscellaneous?.location?.postal,
      state: si.miscellaneous?.location?.state,
      country: si.miscellaneous?.location?.country,
    }
  }

  startPayment(price_details: Pricings, init = true) {
    const session_info = this.coreService.session_data
    session_info.requested_payment_route = window.location.href
    if (init) session_info.payment.order_details = this.initOrderDetails()

    session_info.payment.order_details.name = price_details.title
    session_info.payment.order_details.coupons = {
      voucher: null,
      discount: null,
    }
    session_info.payment.order_details.currency = price_details.currency
    session_info.payment.order_details.product_amount = price_details.price
    if (price_details.record_type === ProductTypes.more_scans) {
      session_info.payment.order_details.description = ["Visit limit extension"]
    }
    session_info.payment.order_details.amount = session_info.payment.order_details.product_amount
    if (price_details.record_type === ProductTypes.plan_subscription) {
      session_info.payment.order_details.description = [
        `${price_details.plan.user_seats} user ${this.functionService.pluralize('seat', price_details.plan.user_seats)}`,
        `${price_details.plan.workspaces} ${this.functionService.pluralize('workspace', price_details.plan.workspaces)}`,
      ]
      session_info.payment.order_details.description.unshift(`${price_details.plan.unlimited_scans ? 'Unlimited QR Code Scans' : price_details.plan.scans.toLocaleString() + ' monthly visits'}`)
      if (session_info.payment.order_details.plan_subscription_check_result?.price) {
        session_info.payment.order_details.amount = +session_info.payment.order_details.plan_subscription_check_result.price.toFixed(2)
      }
    }
    session_info.payment.order_details.tax = this.functionService.percentageCalculator(session_info.payment.order_details.tax_percentage, session_info.payment.order_details.amount)
    session_info.payment.order_details.product_price_id = price_details.id
    session_info.payment.order_details.product_id = price_details.product_id
    session_info.payment.order_details.amount_to_pay = session_info.payment.order_details.amount + session_info.payment.order_details.tax
    session_info.payment.order_details.product_type = price_details.record_type
    session_info.payment.order_details.payment_method = session_info.miscellaneous.payment_methods.find(pm => pm === PaymentMethodTypes.CREDIT_CARD)
    if (!session_info.payment.order_details.payment_method && session_info.miscellaneous.payment_methods.length) {
      session_info.payment.order_details.payment_method = session_info.miscellaneous.payment_methods[0]
    }
    this.coreService.setSessionData(session_info)
    this.router.navigateByUrl(`/user/payments/checkout`)
  }

  async savePaymentInformation(id: string, data: CreditCardBillingAddress, user_id = ""): Promise<{ cards: PaymentMethod }> {
    const session_info = this.coreService.session_data
    const url = session_info.user_details.user_category === SystemUserTypes.ADMIN ? this.config.apiEndpoints.user.payments.manage_payment_methods.replace("/{id}", `/${user_id}`) : this.config.apiEndpoints.user.payments.manage_payment_methods.replace("/{id}", ``)
    return (await this.httpClient.patch(`${url}manage/${id}`, data).toPromise() as any).data
  }

  getPrices(url: string): Observable<{ data: PricingRequestResponse }> {
    return this.httpClient.get<{ data: PricingRequestResponse }>(url).pipe(map(response => response));
  }

  getAlaCartePrices(qr_category: AlaCarteQrTypes): Observable<{ data: AlaCarteProductInterface[] }> {
    let url = `${this.config.apiEndpoints.resources.ala_carte}?qr_category=${qr_category}`
    return this.httpClient.get<{ data: AlaCarteProductInterface[] }>(url).pipe(map(response => response));
  }

  initialiseAlaCarteSession(data: AlaCarteCheckoutInitializeBody): Observable<{ data: AlaCarteCheckoutSession }> {
    let url = `${this.config.apiEndpoints.user.payments.ala_carte}initialize`
    return this.httpClient.post<{ data: AlaCarteCheckoutSession }>(url, data).pipe(map(response => response));
  }

  syncAlaCarteSession(data: AlaCarteCheckoutSession): Observable<{ data: AlaCarteCheckoutSession }> {
    let url = `${this.config.apiEndpoints.user.payments.ala_carte}sync`
    return this.httpClient.post<{ data: AlaCarteCheckoutSession }>(url, {
      id: data.id,
      cart: data.cart,
      sortKey: data.sortKey,
    }).pipe(map(response => response));
  }

  async resumeAlaCarteSession(id: string): Promise<{ data: AlaCarteCheckoutSession }> {
    let url = `${this.config.apiEndpoints.user.payments.ala_carte}initialize`
    return lastValueFrom(this.httpClient.get<{ data: AlaCarteCheckoutSession }>(url, { params: { id } }));
  }

  async initialiseAlaCarteSessionPayment(data: AlaCarteOrderDetails): Promise<{ data: AlaCarteCheckoutSession }> {
    let url = `${this.config.apiEndpoints.user.payments.ala_carte}pay`
    return await lastValueFrom(this.httpClient.post<{ data: AlaCarteCheckoutSession }>(url, data));
  }

  async applyDiscountToAlaCarteItems(data: AlaCarteApplyCouponToOrders): Promise<{ data: Array<CouponValidatedResponse> }> {
    let url = `${this.config.apiEndpoints.user.payments.ala_carte}verify-coupon`
    return await lastValueFrom(this.httpClient.post<{ data: Array<CouponValidatedResponse> }>(url, data));
  }
}
