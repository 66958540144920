import { from, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as AppActions from '../actions/app.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { selectAppState } from '../selectors/app.selectors';
import { CoreService } from '@app/modules/core/services/core.service';
import { LandingPageLoggerDataModes, timeExpired } from 'shared-library';
import { PaymentService } from '@app/modules/core/services/payment.service';
import { UserQrGeneratorService } from '@app/modules/user/services/user-qr-generator.service';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class AppEffects {


  fetchSubscriptionPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchSubscriptionPrices),
      withLatestFrom(this.store.select(selectAppState)),
      filter(([action, appState]) => !appState.data.cache.products.plans?.length || action.force || timeExpired(appState.data.cache.refresh_pricing)),
      switchMap(([{ url }]) => from(this.paymentService.getPrices(url))
        .pipe(
          map(response => AppActions.fetchSubscriptionPricesSuccess({ response })),
          catchError(error => of(AppActions.fetchSubscriptionPricesFailure({ error: this.coreService.handleError(error) })))
        )
      )
    )
  );

  fetchAlaCartePrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchAlaCartePrices),
      withLatestFrom(this.store.select(selectAppState)),
      filter(([action, appState]) => !appState.data.cache.products.alacarte[action.qr_category]?.length || action.force || timeExpired(appState.data.cache.refresh_alacarte[action.qr_category])),
      switchMap(([{ qr_category }]) => from(this.paymentService.getAlaCartePrices(qr_category))
        .pipe(
          map(response => AppActions.fetchAlaCartePricesSuccess({ response })),
          catchError(error => of(AppActions.fetchAlaCartePricesFailure({ error: this.coreService.handleError(error) })))
        )
      )
    )
  );

  initialiseAlaCarteSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.initialiseAlaCarteSession),
      withLatestFrom(this.store.select(selectAppState)),
      switchMap(([{ data }]) => from(this.paymentService.initialiseAlaCarteSession(data))
        .pipe(
          map(response => {
            this.coreService.notifyBackEnd(LandingPageLoggerDataModes.CART_INITIALIZED, { email: response.data.cart.email, sortKey: response.data.sortKey });
            return AppActions.initialiseAlaCarteSessionSuccess({ response })
          }),
          tap(() => this.router.navigate(['/cart'])),
          catchError(error => of(AppActions.initialiseAlaCarteSessionFailure({ error: this.coreService.handleError(error) })))
        )
      )
    )
  );

  syncAlaCarteSessionEffectWatcher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.removeCartInAlaCarteSession, AppActions.addToCartInAlaCarteSession, AppActions.updateCartInAlaCarteSession),
      map(() => AppActions.syncAlaCarteSession())
    )
  );

  syncAlaCarteSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.syncAlaCarteSession),
      withLatestFrom(this.store.select(selectAppState)),
      distinctUntilChanged(),
      debounceTime(2000),
      switchMap(([_, data]) => from(this.paymentService.syncAlaCarteSession(data.data.cache.alacarte_session))
        .pipe(
          map(response => {
            return AppActions.syncAlaCarteSessionSuccess({ response })
          }),
          catchError(error => of(AppActions.syncAlaCarteSessionFailure({ error: this.coreService.handleError(error) })))
        )
      )
    )
  );

  fetchScanProductPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchProductScansPrices),
      withLatestFrom(this.store.select(selectAppState)),
      filter(([action, appState]) => !appState.data.cache.products.scans?.length),
      switchMap(([{ url }]) => from(this.paymentService.getPrices(url))
        .pipe(
          map(response => AppActions.fetchProductScansPricesSuccess({ response })),
          catchError(error => of(AppActions.fetchProductScansPricesFailure({ error: this.coreService.handleError(error) })))
        )
      )
    )
  );

  fetchQRs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchQRs),
      switchMap(({ params, url }) => from(this.userQrGeneratorService.getQrs(url, params))
        .pipe(
          map(response => AppActions.fetchQRsSuccess({ response })),
          catchError(error => of(AppActions.fetchQRsFailure({ error: this.coreService.handleError(error) })))
        )
      )
    )
  );

  fetchPopups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchPopups),
      switchMap(({ params, url }) => from(this.userQrGeneratorService.getPopups(url, params))
        .pipe(
          map(response => AppActions.fetchPopupsSuccess({ response })),
          catchError(error => of(AppActions.fetchPopupsFailure({ error: this.coreService.handleError(error) })))
        )
      )
    )
  );

  fetchQRTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchQRThemes),
      withLatestFrom(this.store.select(selectAppState)),
      filter(([action, appState]) => !appState.sessionInfo.cache.qr_themes.templates?.length),
      switchMap(([{ url }]) => from(this.userQrGeneratorService.getQRThemes(url))
        .pipe(
          map(templates => AppActions.fetchQRThemesSuccess({ templates })),
          catchError(error => of(AppActions.fetchQRThemesFailure({ error: this.coreService.handleError(error) })))
        )
      )
    )
  );

  fetchOTSQrCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchOTSQrCodes),
      switchMap(({ params, url }) => from(this.userQrGeneratorService.getOffTheShelfQrCodes(url, params))
        .pipe(
          map(response => AppActions.fetchOTSQrCodesSuccess({ response })),
          catchError(error => of(AppActions.fetchOTSQrCodesFailure({ error: this.coreService.handleError(error) })))
        )
      )
    )
  );

  fetchReferralHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchReferralHistory),
      switchMap(({ params, url }) => from(this.userQrGeneratorService.getReferralHistory(url, params))
        .pipe(
          map(response => AppActions.fetchReferralHistorySuccess({ response })),
          catchError(error => of(AppActions.fetchReferralHistoryFailure({ error: this.coreService.handleError(error) })))
        )
      )
    )
  );

  constructor(
    private store: Store,
    private router: Router,
    private actions$: Actions,
    private coreService: CoreService,
    private paymentService: PaymentService,
    private userQrGeneratorService: UserQrGeneratorService
  ) { }
}
