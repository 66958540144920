import { createAction, props } from '@ngrx/store';
import { LoginResponse, APIResponse } from 'shared-library';
import { AuthData } from '@app/store/models/auth-data.interface';
import { StateStatuses } from '@app/store/enums/state-statuses.enum';
import { LoginParameters } from '@app/store/models/login-parameters.interface';

export const login = createAction('[Auth] Login', props<{ data: LoginParameters }>());
export const loginFailure = createAction('[Auth] Login Failure', props<{ error: APIResponse }>());
export const loginSuccess = createAction('[Auth] Login Success', props<{ response: { data: LoginResponse } }>());

export const signup = createAction('[Auth] Signup', props<{ data: AuthData }>());
export const signupFailure = createAction('[Auth] Signup Failure', props<{ error: APIResponse }>());
export const signupSuccess = createAction('[Auth] Signup Success', props<{ response: APIResponse }>());

export const authTearDown = createAction('[Auth] Authentication Tear Down');
export const authSetStatus = createAction('[Auth] Authentication Set Status', props<{ status: StateStatuses }>());

export const setActivatingOTS = createAction('[Auth] Setting OTS', props<{ data: { short_id: string; activation_code_ots_qr: string; } }>());
