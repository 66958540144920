import moment from 'moment';
import { Store } from '@ngrx/store';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { APP_CONFIG } from '../config/config';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Inject, Injectable } from '@angular/core';
import { StarRatingComponent } from 'ng-starrating';
import { Tab } from '@app/store/models/tab.interface';
import { FunctionsService } from './functions.service';
import { environment } from 'src/environments/environment';
import { getAppState } from '@app/store/states/app.states';
import * as AppActions from '@app/store/actions/app.actions';
import { IAppConfig } from '@app/store/models/config.interface';
import * as AdminActions from '@app/store/actions/admin.actions';
import { AuthData } from '@app/store/models/auth-data.interface';
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { StateStatuses } from '@app/store/enums/state-statuses.enum';
import { SessionInfo } from '@app/store/models/session-info.interface';
import * as AuthActions from '@app/modules/auth/store/actions/auth.actions';
import { UserTabRootPages } from '@app/store/enums/user-tab-root-pages.enum';
import { LoginParameters } from '@app/store/models/login-parameters.interface';
import { UserTabChildPages } from '@app/store/enums/user-tab-child-pages.enum';
import { CoreProductUseCases } from '@app/store/enums/core-product-use-cases.enum';
import { CoreProductUseCase } from '@app/store/models/core-product-use-cases.interface';
import { SessionInfoLastRoutes } from '@app/store/models/session-info-last-routes.interface';
import { UserQrGeneratorService } from '@app/modules/user/services/user-qr-generator.service';
import { BehaviorSubject, Observable, Subscription, firstValueFrom, lastValueFrom, tap } from 'rxjs';
import { OrganizationInterfaceRequiredActions } from '@app/store/models/organization-required-actions.interface';
import { APIResponse, ErrorMessage, LandingPageLoggerDataModes, LoginResponse, OrganizationRequiredActions, PopupCategories, PublicQrDetails, PublicUserDetails, QrAssetResponse, QrGeneratorNavigatorCategories, QrGeneratorNavigatorRESTAURANTMENU, QRLogo, SortDirections, Statuses, SystemUserRoleTypes, SystemUserTypes, TokenPayloadResponse } from 'shared-library';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  session_data: SessionInfo = null
  private session_data_ob = new BehaviorSubject(this.session_data)
  session_dataObservable = this.session_data_ob.asObservable()

  single_execution_subscription: Subscription
  private readonly session_vname = "session_info";

  constructor(
    private store: Store,
    private router: Router,
    private http: HttpClient,
    private qrService: UserQrGeneratorService,
    private functionService: FunctionsService,
    private socialAuthService: SocialAuthService,
    @Inject(APP_CONFIG) private config: IAppConfig,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.importDataFromLocalStorage()
  }

  login(data: LoginParameters) {
    return this.http.post<{ data: LoginResponse; }>(this.config.apiEndpoints.auth.signin, data, { headers: { "skip-message": "true" } }).pipe(
      tap(response => {
        if (response.data.token_type === "session") {
          this.logMeIn(response.data.token)
        }
        if (response.data.token_type === "password_reset") {
          this.router.navigate([`/auth/resetpass?code=${response.data.token}`])
        }
        return response.data
      })
    );
  }

  signup(data: AuthData): Observable<APIResponse> {
    return this.http.post<APIResponse>(this.config.apiEndpoints.auth.signup, data, { headers: { "skip-message": "true" } }).pipe(
      tap(response => {
        setTimeout(() => {
          this.router.navigateByUrl(`/auth/signin`)
        }, 3000)
        return response
      })
    );
  }

  setSessionData(si: SessionInfo, emit = true): void {
    this.session_data = si;
    if (emit) {
      this.session_data_ob.next(si);
      this.functionService.logToConsole(this.session_data)
    }
    // this.store.dispatch(AppActions.setSessionInfo({ sessionInfo: this.functionService.deepCopy(si) }))
    this.functionService.saveToDisk(this.session_vname, JSON.stringify(this.session_data))
  }

  importDataFromLocalStorage(): void {
    const session_info = this.getSessionInfo();
    if (!session_info) return;
    if (environment.app_version !== session_info.version) {
      this.logMeOut()
      return
    }
    this.functionService.logToConsole({
      app_version: session_info.version,
      app_version_environment: environment.app_version,
      api_version_environment: session_info.miscellaneous?.version
    })
    this.setSessionData(session_info);
  }

  private gotoURL(uri: string, skipLocationChange = false) {
    this.router.navigateByUrl(`/${this.session_data.user_details.user_category}/create-qr-code`, { skipLocationChange }).then(() => {
      this.router.navigate([uri])
    });
  }

  /**
   * Do not change this behaviour without checking with Off The Shelf activation process
   * @returns
   */
  goHome(): void {
    if (!this.isLoggedIn()) {
      this.router.navigateByUrl('/')
      return
    }
    if (
      this.session_data.user_details.user_category === SystemUserTypes.USER &&
      (!(this.session_data.user_details.current_organization_details.current_team_details.stats.qrs.active + this.session_data.user_details.current_organization_details.current_team_details.stats.qrs.inactive) || this.session_data.qr_generating.next_preferred_step) &&
      (this.hasPermission(this.session_data.user_details.user_category + '.USERMANAGEMENT.QRREADNWRITE') || this.hasPermission(this.session_data.user_details.user_category + '.USERMANAGEMENT.QRFULLACCESS'))) {
      this.gotoURL(`/${this.session_data.user_details.user_category}/${UserTabRootPages.CREATE_QR}`)
      return
    }
    this.gotoURL(`/${this.session_data.user_details.user_category}/dashboard`)
  }

  handleError(error: HttpErrorResponse): APIResponse {
    return {
      success: (error as any)?.success || false,
      error: error?.message ? ErrorMessage[error?.message] || error.message : ErrorMessage.UNKNOWN_ERROR,
    }
  }

  hasPermission(permission: string, who: PublicUserDetails = null): boolean {
    who = who || this.session_data.user_details
    if (!who) return false
    let module_level = permission.split(".")
    return (who.current_organization_details.current_team_details.roles.includes("*") ||
      (who.current_organization_details.current_team_details.roles.includes(`${module_level[0]}.${module_level[1]}`) && who.current_organization_details.current_team_details.roles.includes(permission)))
  }

  private initSessionLastRoutes(): SessionInfoLastRoutes {
    return {
      new_home: "/",
      b4payment: "",
      lastvisited: "",
      tabs: {
        admin: {
          profile: null
        },
        operations: {
          loginas: null
        },
        users: {
          root: null,
          profile: null,
        },
      }
    }
  }

  private initSessionData(): SessionInfo {
    return {
      token: "",
      proxy_user: "",
      app_busy: false,
      version: environment.app_version,
      last_routes: this.session_data ? JSON.parse(JSON.stringify(this.session_data.last_routes)) : JSON.parse(JSON.stringify(this.initSessionLastRoutes())),
      requested_payment_route: "",
      payment: {
        order_details: null,
        alacarte_session: null,
        selected_subscription_price: null
      },
      resources: {
        roles: [],
        prices: [],
        qr_logos: this.session_data ? JSON.parse(JSON.stringify(this.session_data.resources.qr_logos)) : [],
        currencies: [],
        organizations: []
      },
      user_details: null,
      miscellaneous: null,
      cache: {
        qrs: [],
        popups: [],
        users: [],
        admins: [],
        roles: [],
        qr_viewing: null,
        popup_viewing: null,
        last_dashboard_user_id: "",
        preferences: {
          warnings: {
            qr_untag: true,
            qr_tag_deletion: true,
            popup_tag_deletion: true,
            workspace_view_grid: true,
          }
        },
        qr_themes: {
          error: "",
          templates: [],
          status: StateStatuses.PENDING,
        },
        pages: {
          qrs: {
            error: "",
            status: StateStatuses.PENDING,
            params: {
              page: 1,
              limit: 20,
              tag_id: "",
              search: "",
              status: Statuses.ACTIVE,
              sort: SortDirections.DESC
            },
            response: null
          },
          popups: {
            error: "",
            status: StateStatuses.PENDING,
            params: {
              page: 1,
              limit: 20,
              tag_id: "",
              search: "",
              status: Statuses.ACTIVE,
              sort: SortDirections.DESC
            },
            response: null
          },
          off_the_shelf_qr_codes: {
            error: "",
            status: StateStatuses.PENDING,
            params: {
              page: 1,
              limit: 20,
              search: "",
              sort: SortDirections.DESC
            },
            response: null
          },
          referrals: {
            error: "",
            status: StateStatuses.PENDING,
            params: {
              page: 1,
              limit: 100,
              search: "",
              year: moment().year(),
              sort: SortDirections.DESC
            },
            response: null
          }
        }
      },
      qr_generating: {
        last_viewed_category: QrGeneratorNavigatorCategories.BUSINESS_PAGE,
        // plans: [],
        updating: null,
        drafts: []
      },
      popup_generating: {
        drafts: [],
        updating: null,
        last_viewed_category: PopupCategories.LIGHTBOX
      }
    }
  }

  async fetchPublicResource(mode: "qr-logos" = "qr-logos") {
    if (!this.session_data.last_refreshed || moment().isAfter(moment(this.session_data.last_refreshed).add(1, "day"))) {
      const qr_logos = (await this.http.get(`${this.config.apiEndpoints.resources.public}${mode}`).toPromise() as any).data as QRLogo[]
      this.session_data.resources.qr_logos = qr_logos
      this.session_data.last_refreshed = moment().format("YYYY-MM-DD HH:mm:ss")
    }
    this.setSessionData(this.session_data)
  }

  initializeSession() {
    if (!this.session_data) {
      this.session_data = this.initSessionData()
      this.setSessionData(this.session_data)
    }
    if (!this.isLoggedIn()) {
      this.fetchPublicResource()
    }
  }

  async logMeIn(token: string) {
    const app_state = await getAppState(this.store)
    this.session_data = this.session_data || app_state.sessionInfo
    this.session_data.token = token
    this.setSessionData(this.session_data, false)

    const session_details = await this.getSessionData()
    this.session_data.proxy_user = session_details.data.meta?.proxying
    this.session_data.resources = session_details.data.resources
    this.session_data.user_details = session_details.data.user_data
    this.session_data.cache = {
      ...this.session_data.cache,
      qrs: [],
      users: [],
      popups: [],
      admins: [],
      roles: [],
      qr_viewing: null,
      popup_viewing: null,
      last_dashboard_user_id: "",
      preferences: {
        warnings: {
          qr_untag: true,
          qr_tag_deletion: true,
          popup_tag_deletion: true,
          workspace_view_grid: true,
        }
      }
    }
    this.session_data.miscellaneous = session_details.data.miscellaneous
    if (!this.session_data.resources.roles) this.session_data.resources.roles = []
    if (!this.session_data.resources.prices) this.session_data.resources.prices = []
    if (!this.session_data.resources.currencies) this.session_data.resources.currencies = []
    if (!this.session_data.resources.organizations) this.session_data.resources.organizations = []
    this.functionService.initThirdParty()

    const finishLogin = () => {
      if (this.session_data.last_routes.lastvisited) {
        this.gotoURL(this.session_data.last_routes.lastvisited)
      } else
        this.goHome()

      this.store.dispatch(AuthActions.authTearDown())
      this.session_data.app_busy = false
      this.setSessionData(this.session_data)

    }

    if (this.session_data.miscellaneous.session?.ots_qr) {
      const result = this.qrService.activateOTS(this, this.session_data, this.session_data.miscellaneous.session.ots_qr as PublicQrDetails, (this.session_data.miscellaneous.session.ots_qr.data as QrGeneratorNavigatorRESTAURANTMENU)?.menu?.currency || app_state.currency, this.session_data.miscellaneous.session.activation_code_ots_qr)
      if (result !== true) {
        finishLogin()
      }
    } else {
      finishLogin()
    }

  }

  async refreshSession() {
    const session_data = await this.getSessionData()
    const clone_session_info: SessionInfo = JSON.parse(JSON.stringify(this.session_data))
    clone_session_info.resources = session_data.data.resources
    clone_session_info.proxy_user = session_data.data.meta?.proxying
    clone_session_info.user_details = session_data.data.user_data
    if (!clone_session_info.resources.roles) clone_session_info.resources.roles = []
    if (!clone_session_info.resources.prices) clone_session_info.resources.prices = []
    if (!clone_session_info.resources.currencies) clone_session_info.resources.currencies = []
    if (!clone_session_info.resources.organizations) clone_session_info.resources.organizations = []
    clone_session_info.app_busy = false
    this.setSessionData(clone_session_info)
    this.functionService.initThirdParty()
  }

  isLoggedIn(): boolean {
    return !!this.getSessionInfo()?.token;
  }

  async switchWorkspace(workspace: string) {
    if (workspace === this.session_data.user_details.current_organization_details.current_team_details.id) return
    try {
      this.session_data.app_busy = true
      this.setSessionData(this.session_data)
      const url = this.config.apiEndpoints.user.workspaces.list.replace("/{id}", ``)
      const response = (await this.http.get(`${url}/${workspace}/switch-workspace`).toPromise() as any).data as { token: string; }
      await this.logMeIn(response.token)
      window.location.reload()
    } catch (error) {
      this.session_data.app_busy = true
      this.setSessionData(this.session_data)
    }
  }

  async logMeOut(redirect = true) {
    this.functionService.closeAllModals()

    this.store.dispatch(AppActions.appTearDown())
    this.store.dispatch(AuthActions.authTearDown())
    this.store.dispatch(AdminActions.adminTearDown())

    const bouncer = () => {
      try {
        localStorage.removeItem(this.session_vname);
      } catch (error) {
        // Local storage is not allowed in cognito mode, try session storage instead
        sessionStorage.removeItem(this.session_vname);
      }
      const si = this.initSessionData();
      si.last_routes = JSON.parse(JSON.stringify(this.initSessionLastRoutes()));
      this.setSessionData(si)
      if (!window.location.href.includes('/qrs/') && redirect) {
        this.router.navigateByUrl("/")
      }
    }

    if (!this.isLoggedIn()) {
      bouncer()
      return;
    }

    this.session_data.app_busy = true
    this.setSessionData(this.session_data)

    try {
      await lastValueFrom(this.http.delete(this.config.apiEndpoints.auth.session_data, { headers: { "skip-message": "true" } }));
    } catch (error) {
    }
    try {
      await this.socialAuthService.signOut(true)
    } catch (error) {
    }
    bouncer()
  }

  getSessionInfo(): SessionInfo {
    const si = this.functionService.retrieveFromDisk(this.session_vname);
    return si ? JSON.parse(si) : null
  }

  async getSessionData(): Promise<{ data: TokenPayloadResponse }> {
    return await lastValueFrom(this.http.get<{ data: TokenPayloadResponse }>(this.config.apiEndpoints.auth.session_data))
  }

  async getRecaptchaToken(action: "signin" = "signin"): Promise<string> {
    if (this.single_execution_subscription) this.single_execution_subscription.unsubscribe();
    return new Promise((yeap, nope) => {
      this.single_execution_subscription = this.recaptchaV3Service.execute(action).subscribe(
        (token) => {
          yeap(token)
        },
        (error) => {
          nope(error)
        }
      );
    })
  }

  userManagementTabs(session_info: SessionInfo, user_details: PublicUserDetails): { page: UserTabRootPages; tabs: Tab[] } {
    if (!session_info?.user_details) return null
    let _filtered: Tab[] = [
      {
        children: [],
        include_in_menu: true,
        icon: "bi bi-bar-chart",
        upgrade_required: false,
        description: "Dashboard",
        page: UserTabRootPages.DASHBOARD
      },
      {
        children: [
          {
            icon: "bi bi-person",
            description: "Profile",
            upgrade_required: false,
            page: UserTabChildPages.PROFILE,
          },
          {
            description: "Billing",
            upgrade_required: false,
            icon: "bi bi-credit-card",
            page: UserTabChildPages.BILLING,
          },
          // {
          //     icon: "bi bi-gear",
          //     upgrade_required: false,
          //     description: "Settings",
          //     page: UserTabChildPages.SETTINGS,
          // },
          {
            icon: "bi bi-view-list",
            upgrade_required: false,
            description: "Workspaces",
            page: UserTabChildPages.WORKSPACES,
          },
          {
            icon: "bi bi-key",
            upgrade_required: false,
            description: "API Keys",
            page: UserTabChildPages.API_KEYS,
          },
        ],
        icon: "bi bi-gear",
        include_in_menu: true,
        upgrade_required: false,
        description: "Account settings",
        page: UserTabRootPages.ACCOUNT_SETTINGS
      },
      {
        children: [],
        include_in_menu: true,
        upgrade_required: false,
        description: "Create QR Code",
        icon: "bi bi-plus-circle",
        page: UserTabRootPages.CREATE_QR
      },
      {
        children: [],
        include_in_menu: false,
        icon: "bi bi-upc-scan",
        description: "Edit QR",
        upgrade_required: false,
        page: UserTabRootPages.EDIT_QR,
      },
      {
        children: [],
        include_in_menu: true,
        icon: "bi bi-qr-code",
        upgrade_required: false,
        description: "My QR Codes",
        page: UserTabRootPages.QRS
      },
      {
        children: [],
        icon: "bi bi-bookshelf",
        include_in_menu: true,
        upgrade_required: false,
        description: "Off-The-Shelf QR Codes",
        page: UserTabRootPages.OFF_THE_SHELF_QR_CODES
      },
      {
        children: [],
        icon: "bi bi-bookshelf",
        include_in_menu: false,
        upgrade_required: false,
        description: "Off-The-Shelf QR Codes",
        page: UserTabRootPages.OFF_THE_SHELF_QR_CODES_BATCH
      },
      {
        children: [],
        include_in_menu: true,
        upgrade_required: false,
        icon: "bi bi-window-plus",
        description: "Create Pop-up",
        page: UserTabRootPages.CREATE_POPUPS
      },
      {
        children: [],
        icon: "bi bi-upc-scan",
        include_in_menu: false,
        upgrade_required: false,
        description: "Edit Pop-up",
        page: UserTabRootPages.EDIT_POPUPS
      },
      {
        children: [],
        icon: "bi bi-back",
        include_in_menu: true,
        upgrade_required: false,
        description: "My Pop-ups",
        page: UserTabRootPages.POPUPS
      },
      {
        children: [],
        include_in_menu: true,
        icon: "bi bi-cash-coin",
        upgrade_required: false,
        description: "Referrals",
        page: UserTabRootPages.REFERRALS
      },
      {
        children: [],
        icon: "bi bi-receipt",
        include_in_menu: false,
        upgrade_required: false,
        description: "Redeem Voucher",
        page: UserTabRootPages.REDEEM_VOUCHER
      },
    ]

    {
      const _index = _filtered.findIndex(f => f.page === UserTabRootPages.DASHBOARD)
      if (_index > -1) {
        if (!this.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.DASHBOARD')) {
          if (user_details.current_organization_details.current_team_details.role_id === SystemUserRoleTypes.OWNER) {
            _filtered[_index].upgrade_required = true
          } else {
            _filtered.splice(_index, 1)
          }
        }
      }
    }

    {
      const _index = _filtered.findIndex(f => f.page === UserTabRootPages.ACCOUNT_SETTINGS)
      if (_index > -1) {
        if (
          SystemUserTypes.ADMIN === session_info.user_details.user_category && !this.hasPermission('admin.USERMANAGEMENT.R')
        ) {
          _filtered[_index].children = _filtered[_index].children.filter(p => p.page !== UserTabChildPages.PROFILE)
        }
        if (!this.hasPermission(`${session_info.user_details.user_category}.USERMANAGEMENT.PAYMENT`)) {
          _filtered[_index].children = _filtered[_index].children.filter(p => p.page !== UserTabChildPages.BILLING)
        }
        if (SystemUserTypes.ADMIN !== session_info.user_details.user_category) {
          _filtered[_index].children = _filtered[_index].children.filter(p => p.page !== UserTabChildPages.SETTINGS)
        }
        if (!_filtered[_index].children.length) {
          _filtered = _filtered.filter(p => p.page !== UserTabRootPages.ACCOUNT_SETTINGS)
        }
      }
    }

    {
      const _index = _filtered.findIndex(f => f.page === UserTabRootPages.REDEEM_VOUCHER)
      if (_index > -1) {
        if (!this.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.PAYMENT')) {
          _filtered.splice(_index, 1)
        }
      }
    }

    {
      const _index = _filtered.findIndex(f => f.page === UserTabRootPages.CREATE_QR)
      if (_index > -1) {
        if (
          !this.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.QRREADNWRITE') &&
          !this.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.QRFULLACCESS')
        ) {
          if (user_details.current_organization_details.current_team_details.role_id === SystemUserRoleTypes.OWNER) {
            _filtered[_index].upgrade_required = true
          } else {
            _filtered.splice(_index, 1)
          }
        }
      }
    }

    {
      const _index = _filtered.findIndex(f => f.page === UserTabRootPages.POPUPS)
      if (_index > -1) {
        if (!this.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.MANAGEPOPUPS')) {
          if (user_details.current_organization_details.current_team_details.role_id === SystemUserRoleTypes.OWNER) {
            _filtered[_index].upgrade_required = true
          } else {
            _filtered.splice(_index, 1)
          }
        }
      }
    }

    {
      const _index = _filtered.findIndex(f => f.page === UserTabRootPages.CREATE_POPUPS)
      if (_index > -1) {
        if (!this.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.MANAGEPOPUPS')) {
          if (user_details.current_organization_details.current_team_details.role_id !== SystemUserRoleTypes.OWNER) {
            _filtered.splice(_index, 1)
          }
        }
      }
    }

    {
      const _index = _filtered.findIndex(f => f.page === UserTabRootPages.QRS)
      if (_index > -1) {
        if (
          !this.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.QRREAD') &&
          !this.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.QRREADNWRITE') &&
          !this.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.QRFULLACCESS')
        ) {
          if (user_details.current_organization_details.current_team_details.role_id === SystemUserRoleTypes.OWNER) {
            _filtered[_index].upgrade_required = true
          } else {
            _filtered.splice(_index, 1)
          }
        }
      }
    }

    {
      const _index = _filtered.findIndex(f => f.page === UserTabRootPages.OFF_THE_SHELF_QR_CODES)
      if (_index > -1) {
        if (
          !this.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.QRREADNWRITE') &&
          !this.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.QRFULLACCESS')
        ) {
          if (user_details.current_organization_details.current_team_details.role_id === SystemUserRoleTypes.OWNER) {
            _filtered[_index].upgrade_required = true
          } else {
            _filtered.splice(_index, 1)
          }
        }
      }
    }

    {
      const _index = _filtered.findIndex(f => f.page === UserTabRootPages.OFF_THE_SHELF_QR_CODES_BATCH)
      if (_index > -1) {
        if (
          !this.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.VIEWOTSCUSTOMERS')
        ) {
          if (user_details.current_organization_details.current_team_details.role_id === SystemUserRoleTypes.OWNER) {
            _filtered[_index].upgrade_required = true
          } else {
            _filtered.splice(_index, 1)
          }
        }
      }
    }

    {
      const _index = _filtered.findIndex(f => f.page === UserTabRootPages.QRS_TAGS)
      if (_index > -1) {
        if (
          !this.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.QRTAGSREAD') &&
          !this.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.QRTAGSREADNWRITE') &&
          !this.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.QRTAGSFULLACCESS')
        ) {
          if (user_details.current_organization_details.current_team_details.role_id === SystemUserRoleTypes.OWNER) {
            _filtered[_index].upgrade_required = true
          } else {
            _filtered.splice(_index, 1)
          }
        }
      }
    }

    {
      const _index = _filtered.findIndex(f => f.page === UserTabRootPages.REFERRALS)
      if (_index > -1) {
        if (!this.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.MANAGEREFERRALS')) {
          _filtered.splice(_index, 1)
        }
      }
    }

    if (!_filtered.length) {
      this.functionService.showAlertMessage("Permission to all pages has been disabled, please contact your admin.")
      this.logMeOut()
      return null
    }

    if (!session_info.last_routes.tabs.users.root || !_filtered.find(f => f.page == session_info.last_routes.tabs.users.root)) {
      session_info.last_routes.tabs.users.root = _filtered[0].page
    }

    this.setSessionData(session_info, false)
    return {
      tabs: _filtered,
      page: session_info.last_routes.tabs.users.root
    }
  }

  allProductUseCases(): CoreProductUseCase[] {

    const common_seo_keywords_memories = [
      "event qr code",
      "photo sharing app",
      "online gallery qr code",
      "shared online gallery qr code",
    ]

    const common_seo_keywords_events = [
      "event qr code generator",
      "events qr code generator",
      "qr code generator for event",
      "qr code generator for events",
    ]

    const common_seo_keywords_restaurant_menu = [
      "product menu qr code",
    ]

    const common_seo_keywords_images = [
      "image gallery qr code",
    ]

    const common_seo_keywords_pdf = [
      "pdf qr code generator",
    ]

    const common_seo_keywords_vcardpro = [
      "digital business card qr code generator",
    ]

    const common_seo_keywords_pet = [
      "pet id qr code generator",
      "pet tag qr code generator",
      "pet identification qr code",
      "pet identification qr code generator",
    ]

    const common_seo_keywords_business_page = [
      "business page qr code",
      "qr code for business page",
    ]

    const common_seo_keywords_social_media = [
      "social media qr code",
      "qr code for social media",
    ]

    const common_seo_keywords_coupons = [
      "coupons qr code",
      "qr code for coupons",
      "coupon qr code",
      "qr code for coupon",
      "discount qr code",
      "qr code for discount",
      "qr code for promotion",
    ]

    const common_seo_keywords_feedbacks = [
      "feedbacks qr code",
      "qr code for feedbacks",
      "feedback qr code",
      "qr code for feedback",
    ]

    const common_seo_keywords_ratings = [
      "ratings qr code",
      "qr code for ratings",
      "rating qr code",
      "qr code for rating",
    ]

    const common_seo_keywords_videos = [
      "youtube video qr code",
      "qr code for youtube video",
      "youtube videos qr code",
      "qr code for youtube videos",
      "youtube video qr code generator",
      "qr code generator for youtube video",
      "youtube videos qr code generator",
      "qr code generator for youtube videos",
    ]

    const common_seo_keywords_vacancies = [
      "job vacancy qr code",
      "qr code for job vacancy",
      "job vacancy qr code generator",
      "qr code generator for job vacancy",
    ]

    const common_seo_keywords_apps = [
      "app download qr code",
      "qr code for app download",
      "app download qr code generator",
      "qr code generator for app download",
    ]

    const common_seo_keywords_mp3s = [
      "mp3 download qr code",
      "qr code for mp3 download",
      "mp3 download qr code generator",
      "qr code generator for mp3 download",
    ]

    const common_seo_keywords_long_plain_texts = [
      "plain text qr code",
      "qr code for plain text",
      "plain text qr code generator",
      "qr code generator for plain text",
    ]

    return [
      {
        seo_keywords: [
          ...common_seo_keywords_memories,
          "wedding qr code",
          "qr code for wedding",
          "wedding photo qr code",
          "qr code wedding photos",
          "wedding photos qr code",
          "wedding photos qr codes",
          "wedding memories qr code",
          "wedding qr code generator",
          "qr code for wedding photos",
          "wedding qr code for photos",
          "qr codes for wedding photos",
          "wedding photo share qr code",
          "qr code for wedding pictures",
          "wedding photo sharing qr code",
          "free qr code for wedding photos",
          "qr code to upload wedding photos",
          "wedding party qr code",
          "qr code for wedding party",
          "wedding party photo qr code",
          "qr code wedding party photos",
          "wedding party photos qr code",
          "wedding party photos qr codes",
          "wedding party memories qr code",
          "wedding party qr code generator",
          "qr code for wedding party photos",
          "wedding party qr code for photos",
          "qr codes for wedding party photos",
          "wedding party photo share qr code",
          "qr code for wedding party pictures",
          "wedding party photo sharing qr code",
          "free qr code for wedding party photos",
          "qr code to upload wedding party photos",
        ],
        title: "Weddings & Receptions",
        type: CoreProductUseCases.MEMORIES_WEDDING,
        perfect_for: QrGeneratorNavigatorCategories.MEMORIES,
        thumbnail: "/assets/products/wedding-ceremony-marry-wedding-married-brandqrcodes-com.png",
        description: "Use QR Code to collect captured moments from your special day and all the festivities.",
        url: `/products/${QrGeneratorNavigatorCategories.MEMORIES}/use-cases/${CoreProductUseCases.MEMORIES_WEDDING}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_memories,
          "bachelorette qr code",
          "qr codes for bachelorette",
          "bachelorette photo qr code",
          "qr code bachelorette photos",
          "bachelorette photos qr code",
          "bachelorette photos qr codes",
          "bachelorette memories qr code",
          "bachelorette qr code generator",
          "qr code for bachelorette photos",
          "bachelorette qr code for photos",
          "qr codes for bachelorette photos",
          "bachelorette photo share qr code",
          "qr code for bachelorette pictures",
          "bachelorette photo sharing qr code",
          "free qr code for bachelorette photos",
          "qr code to upload bachelorette photos",
          "bachelorette party qr code",
          "qr codes for bachelorette party",
          "bachelorette party photo qr code",
          "qr code bachelorette party photos",
          "bachelorette party photos qr code",
          "bachelorette party photos qr codes",
          "bachelorette party memories qr code",
          "bachelorette party qr code generator",
          "qr code for bachelorette party photos",
          "bachelorette party qr code for photos",
          "qr codes for bachelorette party photos",
          "bachelorette party photo share qr code",
          "qr code for bachelorette party pictures",
          "bachelorette party photo sharing qr code",
          "free qr code for bachelorette party photos",
          "qr code to upload bachelorette party photos",
          "bachelor qr code",
          "qr codes for bachelor",
          "bachelor photo qr code",
          "qr code bachelor photos",
          "bachelor photos qr code",
          "bachelor photos qr codes",
          "bachelor memories qr code",
          "bachelor qr code generator",
          "qr code for bachelor photos",
          "bachelor qr code for photos",
          "qr codes for bachelor photos",
          "bachelor photo share qr code",
          "qr code for bachelor pictures",
          "bachelor photo sharing qr code",
          "free qr code for bachelor photos",
          "qr code to upload bachelor photos",
          "bachelor party qr code",
          "qr code for bachelor party",
          "bachelor party photo qr code",
          "qr code bachelor party photos",
          "bachelor party photos qr code",
          "bachelor party photos qr codes",
          "bachelor party memories qr code",
          "bachelor party qr code generator",
          "qr code for bachelor party photos",
          "bachelor party qr code for photos",
          "qr codes for bachelor party photos",
          "bachelor party photo share qr code",
          "qr code for bachelor party pictures",
          "bachelor party photo sharing qr code",
          "free qr code for bachelor party photos",
          "qr code to upload bachelor party photos",
        ],
        title: "Bachelorette & Bachelor Parties",
        type: CoreProductUseCases.MEMORIES_BACHELORETTE,
        perfect_for: QrGeneratorNavigatorCategories.MEMORIES,
        thumbnail: "/assets/products/party-popper-brandqrcodes-com.png",
        description: "Use QR Code to collect captured moments from your pre-wedding celebrations with friends.",
        url: `/products/${QrGeneratorNavigatorCategories.MEMORIES}/use-cases/${CoreProductUseCases.MEMORIES_BACHELORETTE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_memories,
          "birthday qr code",
          "qr codes for birthday",
          "birthday photo qr code",
          "qr code birthday photos",
          "birthday photos qr code",
          "birthday photos qr codes",
          "birthday memories qr code",
          "birthday qr code generator",
          "qr code for birthday photos",
          "birthday qr code for photos",
          "qr codes for birthday photos",
          "birthday photo share qr code",
          "qr code for birthday pictures",
          "birthday photo sharing qr code",
          "free qr code for birthday photos",
          "qr code to upload birthday photos",
          "birthday party qr code",
          "qr codes for birthday party",
          "birthday party photo qr code",
          "qr code birthday party photos",
          "birthday party photos qr code",
          "birthday party photos qr codes",
          "birthday party memories qr code",
          "birthday party qr code generator",
          "qr code for birthday party photos",
          "birthday party qr code for photos",
          "qr codes for birthday party photos",
          "birthday party photo share qr code",
          "qr code for birthday party pictures",
          "birthday party photo sharing qr code",
          "free qr code for birthday party photos",
          "qr code to upload birthday party photos",
        ],
        title: "Birthday & Themed Parties",
        type: CoreProductUseCases.MEMORIES_BIRTHDAY,
        perfect_for: QrGeneratorNavigatorCategories.MEMORIES,
        thumbnail: "/assets/products/birthday-heart-invitation-brandqrcodes-com.png",
        description: "Use QR Code to collect captured moments from your birthdays, costume parties, or milestone parties.",
        url: `/products/${QrGeneratorNavigatorCategories.MEMORIES}/use-cases/${CoreProductUseCases.MEMORIES_BIRTHDAY}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_memories,
          "corporate event qr code",
          "qr code for corporate event",
          "corporate event photo qr code",
          "qr code corporate event photos",
          "corporate event photos qr code",
          "corporate event photos qr codes",
          "corporate event memories qr code",
          "corporate event qr code generator",
          "qr code for corporate event photos",
          "corporate event qr code for photos",
          "qr codes for corporate event photos",
          "corporate event photo share qr code",
          "qr code for corporate event pictures",
          "corporate event photo sharing qr code",
          "free qr code for corporate event photos",
          "qr code to upload corporate event photos",
        ],
        title: "Corporate Events & Conferences",
        type: CoreProductUseCases.MEMORIES_CORPORATE,
        perfect_for: QrGeneratorNavigatorCategories.MEMORIES,
        thumbnail: "/assets/products/business-finance-corporate-brandqrcodes-com.png",
        description: "Use QR Code to collect captured moments from your company retreats, workshops, & team-building exercises.",
        url: `/products/${QrGeneratorNavigatorCategories.MEMORIES}/use-cases/${CoreProductUseCases.MEMORIES_CORPORATE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_memories,
          "prom qr code",
          "qr code for prom",
          "prom photo qr code",
          "qr code prom photos",
          "prom photos qr code",
          "prom photos qr codes",
          "prom memories qr code",
          "prom qr code generator",
          "qr code for prom photos",
          "prom qr code for photos",
          "qr codes for prom photos",
          "prom photo share qr code",
          "qr code for prom pictures",
          "prom photo sharing qr code",
          "free qr code for prom photos",
          "qr code to upload prom photos",
          "prom party qr code",
          "qr code for prom party",
          "prom party photo qr code",
          "qr code prom party photos",
          "prom party photos qr code",
          "prom party photos qr codes",
          "prom party memories qr code",
          "prom party qr code generator",
          "qr code for prom party photos",
          "prom party qr code for photos",
          "qr codes for prom party photos",
          "prom party photo share qr code",
          "qr code for prom party pictures",
          "prom party photo sharing qr code",
          "free qr code for prom party photos",
          "qr code to upload prom party photos",
        ],
        title: "School Proms & Graduations",
        type: CoreProductUseCases.MEMORIES_PROMS,
        perfect_for: QrGeneratorNavigatorCategories.MEMORIES,
        thumbnail: "/assets/products/dress-with-ribbon-brandqrcodes-com.png",
        description: "Use QR Code to collect captured moments from your academic achievements and milestone dances.",
        url: `/products/${QrGeneratorNavigatorCategories.MEMORIES}/use-cases/${CoreProductUseCases.MEMORIES_PROMS}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_memories,
          "holiday qr code",
          "qr code for holiday",
          "holiday photo qr code",
          "qr code holiday photos",
          "holiday photos qr code",
          "holiday photos qr codes",
          "holiday memories qr code",
          "holiday photo sharing app",
          "holiday qr code generator",
          "qr code for holiday photos",
          "holiday qr code for photos",
          "qr codes for holiday photos",
          "holiday photo share qr code",
          "qr code for holiday pictures",
          "holiday photo sharing qr code",
          "free qr code for holiday photos",
          "qr code to upload holiday photos",
          "vacation qr code",
          "qr code for vacation",
          "vacation photo qr code",
          "qr code vacation photos",
          "vacation photos qr code",
          "vacation photos qr codes",
          "vacation memories qr code",
          "vacation photo sharing app",
          "vacation qr code generator",
          "qr code for vacation photos",
          "vacation qr code for photos",
          "qr codes for vacation photos",
          "vacation photo share qr code",
          "qr code for vacation pictures",
          "vacation photo sharing qr code",
          "free qr code for vacation photos",
          "qr code to upload vacation photos",
        ],
        title: "Holiday Photos & Adventures",
        type: CoreProductUseCases.MEMORIES_HOLIDAY,
        perfect_for: QrGeneratorNavigatorCategories.MEMORIES,
        thumbnail: "/assets/products/holiday-island-tourism-brandqrcodes-com.png",
        description: "Use QR Code to collect captured moments from your holidays and getaways with family and friends.",
        url: `/products/${QrGeneratorNavigatorCategories.MEMORIES}/use-cases/${CoreProductUseCases.MEMORIES_HOLIDAY}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_memories,
          "concert qr code",
          "qr code for concert",
          "concert photo qr code",
          "qr code concert photos",
          "concert photos qr code",
          "concert photos qr codes",
          "concert memories qr code",
          "concert photo sharing app",
          "concert qr code generator",
          "qr code for concert photos",
          "concert qr code for photos",
          "qr codes for concert photos",
          "concert photo share qr code",
          "qr code for concert pictures",
          "concert photo sharing qr code",
          "free qr code for concert photos",
          "qr code to upload concert photos"
        ],
        title: "Concerts & Performances",
        type: CoreProductUseCases.MEMORIES_CONCERT,
        perfect_for: QrGeneratorNavigatorCategories.MEMORIES,
        thumbnail: "/assets/products/concert-brandqrcodes-com.png",
        description: "Use QR Code to collect captured moments from your live show, play, or musical performance.",
        url: `/products/${QrGeneratorNavigatorCategories.MEMORIES}/use-cases/${CoreProductUseCases.MEMORIES_CONCERT}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_memories,
          "baby shower qr code",
          "qr code for baby shower",
          "baby shower photo qr code",
          "qr code baby shower photos",
          "baby shower photos qr code",
          "baby shower photos qr codes",
          "baby shower memories qr code",
          "baby shower photo sharing app",
          "baby shower qr code generator",
          "qr code for baby shower photos",
          "baby shower qr code for photos",
          "qr codes for baby shower photos",
          "baby shower photo share qr code",
          "qr code for baby shower pictures",
          "baby shower photo sharing qr code",
          "free qr code for baby shower photos",
          "qr code to upload baby shower photos",
          "gender reveal qr code",
          "qr code for gender reveal",
          "gender reveal photo qr code",
          "qr code gender reveal photos",
          "gender reveal photos qr code",
          "gender reveal photos qr codes",
          "gender reveal memories qr code",
          "gender reveal photo sharing app",
          "gender reveal qr code generator",
          "qr code for gender reveal photos",
          "gender reveal qr code for photos",
          "qr codes for gender reveal photos",
          "gender reveal photo share qr code",
          "qr code for gender reveal pictures",
          "gender reveal photo sharing qr code",
          "free qr code for gender reveal photos",
          "qr code to upload gender reveal photos",
        ],
        title: "Baby Showers & Gender Reveals",
        type: CoreProductUseCases.MEMORIES_BABY_SHOWER,
        perfect_for: QrGeneratorNavigatorCategories.MEMORIES,
        thumbnail: "/assets/products/pregnant-woman-parenthood-life-pregnant-brandqrcodes-com.png",
        description: "Use QR Code to collect captured moments from your baby showers and gender reveals.",
        url: `/products/${QrGeneratorNavigatorCategories.MEMORIES}/use-cases/${CoreProductUseCases.MEMORIES_BABY_SHOWER}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_memories,
          "christmas qr code",
          "qr code for christmas",
          "christmas photo qr code",
          "qr code christmas photos",
          "christmas photos qr code",
          "christmas photos qr codes",
          "christmas memories qr code",
          "christmas photo sharing app",
          "christmas qr code generator",
          "qr code for christmas photos",
          "christmas qr code for photos",
          "qr codes for christmas photos",
          "christmas photo share qr code",
          "qr code for christmas pictures",
          "christmas photo sharing qr code",
          "free qr code for christmas photos",
          "qr code to upload christmas photos",
          "christmas party qr code",
          "qr code for christmas party",
          "christmas party photo qr code",
          "qr code christmas party photos",
          "christmas party photos qr code",
          "christmas party photos qr codes",
          "christmas party memories qr code",
          "christmas party photo sharing app",
          "christmas party qr code generator",
          "qr code for christmas party photos",
          "christmas party qr code for photos",
          "qr codes for christmas party photos",
          "christmas party photo share qr code",
          "qr code for christmas party pictures",
          "christmas party photo sharing qr code",
          "free qr code for christmas party photos",
          "qr code to upload christmas party photos",
        ],
        title: "Christmas & Religious Gatherings",
        type: CoreProductUseCases.MEMORIES_CHRISTMAS,
        perfect_for: QrGeneratorNavigatorCategories.MEMORIES,
        thumbnail: "/assets/products/christmas-snowman-brandqrcodes-com.png",
        url: `/products/${QrGeneratorNavigatorCategories.MEMORIES}/use-cases/${CoreProductUseCases.MEMORIES_CHRISTMAS}`,
        description: "Use QR Code to collect captured moments from your Christmas party, Baptisms, Bar/Bat Mitzvahs, and other religious milestones.",
      },
      {
        seo_keywords: [
          ...common_seo_keywords_memories,
          "sport qr code",
          "qr code for sport",
          "sport photo qr code",
          "qr code sport photos",
          "sport photos qr code",
          "sport photos qr codes",
          "sport memories qr code",
          "sport photo sharing app",
          "sport qr code generator",
          "qr code for sport photos",
          "sport qr code for photos",
          "qr codes for sport photos",
          "sport photo share qr code",
          "qr code for sport pictures",
          "sport photo sharing qr code",
          "free qr code for sport photos",
          "qr code to upload sport photos",
        ],
        title: "Sports Tournaments & Award Ceremonies",
        type: CoreProductUseCases.MEMORIES_SPORT_CEREMONIES,
        perfect_for: QrGeneratorNavigatorCategories.MEMORIES,
        thumbnail: "/assets/products/sports-football-solid-brandqrcodes-com.png",
        description: "Use QR Code to collect captured moments from your sport events, big or small.",
        url: `/products/${QrGeneratorNavigatorCategories.MEMORIES}/use-cases/${CoreProductUseCases.MEMORIES_SPORT_CEREMONIES}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_memories,
          "thanksgiving qr code",
          "family reunion qr code",
          "qr code for thanksgiving",
          "qr code for family reunion",
          "family reunion photo qr code",
          "qr code family reunion photos",
          "family reunion photos qr code",
          "family reunion photos qr codes",
          "family reunion memories qr code",
          "family reunion photo sharing app",
          "family reunion qr code generator",
          "qr code for family reunion photos",
          "family reunion qr code for photos",
          "qr codes for family reunion photos",
          "family reunion photo share qr code",
          "qr code for family reunion pictures",
          "family reunion photo sharing qr code",
          "free qr code for family reunion photos",
          "qr code to upload family reunion photos",
          "anniversary qr code",
          "qr code for anniversary",
          "anniversary photo qr code",
          "qr code anniversary photos",
          "anniversary photos qr code",
          "anniversary photos qr codes",
          "anniversary memories qr code",
          "anniversary photo sharing app",
          "anniversary qr code generator",
          "qr code for anniversary photos",
          "anniversary qr code for photos",
          "qr codes for anniversary photos",
          "anniversary photo share qr code",
          "qr code for anniversary pictures",
          "anniversary photo sharing qr code",
          "free qr code for anniversary photos",
          "qr code to upload anniversary photos",
        ],
        title: "Family Reunions & Anniversaries",
        type: CoreProductUseCases.MEMORIES_FAMILY_REUNIONS,
        perfect_for: QrGeneratorNavigatorCategories.MEMORIES,
        thumbnail: "/assets/products/family-brandqrcodes-com.png",
        url: `/products/${QrGeneratorNavigatorCategories.MEMORIES}/use-cases/${CoreProductUseCases.MEMORIES_FAMILY_REUNIONS}`,
        description: "Use QR Code to collect captured moments from your time spent reconnecting with family and celebrating special anniversaries.",
      },
      {
        seo_keywords: [
          ...common_seo_keywords_memories,
          "charity event qr code",
          "qr code for charity event",
          "charity event photo qr code",
          "qr code charity event photos",
          "charity event photos qr code",
          "charity event photos qr codes",
          "charity event memories qr code",
          "charity event photo sharing app",
          "charity event qr code generator",
          "qr code for charity event photos",
          "charity event qr code for photos",
          "qr codes for charity event photos",
          "charity event photo share qr code",
          "qr code for charity event pictures",
          "charity event photo sharing qr code",
          "free qr code for charity event photos",
          "qr code to upload charity event photos"
        ],
        type: CoreProductUseCases.MEMORIES_CHARITY,
        title: "Charity Events & Volunteer Activities",
        perfect_for: QrGeneratorNavigatorCategories.MEMORIES,
        thumbnail: "/assets/products/charity-brandqrcodes-com.png",
        description: "Use QR Code to collect captured moments from your charity events.",
        url: `/products/${QrGeneratorNavigatorCategories.MEMORIES}/use-cases/${CoreProductUseCases.MEMORIES_CHARITY}`,
      },

      {
        seo_keywords: [
          ...common_seo_keywords_events,
          "corporate event qr code",
          "corporate event qr code generator",
          "qr code for corporate event",
          "qr code generator for corporate event",
        ],
        title: "Corporate Events & Conferences",
        type: CoreProductUseCases.EVENT_CORPORATE,
        perfect_for: QrGeneratorNavigatorCategories.EVENT,
        thumbnail: "/assets/products/business-finance-corporate-brandqrcodes-com.png",
        description: "Use QR Code to display details of your company retreats, workshops, & team-building exercises.",
        // url: `/products/${QrGeneratorNavigatorCategories.EVENT}/use-cases/${CoreProductUseCases.EVENT_CORPORATE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_events,
          "wedding receiption event qr code",
          "wedding receiption event qr code generator",
          "qr code for wedding receiption event",
          "qr code generator for wedding receiption event",
        ],
        title: "Weddings & Receptions",
        type: CoreProductUseCases.EVENT_WEDDING,
        perfect_for: QrGeneratorNavigatorCategories.EVENT,
        thumbnail: "/assets/products/wedding-ceremony-marry-wedding-married-brandqrcodes-com.png",
        description: "Use QR Code to display details of your special day and all the festivities.",
        // url: `/products/${QrGeneratorNavigatorCategories.EVENT}/use-cases/${CoreProductUseCases.EVENT_WEDDING}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_events,
          "bachelorette qr code",
          "bachelorette qr code generator",
          "qr code for bachelorette",
          "qr code generator for bachelorette",
        ],
        title: "Bachelorette & Bachelor Parties",
        type: CoreProductUseCases.EVENT_BACHELORETTE,
        perfect_for: QrGeneratorNavigatorCategories.EVENT,
        thumbnail: "/assets/products/party-popper-brandqrcodes-com.png",
        description: "Use QR Code to display details of your pre-wedding celebrations with friends.",
        // url: `/products/${QrGeneratorNavigatorCategories.EVENT}/use-cases/${CoreProductUseCases.EVENT_BACHELORETTE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_events,
          "birthday qr code",
          "birthday qr code generator",
          "qr code for birthday",
          "qr code generator for birthday",
        ],
        title: "Birthday & Themed Parties",
        type: CoreProductUseCases.EVENT_BIRTHDAY,
        perfect_for: QrGeneratorNavigatorCategories.EVENT,
        thumbnail: "/assets/products/birthday-heart-invitation-brandqrcodes-com.png",
        description: "Use QR Code to display details of your birthdays, costume parties, or milestone parties.",
        // url: `/products/${QrGeneratorNavigatorCategories.EVENT}/use-cases/${CoreProductUseCases.EVENT_BIRTHDAY}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_events,
          "concert qr code",
          "concert qr code generator",
          "qr code for concert",
          "qr code generator for concert",
        ],
        title: "Concerts & Performances",
        type: CoreProductUseCases.EVENT_CONCERT,
        perfect_for: QrGeneratorNavigatorCategories.EVENT,
        thumbnail: "/assets/products/concert-brandqrcodes-com.png",
        description: "Use QR Code to display details of your live show, play, or musical performance.",
        // url: `/products/${QrGeneratorNavigatorCategories.EVENT}/use-cases/${CoreProductUseCases.EVENT_CONCERT}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_events,
          "sporting event qr code",
          "sporting event qr code generator",
          "qr code for sporting event",
          "qr code generator for sporting event",
          "award ceremony event qr code",
          "award ceremony event qr code generator",
          "qr code for award ceremony event",
          "qr code generator for award ceremony event",
        ],
        title: "Sports Tournaments & Award Ceremonies",
        type: CoreProductUseCases.EVENT_SPORT_CEREMONIES,
        perfect_for: QrGeneratorNavigatorCategories.EVENT,
        thumbnail: "/assets/products/sports-football-solid-brandqrcodes-com.png",
        description: "Use QR Code to display details of your sport events, big or small.",
        // url: `/products/${QrGeneratorNavigatorCategories.EVENT}/use-cases/${CoreProductUseCases.EVENT_SPORT_CEREMONIES}`,
      },

      {
        seo_keywords: [
          ...common_seo_keywords_pdf,
          "pdf qr code",
          "qr code for pdf",
        ],
        title: "Restaurants & Fast Food",
        type: CoreProductUseCases.PDF_MENU,
        perfect_for: QrGeneratorNavigatorCategories.PDF,
        description: "Use QR Code to display set of pdf menu.",
        thumbnail: "/assets/products/restaurant-menu-brandqrcodes-com.png",
        // url: `/products/${QrGeneratorNavigatorCategories.PDF}/use-cases/${CoreProductUseCases.PDF_MENU}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pdf,
          "real estate pdf qr code",
          "qr code for real estate pdf",
          "real estate pdf qr code generator",
        ],
        title: "Real Estate & Open House Info",
        type: CoreProductUseCases.PDF_REAL_ESTATE,
        perfect_for: QrGeneratorNavigatorCategories.PDF,
        description: "Use QR Code to display set of pdf menu.",
        thumbnail: "/assets/products/real-estate-market-brandqrcodes-com.png",
        // url: `/products/${QrGeneratorNavigatorCategories.PDF}/use-cases/${CoreProductUseCases.PDF_REAL_ESTATE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pdf,
          "spa qr code",
          "qr code for spa",
          "spa qr code generator",
          "salon qr code",
          "qr code for salon",
          "salon qr code generator",
        ],
        title: "Spas & Salons",
        type: CoreProductUseCases.PDF_SPAS_SALONS,
        description: "Use QR Code to display treatment options.",
        perfect_for: QrGeneratorNavigatorCategories.PDF,
        thumbnail: "/assets/products/spa-brandqrcodes-com.png",
        // url: `/products/${QrGeneratorNavigatorCategories.PDF}/use-cases/${CoreProductUseCases.PDF_SPAS_SALONS}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pdf,
          "educational material qr code",
          "educational material qr code pdf",
          "qr code for educational material",
          "qr code for educational material pdf",
          "downloadable resource qr code",
          "qr code for downloadable resource",
          "downloadable resource qr code pdf",
          "qr code for downloadable resource pdf",
        ],
        title: "Educational Materials & Downloadable Resources",
        type: CoreProductUseCases.PDF_EDUCATION,
        perfect_for: QrGeneratorNavigatorCategories.PDF,
        thumbnail: "/assets/products/books-stack-brandqrcodes-com.png",
        description: "Use QR Code to share access to downloadable resource.",
        // url: `/products/${QrGeneratorNavigatorCategories.PDF}/use-cases/${CoreProductUseCases.PDF_EDUCATION}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pdf,
          "product manual qr code",
          "product manual qr code pdf",
          "qr code for product manual",
          "qr code for product manual pdf",
          "brochures qr code",
          "qr code for brochures",
          "brochures qr code pdf",
          "qr code for brochures pdf",
        ],
        title: "Product Manuals & Brochures",
        type: CoreProductUseCases.PDF_MANUFACTURING,
        perfect_for: QrGeneratorNavigatorCategories.PDF,
        description: "Use QR Code to share product manuals and brochures.",
        thumbnail: "/assets/products/instructions-manual-brandqrcodes-com.png",
        // url: `/products/${QrGeneratorNavigatorCategories.PDF}/use-cases/${CoreProductUseCases.PDF_MANUFACTURING}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pdf,
          "medical record qr code",
          "medical record qr code pdf",
          "qr code for medical record",
          "qr code for medical record pdf",
          "patient information qr code",
          "qr code for patient information",
          "patient information qr code pdf",
          "qr code for patient information pdf",
        ],
        type: CoreProductUseCases.PDF_HEALTHCARE,
        title: "Medical Records & Patient Information",
        perfect_for: QrGeneratorNavigatorCategories.PDF,
        description: "Use QR Code to share medical records and patient information.",
        thumbnail: "/assets/products/medical-record-medical-hospital-pharmacy-healthcare-brandqrcodes-com.png",
        // url: `/products/${QrGeneratorNavigatorCategories.PDF}/use-cases/${CoreProductUseCases.PDF_HEALTHCARE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pdf,
          "safety protocol document qr code",
          "safety protocol document qr code pdf",
          "qr code for safety protocol document",
          "qr code for safety protocol document pdf",
          "blueprints qr code",
          "qr code for blueprints",
          "blueprints qr code pdf",
          "qr code for blueprints pdf",
        ],
        type: CoreProductUseCases.PDF_CONSTRUCTION,
        perfect_for: QrGeneratorNavigatorCategories.PDF,
        title: "Blueprints, Safety Protocols & Worksite updates",
        description: "Use QR Code to share safety protocols and worksite updates.",
        thumbnail: "/assets/products/construction-man-brandqrcodes-com.png",
        // url: `/products/${QrGeneratorNavigatorCategories.PDF}/use-cases/${CoreProductUseCases.PDF_CONSTRUCTION}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_restaurant_menu,
          "hospitality qr code",
          "qr code for hospitality",
          "hospitality qr code generator",
          "tourism qr code",
          "qr code for tourism",
          "qr code for tourisms",
          "tourism qr code generator",
        ],
        title: "Hospitality & Tourism",
        type: CoreProductUseCases.PDF_HOSPITALITY,
        description: "Use QR Code to display downloadable travel guides or event schedules.",
        perfect_for: QrGeneratorNavigatorCategories.PDF,
        thumbnail: "/assets/products/hotel-room-bed-brandqrcodes-com.png",
        // url: `/products/${QrGeneratorNavigatorCategories.PDF}/use-cases/${CoreProductUseCases.PDF_HOSPITALITY}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_restaurant_menu,
          "marketing qr code",
          "qr code for marketing",
          "marketing qr code generator",
          "advertisement qr code",
          "qr code for advertisement",
          "qr code for advertisements",
          "advertisement qr code generator",
        ],
        title: "Marketing & Advertising",
        type: CoreProductUseCases.PDF_MARKETING,
        description: "Use QR Code to track document downloads and engagement.",
        perfect_for: QrGeneratorNavigatorCategories.PDF,
        thumbnail: "/assets/products/marketing-hand-give-bar-chart-statistic-brandqrcodes-com.png",
        // url: `/products/${QrGeneratorNavigatorCategories.PDF}/use-cases/${CoreProductUseCases.PDF_MARKETING}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_restaurant_menu,
          "government qr code",
          "qr code for government",
          "government qr code generator",
          "non profit qr code",
          "qr code for non profit",
          "qr code for non profits",
          "qr code generator for non profit",
          "non profit qr code generator",
        ],
        title: "Government & Non-Profit",
        type: CoreProductUseCases.PDF_GOVERNMENT,
        perfect_for: QrGeneratorNavigatorCategories.PDF,
        thumbnail: "/assets/products/kremlin-brandqrcodes-com.png",
        description: "Use QR Code to distribute Public Information and Educational Materials",
        // url: `/products/${QrGeneratorNavigatorCategories.PDF}/use-cases/${CoreProductUseCases.PDF_GOVERNMENT}`,
      },

      {
        seo_keywords: [
          ...common_seo_keywords_images,
          "photography qr code",
          "qr code for photography",
          "photography qr code generator",
        ],
        title: "Photography & Fashion",
        type: CoreProductUseCases.IMAGES,
        description: "Use QR Code to display set of photos.",
        perfect_for: QrGeneratorNavigatorCategories.IMAGES,
        thumbnail: "/assets/products/gallery-brandqrcodes-com.png",
        // url: `/products/${QrGeneratorNavigatorCategories.IMAGES}/use-cases/${CoreProductUseCases.IMAGES}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_images,
          "real estate qr code",
          "qr code for real estate",
          "real estate qr code generator",
        ],
        title: "Real Estate & Open House Info",
        type: CoreProductUseCases.IMAGES_REAL_ESTATE,
        description: "Use QR Code to display property details.",
        perfect_for: QrGeneratorNavigatorCategories.IMAGES,
        thumbnail: "/assets/products/real-estate-market-brandqrcodes-com.png",
        // url: `/products/${QrGeneratorNavigatorCategories.IMAGES}/use-cases/${CoreProductUseCases.IMAGES_REAL_ESTATE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_images,
          "spa qr code",
          "qr code for spa",
          "spa qr code generator",
          "salon qr code",
          "qr code for salon",
          "salon qr code generator",
        ],
        title: "Spas & Salons",
        type: CoreProductUseCases.IMAGES_SPAS_SALONS,
        description: "Use QR Code to display treatment options.",
        perfect_for: QrGeneratorNavigatorCategories.IMAGES,
        thumbnail: "/assets/products/spa-brandqrcodes-com.png",
        // url: `/products/${QrGeneratorNavigatorCategories.IMAGES}/use-cases/${CoreProductUseCases.IMAGES_SPAS_SALONS}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_images,
          "restaurant menu qr code",
          "qr code for restaurant menu",
          "restaurant menu qr code generator",
        ],
        title: "Restaurants & Fast Food",
        type: CoreProductUseCases.IMAGES_MENU,
        description: "Use QR Code to display your menu offerings.",
        perfect_for: QrGeneratorNavigatorCategories.IMAGES,
        thumbnail: "/assets/products/restaurant-menu-brandqrcodes-com.png",
        // url: `/products/${QrGeneratorNavigatorCategories.IMAGES}/use-cases/${CoreProductUseCases.IMAGES_MENU}`,
      },

      {
        seo_keywords: [
          ...common_seo_keywords_restaurant_menu,
          "restaurant menu qr code",
          "qr code for restaurant menu",
          "restaurant menu qr code generator",
        ],
        title: "Restaurants & Fast Food",
        type: CoreProductUseCases.RESTAURANT_MENU,
        description: "Use QR Code to display your menu offerings.",
        perfect_for: QrGeneratorNavigatorCategories.RESTAURANT_MENU,
        thumbnail: "/assets/products/restaurant-menu-brandqrcodes-com.png",
        url: `/products/${QrGeneratorNavigatorCategories.RESTAURANT_MENU}/use-cases/${CoreProductUseCases.RESTAURANT_MENU}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_restaurant_menu,
          "cafe qr code",
          "qr code for cafe",
          "cafe qr code generator",
          "bakery qr code",
          "qr code for bakery",
          "bakery qr code generator",
        ],
        title: "Cafes & Bakeries",
        type: CoreProductUseCases.RESTAURANT_MENU_CAFES,
        description: "Use QR Code to display your menu offerings.",
        perfect_for: QrGeneratorNavigatorCategories.RESTAURANT_MENU,
        thumbnail: "/assets/products/coffee-shop-brandqrcodes-com.png",
        // url: `/products/${QrGeneratorNavigatorCategories.RESTAURANT_MENU}/use-cases/${CoreProductUseCases.RESTAURANT_MENU_CAFES}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_restaurant_menu,
          "catering service qr code",
          "qr code for catering service",
          "catering service qr code generator",
          "event planning qr code",
          "qr code for event planning",
          "qr code for event planning services",
          "event planning qr code generator",
        ],
        title: "Catering Services & Event Planning",
        type: CoreProductUseCases.RESTAURANT_MENU_CATERING,
        description: "Use QR Code to display your menu offerings.",
        perfect_for: QrGeneratorNavigatorCategories.RESTAURANT_MENU,
        thumbnail: "/assets/products/catering-buffet-brandqrcodes-com.png",
        // url: `/products/${QrGeneratorNavigatorCategories.RESTAURANT_MENU}/use-cases/${CoreProductUseCases.RESTAURANT_MENU_CATERING}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_restaurant_menu,
          "hospitality qr code",
          "qr code for hospitality",
          "hospitality qr code generator",
          "hotel qr code",
          "qr code for hotel",
          "qr code for hotels",
          "hotel qr code generator",
        ],
        title: "Hospitality & Hotels",
        type: CoreProductUseCases.RESTAURANT_MENU_HOSPITALITY,
        description: "Use QR Code to display menu offerings.",
        perfect_for: QrGeneratorNavigatorCategories.RESTAURANT_MENU,
        thumbnail: "/assets/products/hotel-room-bed-brandqrcodes-com.png",
        // url: `/products/${QrGeneratorNavigatorCategories.RESTAURANT_MENU}/use-cases/${CoreProductUseCases.RESTAURANT_MENU_HOSPITALITY}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_restaurant_menu,
          "food truck qr code",
          "qr code for food truck",
          "food truck qr code generator",
        ],
        title: "Food trucks",
        type: CoreProductUseCases.RESTAURANT_MENU_FOOD_TRUCKS,
        description: "Use QR Code to display your menu offerings.",
        perfect_for: QrGeneratorNavigatorCategories.RESTAURANT_MENU,
        thumbnail: "/assets/products/food-truck-brandqrcodes-com.png",
        // url: `/products/${QrGeneratorNavigatorCategories.RESTAURANT_MENU}/use-cases/${CoreProductUseCases.RESTAURANT_MENU_FOOD_TRUCKS}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_restaurant_menu,
          "retail store qr code",
          "qr code for retail store",
          "retail store qr code generator",
        ],
        title: "Retail Stores",
        type: CoreProductUseCases.RESTAURANT_MENU_RETAIL_STORES,
        description: "Use QR Code to showcase product details.",
        perfect_for: QrGeneratorNavigatorCategories.RESTAURANT_MENU,
        thumbnail: "/assets/products/shop-shopping-store-brandqrcodes-com.png",
        // url: `/products/${QrGeneratorNavigatorCategories.RESTAURANT_MENU}/use-cases/${CoreProductUseCases.RESTAURANT_MENU_RETAIL_STORES}`,
      },

      {
        seo_keywords: [
          ...common_seo_keywords_vcardpro,
          "personal branding qr code",
          "qr code for personal branding",
        ],
        title: "Personal Branding",
        type: CoreProductUseCases.VCARD_PRO_PERSONAL_BRANDING,
        perfect_for: QrGeneratorNavigatorCategories.VCARD_PRO,
        thumbnail: "/assets/products/personal-brandqrcodes-com.png",
        description: "Use QR Code to showcase your online presence and expertise.",
        // url: `/products/${QrGeneratorNavigatorCategories.VCARD_PRO}/use-cases/${CoreProductUseCases.VCARD_PRO_PERSONAL_BRANDING}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_vcardpro,
          "company profile qr code",
          "qr code for company profile",
        ],
        title: "Company Profile",
        type: CoreProductUseCases.VCARD_PRO_COMPANY_PROFILE,
        perfect_for: QrGeneratorNavigatorCategories.VCARD_PRO,
        thumbnail: "/assets/products/id-card-brandqrcodes-com.png",
        description: "Use QR Code for employee's contact sharing within the organization.",
        url: `/products/${QrGeneratorNavigatorCategories.VCARD_PRO}/use-cases/${CoreProductUseCases.VCARD_PRO_COMPANY_PROFILE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_vcardpro,
          "company directory qr code",
          "employee directory qr code",
          "internal communication qr code",
          "streamline employee access qr code",
          "qr code for employee contact information",
        ],
        title: "Employee Directory",
        perfect_for: QrGeneratorNavigatorCategories.VCARD_PRO,
        type: CoreProductUseCases.VCARD_PRO_EMPLOYEE_DIRECTORY,
        thumbnail: "/assets/products/tree-2-brandqrcodes-com.png",
        description: "Simplify internal communication and access with a QR code linking directly to your employee directory for better collaboration.",
        // url: `/products/${QrGeneratorNavigatorCategories.VCARD_PRO}/use-cases/${CoreProductUseCases.VCARD_PRO_EMPLOYEE_DIRECTORY}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_vcardpro,
          "professional networking qr code",
          "qr code for professional networking",
        ],
        title: "Professional Networking",
        type: CoreProductUseCases.VCARD_PRO_NETWORKING,
        perfect_for: QrGeneratorNavigatorCategories.VCARD_PRO,
        thumbnail: "/assets/products/business-finance-corporate-brandqrcodes-com.png",
        description: "Use QR Code to share contact details effortlessly at conferences or events.",
        // url: `/products/${QrGeneratorNavigatorCategories.VCARD_PRO}/use-cases/${CoreProductUseCases.VCARD_PRO_NETWORKING}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_vcardpro,
          "client communication qr code",
          "qr code for client communication",
        ],
        title: "Client Communication",
        type: CoreProductUseCases.VCARD_PRO_CLIENT,
        perfect_for: QrGeneratorNavigatorCategories.VCARD_PRO,
        thumbnail: "/assets/products/communication-2-brandqrcodes-com.png",
        description: "Use QR Code to streamline client interaction with easy access to your information.",
        // url: `/products/${QrGeneratorNavigatorCategories.VCARD_PRO}/use-cases/${CoreProductUseCases.VCARD_PRO_CLIENT}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_vcardpro,
          "event qr code solutions",
          "trade show qr codes",
          "branded qr codes for events",
          "qr codes for trade show booths",
          "promotional qr codes for events",
        ],
        title: "Trade Shows & Events",
        type: CoreProductUseCases.VCARD_PRO_TRADE_SHOWS,
        perfect_for: QrGeneratorNavigatorCategories.VCARD_PRO,
        thumbnail: "/assets/products/service-desk-brandqrcodes-com.png",
        description: "Enhance your presence at events with branded QR codes on promotional materials for instant access to your company profile and effective follow-up.",
        // url: `/products/${QrGeneratorNavigatorCategories.VCARD_PRO}/use-cases/${CoreProductUseCases.VCARD_PRO_TRADE_SHOWS}`,
      },

      {
        seo_keywords: [
          ...common_seo_keywords_pet,
          "lost pet recovery qr code",
          "qr code for lost pet recovery",
        ],
        title: "Lost Pet Recovery & Emergency Situations",
        type: CoreProductUseCases.PET_MISSING_PET,
        perfect_for: QrGeneratorNavigatorCategories.PET,
        thumbnail: "/assets/products/emergency-phone-brandqrcodes-com.png",
        description: "Use QR Code to enable access to owner's contact information and return instructions.",
        // url: `/products/${QrGeneratorNavigatorCategories.PET}/use-cases/${CoreProductUseCases.PET_MISSING_PET}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pet,
          "livestock farming qr code",
          "qr code for livestock farming",
        ],
        title: "Farming Livestock Management",
        type: CoreProductUseCases.PET_FARMING,
        perfect_for: QrGeneratorNavigatorCategories.PET,
        thumbnail: "/assets/products/farming-livestock-animal-brandqrcodes-com.png",
        description: "Use QR Code to monitor health records, breeding histories, and track movements for better management.",
        // url: `/products/${QrGeneratorNavigatorCategories.PET}/use-cases/${CoreProductUseCases.PET_FARMING}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pet,
          "pet friendly event qr code",
          "qr code for pet friendly event",
        ],
        title: "Pet-Friendly Events",
        type: CoreProductUseCases.PET_EVENTS,
        perfect_for: QrGeneratorNavigatorCategories.PET,
        thumbnail: "/assets/products/pet-frontal-head-with-a-halter-brandqrcodes-com.png",
        description: "Use QR Code to share vaccination status and behavioral notes.",
        // url: `/products/${QrGeneratorNavigatorCategories.PET}/use-cases/${CoreProductUseCases.PET_EVENTS}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pet,
          "pet sitters qr code",
          "qr code for pet sitters",
          "pet walkers qr code",
          "qr code for pet walkers",
        ],
        title: "Pet Sitters & Pet Walkers",
        type: CoreProductUseCases.PET_SITTERS,
        perfect_for: QrGeneratorNavigatorCategories.PET,
        thumbnail: "/assets/products/pet-dog-brandqrcodes-com.png",
        description: "Use QR Code to share feeding schedules, exercise routines and behavioral notes.",
        // url: `/products/${QrGeneratorNavigatorCategories.PET}/use-cases/${CoreProductUseCases.PET_SITTERS}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pet,
          "pet grooming qr code",
          "qr code for pet grooming",
        ],
        title: "Pet Grooming Services",
        type: CoreProductUseCases.PET_GROOMING,
        perfect_for: QrGeneratorNavigatorCategories.PET,
        thumbnail: "/assets/products/pet-care-service-pet-grooming-service-brandqrcodes-com.png",
        description: "Use QR Code to share grooming preferences, past grooming records and behavioral notes.",
        // url: `/products/${QrGeneratorNavigatorCategories.PET}/use-cases/${CoreProductUseCases.PET_GROOMING}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pet,
          "pet boarding qr code",
          "qr code for pet boarding",
        ],
        title: "Pet Boarding",
        type: CoreProductUseCases.PET_BOARDING,
        perfect_for: QrGeneratorNavigatorCategories.PET,
        thumbnail: "/assets/products/pet-certificate-brandqrcodes-com.png",
        description: "Use QR Code to share dietary needs, emergency contact information and behavioral notes.",
        // url: `/products/${QrGeneratorNavigatorCategories.PET}/use-cases/${CoreProductUseCases.PET_BOARDING}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pet,
          "dog parks qr code",
          "qr code for dog parks",
        ],
        title: "Dog Parks",
        type: CoreProductUseCases.PET_PARKS,
        perfect_for: QrGeneratorNavigatorCategories.PET,
        thumbnail: "/assets/products/park-bench-brandqrcodes-com.png",
        description: "Use QR Code to share vaccinations status and behavioral notes.",
        // url: `/products/${QrGeneratorNavigatorCategories.PET}/use-cases/${CoreProductUseCases.PET_PARKS}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pet,
          "pet shops qr code",
          "qr code for pet shops",
        ],
        title: "Pet Shops",
        type: CoreProductUseCases.PET_SHOPS,
        perfect_for: QrGeneratorNavigatorCategories.PET,
        thumbnail: "/assets/products/pet-care-service-pet-grooming-service-brandqrcodes-com.png",
        description: "Use QR Code to share product preferences, dietary restrictions and behavioral notes.",
        // url: `/products/${QrGeneratorNavigatorCategories.PET}/use-cases/${CoreProductUseCases.PET_SHOPS}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pet,
          "medical information qr code",
          "qr code for medical information",
        ],
        title: "Medical Info & Veterinary Visits",
        type: CoreProductUseCases.PET_MEDICAL,
        perfect_for: QrGeneratorNavigatorCategories.PET,
        thumbnail: "/assets/products/pet-first-aid-brandqrcodes-com.png",
        description: "Use QR Code to share medical history, vaccination records and known allergies.",
        // url: `/products/${QrGeneratorNavigatorCategories.PET}/use-cases/${CoreProductUseCases.PET_MEDICAL}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pet,
          "pet transportation qr code",
          "qr code for pet transportation",
        ],
        title: "Travel & Transportation",
        type: CoreProductUseCases.PET_TRANSPORTATION,
        perfect_for: QrGeneratorNavigatorCategories.PET,
        thumbnail: "/assets/products/plane-brandqrcodes-com.png",
        description: "Use QR Code to share pet's health certifications and travel permissions.",
        // url: `/products/${QrGeneratorNavigatorCategories.PET}/use-cases/${CoreProductUseCases.PET_TRANSPORTATION}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pet,
          "pet competition qr code",
          "qr code for pet competition",
        ],
        title: "Pet Competitions & Shows",
        type: CoreProductUseCases.PET_COMPETITIONS,
        perfect_for: QrGeneratorNavigatorCategories.PET,
        thumbnail: "/assets/products/tv-alt-brandqrcodes-com.png",
        description: "Use QR Code to share detailed pet's information, including lineage and past achievements.",
        // url: `/products/${QrGeneratorNavigatorCategories.PET}/use-cases/${CoreProductUseCases.PET_COMPETITIONS}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pet,
          "pet insurance qr code",
          "qr code for pet insurance",
        ],
        title: "Pet Insurance Claims",
        type: CoreProductUseCases.PET_INSURANCE,
        perfect_for: QrGeneratorNavigatorCategories.PET,
        thumbnail: "/assets/products/pet-certificate-brandqrcodes-com.png",
        description: "Use QR Code to share medical records and treatments for verification.",
        // url: `/products/${QrGeneratorNavigatorCategories.PET}/use-cases/${CoreProductUseCases.PET_INSURANCE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pet,
          "pet adoption qr code",
          "qr code for pet adoption",
        ],
        title: "Pet Adoption Centers & Animal Shelters",
        type: CoreProductUseCases.PET_ADOPTION,
        perfect_for: QrGeneratorNavigatorCategories.PET,
        thumbnail: "/assets/products/pet-hotel-building-brandqrcodes-com.png",
        description: "Use QR Code to share adoption history, temperament, and any special needs or requirements.",
        // url: `/products/${QrGeneratorNavigatorCategories.PET}/use-cases/${CoreProductUseCases.PET_ADOPTION}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_pet,
          "pet inventory management qr code",
          "qr code for pet inventory management",
        ],
        title: "Inventory Management",
        type: CoreProductUseCases.PET_INVENTORY_MANAGEMENT,
        perfect_for: QrGeneratorNavigatorCategories.PET,
        thumbnail: "/assets/products/book-shelf-brandqrcodes-com.png",
        description: "Use QR Code to quickly access information about specific animals during inventory checks.",
        // url: `/products/${QrGeneratorNavigatorCategories.PET}/use-cases/${CoreProductUseCases.PET_INVENTORY_MANAGEMENT}`,
      },

      {
        seo_keywords: [
          ...common_seo_keywords_business_page,
          "business website qr code",
          "qr code for business website",
          "online store qr code",
          "qr code for online store",
        ],
        title: "Website URL & Online Store Link",
        type: CoreProductUseCases.BUSINESS_PAGE_WEBSITE,
        perfect_for: QrGeneratorNavigatorCategories.BUSINESS_PAGE,
        thumbnail: "/assets/products/website-connection-communication-brandqrcodes-com.png",
        description: "Use QR Code to share company’s official website for comprehensive information and services.",
        // url: `/products/${QrGeneratorNavigatorCategories.BUSINESS_PAGE}/use-cases/${CoreProductUseCases.BUSINESS_PAGE_WEBSITE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_business_page,
          "business contact information qr code",
          "qr code for business contact information",
        ],
        title: "Business Contact Information",
        type: CoreProductUseCases.BUSINESS_PAGE_CONTACT_INFO,
        perfect_for: QrGeneratorNavigatorCategories.BUSINESS_PAGE,
        thumbnail: "/assets/products/emergency-phone-brandqrcodes-com.png",
        description: "Use QR Code to share instant access to the business’s phone number, email, and physical address.",
        // url: `/products/${QrGeneratorNavigatorCategories.BUSINESS_PAGE}/use-cases/${CoreProductUseCases.BUSINESS_PAGE_CONTACT_INFO}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_business_page,
          "social media profiles qr code",
          "qr code for social media profiles",
        ],
        title: "Social Media Profiles",
        type: CoreProductUseCases.BUSINESS_PAGE_SOCIALS,
        perfect_for: QrGeneratorNavigatorCategories.BUSINESS_PAGE,
        thumbnail: "/assets/products/social-media-campaign-brandqrcodes-com.png",
        description: "Use QR Code to share business’s social media pages, encouraging customers to follow and engage.",
        // url: `/products/${QrGeneratorNavigatorCategories.BUSINESS_PAGE}/use-cases/${CoreProductUseCases.BUSINESS_PAGE_SOCIALS}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_business_page,
          "about us page qr code",
          "qr code for about us page",
          "blog page qr code",
          "qr code for blog page",
        ],
        title: "About Us & Blog Link",
        type: CoreProductUseCases.BUSINESS_PAGE_BLOG_LINK,
        perfect_for: QrGeneratorNavigatorCategories.BUSINESS_PAGE,
        thumbnail: "/assets/products/about-brandqrcodes-com.png",
        description: "Use QR Code to share brief overview of the business, its mission, and its values.",
        // url: `/products/${QrGeneratorNavigatorCategories.BUSINESS_PAGE}/use-cases/${CoreProductUseCases.BUSINESS_PAGE_BLOG_LINK}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_business_page,
          "product summary qr code",
          "qr code for product summary",
        ],
        title: "Product/Service Summary",
        type: CoreProductUseCases.BUSINESS_PAGE_SERVICE_SUMMARY,
        perfect_for: QrGeneratorNavigatorCategories.BUSINESS_PAGE,
        thumbnail: "/assets/products/service-desk-brandqrcodes-com.png",
        description: "Use QR Code to share brief overview of product offerings.",
        // url: `/products/${QrGeneratorNavigatorCategories.BUSINESS_PAGE}/use-cases/${CoreProductUseCases.BUSINESS_PAGE_SERVICE_SUMMARY}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_business_page,
          "business operating hours qr code",
          "qr code for business operating hours",
          "business on-site facilities qr code",
          "qr code for business on-site facilities",
        ],
        title: "Operating Hours & On-Site Facilities",
        type: CoreProductUseCases.BUSINESS_PAGE_OPERATING_HOURS,
        perfect_for: QrGeneratorNavigatorCategories.BUSINESS_PAGE,
        thumbnail: "/assets/products/opening-hours-brandqrcodes-com.png",
        description: "Use QR Code to share business’s opening and closing times for customer convenience.",
        // url: `/products/${QrGeneratorNavigatorCategories.BUSINESS_PAGE}/use-cases/${CoreProductUseCases.BUSINESS_PAGE_OPERATING_HOURS}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_business_page,
          "business google map location qr code",
          "qr code for business google map location",
        ],
        title: "Google Maps Location",
        type: CoreProductUseCases.BUSINESS_PAGE_GOOGLE_MAPS,
        perfect_for: QrGeneratorNavigatorCategories.BUSINESS_PAGE,
        thumbnail: "/assets/products/map-location-brandqrcodes-com.png",
        description: "Use QR Code to share directions and maps to the business location.",
        // url: `/products/${QrGeneratorNavigatorCategories.BUSINESS_PAGE}/use-cases/${CoreProductUseCases.BUSINESS_PAGE_GOOGLE_MAPS}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_business_page,
          "downloadable resources qr code",
          "qr code for downloadable resources",
          "video content qr code",
          "qr code for video content",
        ],
        title: "Video Content & Downloadable Resources",
        type: CoreProductUseCases.BUSINESS_PAGE_VIDEO_CONTENT,
        perfect_for: QrGeneratorNavigatorCategories.BUSINESS_PAGE,
        thumbnail: "/assets/products/product-downloadable-brandqrcodes-com.png",
        description: "Use QR Code to share promotional videos, tutorials, or other multimedia content.",
        // url: `/products/${QrGeneratorNavigatorCategories.BUSINESS_PAGE}/use-cases/${CoreProductUseCases.BUSINESS_PAGE_VIDEO_CONTENT}`,
      },

      {
        seo_keywords: [
          ...common_seo_keywords_social_media,
          "podcast links qr code",
          "qr code for podcast links",
        ],
        title: "Podcast Links",
        type: CoreProductUseCases.SOCIAL_MEDIA_PODCAST,
        perfect_for: QrGeneratorNavigatorCategories.SOCIAL_MEDIA,
        thumbnail: "/assets/products/podcast-brandqrcodes-com.png",
        description: "Use QR Code to share podcast episodes on platforms like Spotify, Apple Podcasts, or Google Podcasts.",
        // url: `/products/${QrGeneratorNavigatorCategories.SOCIAL_MEDIA}/use-cases/${CoreProductUseCases.SOCIAL_MEDIA_PODCAST}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_social_media,
          "social media contact information qr code",
          "qr code for social media contact information",
          "social media profile links qr code",
          "qr code for social media profile links",
        ],
        title: "Contact Information & Profile Links",
        type: CoreProductUseCases.SOCIAL_MEDIA_CONTACT_INFO,
        perfect_for: QrGeneratorNavigatorCategories.SOCIAL_MEDIA,
        thumbnail: "/assets/products/contact-chatting-communication-brandqrcodes-com.png",
        description: "Use QR Code to share your main profiles on platforms like Facebook, Instagram, Twitter, LinkedIn, and more.",
        // url: `/products/${QrGeneratorNavigatorCategories.SOCIAL_MEDIA}/use-cases/${CoreProductUseCases.SOCIAL_MEDIA_CONTACT_INFO}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_social_media,
          "video channels qr code",
          "qr code for video channels",
        ],
        title: "Video Channels",
        type: CoreProductUseCases.SOCIAL_MEDIA_VIDEO_CHANNELS,
        perfect_for: QrGeneratorNavigatorCategories.SOCIAL_MEDIA,
        thumbnail: "/assets/products/video-library-brandqrcodes-com.png",
        description: "Use QR Code to share your YouTube or Vimeo channels, showcasing your video content.",
        // url: `/products/${QrGeneratorNavigatorCategories.SOCIAL_MEDIA}/use-cases/${CoreProductUseCases.SOCIAL_MEDIA_VIDEO_CHANNELS}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_social_media,
          "link in bio qr code",
          "qr code for link in bio",
          "link consolidation qr code",
          "qr code for link consolidation",
          "bio link consolidation qr code",
          "qr code for bio link consolidation",
        ],
        title: "Link-In-Bio & Links Consolidation",
        type: CoreProductUseCases.SOCIAL_MEDIA_UNIFIED_SOCIAL_HUB,
        perfect_for: QrGeneratorNavigatorCategories.SOCIAL_MEDIA,
        thumbnail: "/assets/products/social-media-campaign-brandqrcodes-com.png",
        description: "Use QR Code to share all your important links in one place for easy access.",
        // url: `/products/${QrGeneratorNavigatorCategories.SOCIAL_MEDIA}/use-cases/${CoreProductUseCases.SOCIAL_MEDIA_UNIFIED_SOCIAL_HUB}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_social_media,
          "music playlists qr code",
          "qr code for music playlists",
        ],
        title: "Music & Playlists",
        type: CoreProductUseCases.SOCIAL_MEDIA_MUSIC,
        perfect_for: QrGeneratorNavigatorCategories.SOCIAL_MEDIA,
        thumbnail: "/assets/products/playlist-brandqrcodes-com.png",
        description: "Use QR Code to share your music or curated playlists on platforms like Spotify, Apple Music, or SoundCloud.",
        // url: `/products/${QrGeneratorNavigatorCategories.SOCIAL_MEDIA}/use-cases/${CoreProductUseCases.SOCIAL_MEDIA_MUSIC}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_social_media,
          "direct messaging qr code",
          "qr code for direct messaging",
          "dm qr code",
          "qr code for dm",
        ],
        title: "Direct Messaging",
        type: CoreProductUseCases.SOCIAL_MEDIA_DM,
        perfect_for: QrGeneratorNavigatorCategories.SOCIAL_MEDIA,
        thumbnail: "/assets/products/contact-chatting-communication-brandqrcodes-com.png",
        description: "Use QR Code to facilitates direct communication through links to your preferred messaging platforms.",
        // url: `/products/${QrGeneratorNavigatorCategories.SOCIAL_MEDIA}/use-cases/${CoreProductUseCases.SOCIAL_MEDIA_DM}`,
      },

      {
        seo_keywords: [
          ...common_seo_keywords_coupons,
          "exclusive discounts qr code",
          "qr code for exclusive discounts",
          "seasonal promotions qr code",
          "qr code for seasonal promotions",
          "black friday qr code",
          "qr code for black friday",
          "christmas sales qr code",
          "qr code for christmas sales",
        ],
        title: "Exclusive Discounts & Seasonal Promotions",
        type: CoreProductUseCases.COUPONS_EXCLUSIVE,
        perfect_for: QrGeneratorNavigatorCategories.COUPONS,
        thumbnail: "/assets/products/discount-label-brandqrcodes-com.png",
        description: "Use QR Code to provide access to special discounts.",
        // url: `/products/${QrGeneratorNavigatorCategories.COUPONS}/use-cases/${CoreProductUseCases.COUPONS_EXCLUSIVE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_coupons,
          "first time customer qr code",
          "qr code for first time customer",
          "loyalty reward qr code",
          "qr code for loyalty reward",
        ],
        title: "First-Time Customer Offer & Loyalty Rewards",
        type: CoreProductUseCases.COUPONS_LOYALTY,
        perfect_for: QrGeneratorNavigatorCategories.COUPONS,
        thumbnail: "/assets/products/award-cup-trophy-brandqrcodes-com.png",
        description: "Use QR Code to offer a special discount for first-time customers to encourage initial purchases.",
        // url: `/products/${QrGeneratorNavigatorCategories.COUPONS}/use-cases/${CoreProductUseCases.COUPONS_LOYALTY}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_coupons,
          "birthday discount qr code",
          "qr code for birthday discount",
        ],
        title: "Birthday Discounts",
        type: CoreProductUseCases.COUPONS_BIRTHDAY,
        perfect_for: QrGeneratorNavigatorCategories.COUPONS,
        thumbnail: "/assets/products/birthday-heart-invitation-brandqrcodes-com.png",
        description: "Use QR Code to offer a special discount to customers on their birthdays.",
        // url: `/products/${QrGeneratorNavigatorCategories.COUPONS}/use-cases/${CoreProductUseCases.COUPONS_BIRTHDAY}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_coupons,
          "flash sales qr code",
          "qr code for flash sales",
        ],
        title: "Flash Sales",
        type: CoreProductUseCases.COUPONS_FLASH,
        perfect_for: QrGeneratorNavigatorCategories.COUPONS,
        thumbnail: "/assets/products/discount-flash-sale-brandqrcodes-com.png",
        description: "Use QR Code to give access to time-sensitive flash sales and quick deals.",
        // url: `/products/${QrGeneratorNavigatorCategories.COUPONS}/use-cases/${CoreProductUseCases.COUPONS_FLASH}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_coupons,
          "bundle offers qr code",
          "qr code for bundle offers",
          "bulk purchase discounts qr code",
          "qr code for bulk purchase discounts",
        ],
        title: "Bundle Offers & Bulk Purchase Discounts",
        type: CoreProductUseCases.COUPONS_BUNDLE,
        perfect_for: QrGeneratorNavigatorCategories.COUPONS,
        thumbnail: "/assets/products/bundle-brandqrcodes-com.png",
        description: "Use QR Code to provide discounts on bundled products or services.",
        // url: `/products/${QrGeneratorNavigatorCategories.COUPONS}/use-cases/${CoreProductUseCases.COUPONS_BUNDLE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_coupons,
          "buy one get one free qr code",
          "qr code for buy one get one free",
        ],
        title: "Buy One, Get One (BOGO)",
        type: CoreProductUseCases.COUPONS_BOGO,
        perfect_for: QrGeneratorNavigatorCategories.COUPONS,
        thumbnail: "/assets/products/free-brandqrcodes-com.png",
        description: "Use QR Code to give access to buy one, get one free or buy one discount offers.",
        // url: `/products/${QrGeneratorNavigatorCategories.COUPONS}/use-cases/${CoreProductUseCases.COUPONS_BOGO}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_coupons,
          "newsletter signup discount qr code",
          "qr code for newsletter signup discount",
          "cart abandonment discount qr code",
          "qr code for cart abandonment discount",
        ],
        title: "Newsletter Signup & Cart Abandonment",
        type: CoreProductUseCases.COUPONS_CART,
        perfect_for: QrGeneratorNavigatorCategories.COUPONS,
        thumbnail: "/assets/products/newsletter-brandqrcodes-com.png",
        description: "Use QR Code to encourage customers to sign up for the newsletter to receive a discount code.",
        // url: `/products/${QrGeneratorNavigatorCategories.COUPONS}/use-cases/${CoreProductUseCases.COUPONS_CART}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_coupons,
          "in-store promotion qr code",
          "qr code for in-store promotion",
          "online store discount qr code",
          "qr code for online store discount",
        ],
        title: "In-Store Promotions & Online Store Discounts",
        type: CoreProductUseCases.COUPONS_IN_STORE,
        perfect_for: QrGeneratorNavigatorCategories.COUPONS,
        thumbnail: "/assets/products/store-dukan-brandqrcodes-com.png",
        description: "Use QR Code to offer in-store discounts for customers at checkout.",
        // url: `/products/${QrGeneratorNavigatorCategories.COUPONS}/use-cases/${CoreProductUseCases.COUPONS_IN_STORE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_coupons,
          "new product launch qr code",
          "qr code for new product launch",
          "clearance sale discount qr code",
          "qr code for clearance sale discount",
        ],
        title: "New Product Launches & Clearance Sales",
        type: CoreProductUseCases.COUPONS_NEW_PRODUCT,
        perfect_for: QrGeneratorNavigatorCategories.COUPONS,
        thumbnail: "/assets/products/new-products-label-brandqrcodes-com.png",
        description: "Use QR Code to offer discounts on newly launched products.",
        // url: `/products/${QrGeneratorNavigatorCategories.COUPONS}/use-cases/${CoreProductUseCases.COUPONS_NEW_PRODUCT}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_coupons,
          "student discount qr code",
          "qr code for student discount",
        ],
        title: "Student Discounts",
        type: CoreProductUseCases.COUPONS_STUDENT,
        perfect_for: QrGeneratorNavigatorCategories.COUPONS,
        thumbnail: "/assets/products/student-card-brandqrcodes-com.png",
        description: "Use QR Code to offer special discounts to students who verify their student status.",
        // url: `/products/${QrGeneratorNavigatorCategories.COUPONS}/use-cases/${CoreProductUseCases.COUPONS_STUDENT}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_coupons,
          "healthcare worker discounts qr code",
          "qr code for healthcare worker discounts",
          "military discounts qr code",
          "qr code for military discounts",
        ],
        title: "Healthcare Worker Discounts & Military Discounts",
        type: CoreProductUseCases.COUPONS_MILITARY,
        perfect_for: QrGeneratorNavigatorCategories.COUPONS,
        thumbnail: "/assets/products/healthcare-medical-stethoscope-brandqrcodes-com.png",
        description: "Use QR Code to offer discounts to healthcare workers and first responders.",
        // url: `/products/${QrGeneratorNavigatorCategories.COUPONS}/use-cases/${CoreProductUseCases.COUPONS_MILITARY}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_coupons,
          "early bird qr code",
          "qr code for early bird",
          "early access offer qr code",
          "qr code for early access offer",
        ],
        title: "Early Birds & Early Access Offers",
        type: CoreProductUseCases.COUPONS_EARLY,
        perfect_for: QrGeneratorNavigatorCategories.COUPONS,
        thumbnail: "/assets/products/bird-brandqrcodes-com.png",
        description: "Use QR Code to give early access to sales and discounts before they are available to the general public.",
        // url: `/products/${QrGeneratorNavigatorCategories.COUPONS}/use-cases/${CoreProductUseCases.COUPONS_EARLY}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_coupons,
          "affiliate discount qr code",
          "qr code for affiliate discount",
        ],
        title: "Affiliate & Partner Discounts",
        type: CoreProductUseCases.COUPONS_PARTNER,
        perfect_for: QrGeneratorNavigatorCategories.COUPONS,
        thumbnail: "/assets/products/affilitate-brandqrcodes-com.png",
        description: "Use QR Code to provide discounts through partner and affiliate collaborations.",
        // url: `/products/${QrGeneratorNavigatorCategories.COUPONS}/use-cases/${CoreProductUseCases.COUPONS_PARTNER}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_coupons,
          "product review qr code",
          "qr code for product review",
        ],
        title: "Product Review Incentives",
        type: CoreProductUseCases.COUPONS_PRODUCT,
        perfect_for: QrGeneratorNavigatorCategories.COUPONS,
        thumbnail: "/assets/products/review-like-message-brandqrcodes-com.png",
        description: "Use QR Code to provide discounts for leaving a product review.",
        // url: `/products/${QrGeneratorNavigatorCategories.COUPONS}/use-cases/${CoreProductUseCases.COUPONS_PRODUCT}`,
      },

      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "customer feedback qr code",
          "qr code for customer feedback",
        ],
        title: "Customer Satisfaction Surveys",
        type: CoreProductUseCases.FEEDBACK_CUSTOMER,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/review-like-message-brandqrcodes-com.png",
        description: "Use QR Code to provide detailed feedback on their experience.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_CUSTOMER}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "product feedback qr code",
          "qr code for product feedback",
        ],
        title: "Product Feedback",
        type: CoreProductUseCases.FEEDBACK_PRODUCT_REVIEW,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/feedback-brandqrcodes-com.png",
        description: "Use QR Code to allow customers to provide feedback to specific products or services.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_PRODUCT_REVIEW}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "service quality feedback qr code",
          "qr code for service quality feedback",
        ],
        title: "Service Quality Feedback",
        type: CoreProductUseCases.FEEDBACK_SERVICES,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/feedback-2-brandqrcodes-com.png",
        description: "Use QR Code to allow customers to provide feedback to the quality of service received and leave comments.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_SERVICES}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "rental service quality feedback qr code",
          "qr code for rental service quality feedback",
        ],
        title: "Rental Service Feedback",
        type: CoreProductUseCases.FEEDBACK_RENTAL,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/rental-brandqrcodes-com.png",
        description: "Use QR Code to allow customers to provide feedback on their experience with rental services, such as cars or equipment.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_RENTAL}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "pet service quality feedback qr code",
          "qr code for pet service quality feedback",
        ],
        title: "Pet Service Feedback",
        type: CoreProductUseCases.FEEDBACK_PET,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/pet-care-service-pet-grooming-service-brandqrcodes-com.png",
        description: "Use QR Code to allow customers to provide feedback on their experience with pet services, such as pet grooming, boarding, and veterinary services.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_PET}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "automotive service quality feedback qr code",
          "qr code for automotive service quality feedback",
        ],
        title: "Automotive Service Feedback",
        type: CoreProductUseCases.FEEDBACK_AUTOMOTIVE,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/car-svgrepo-com.png",
        description: "Use QR Code to allow customers to provide feedback on their experience with automotive services.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_AUTOMOTIVE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "spa service quality feedback qr code",
          "qr code for spa service quality feedback",
        ],
        title: "Spa & Salon Feedback",
        type: CoreProductUseCases.FEEDBACK_SPA,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/spa-brandqrcodes-com.png",
        description: "Use QR Code to allow customers to provide feedback on their experience with spa services.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_SPA}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "delivery service quality feedback qr code",
          "qr code for delivery service quality feedback",
        ],
        title: "Delivery Service Feedback",
        type: CoreProductUseCases.FEEDBACK_DELIVERY_SERVICES,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/delivery-brandqrcodes-com.png",
        description: "Use QR Code to collect feedback on delivery speed, service quality, and packaging.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_DELIVERY_SERVICES}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "post purchase feedback qr code",
          "qr code for post purchase feedback",
        ],
        title: "Post-Purchase & Retail Experience Feedback",
        type: CoreProductUseCases.FEEDBACK_POST_PURCHASE,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/discount-flash-sale-brandqrcodes-com.png",
        description: "Use QR Code to collect feedback after a purchase to understand their buying experience.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_POST_PURCHASE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "event feedback qr code",
          "qr code for event feedback",
        ],
        title: "Event Feedback",
        type: CoreProductUseCases.FEEDBACK_EVENT,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/party-popper-brandqrcodes-com.png",
        description: "Use QR Code to collect feedback about a recent event or workshop.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_EVENT}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "restaurant dining experience feedback qr code",
          "qr code for restaurant dining experience feedback",
        ],
        title: "Restaurant Dining Experience Feedback",
        type: CoreProductUseCases.FEEDBACK_RESTAURANT,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/restaurant-menu-brandqrcodes-com.png",
        description: "Use QR Code to collect feedback on diners' dining experience and provide suggestions.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_RESTAURANT}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "hotel stay feedback qr code",
          "qr code for hotel stay feedback",
        ],
        title: "Hotel Stay Feedback",
        type: CoreProductUseCases.FEEDBACK_HOTEL,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/hotel-room-bed-brandqrcodes-com.png",
        description: "Use QR Code to collect feedback from guests about their stay, including room quality and service.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_HOTEL}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "healthcare feedback qr code",
          "qr code for healthcare feedback",
        ],
        title: "Healthcare Services Feedback",
        type: CoreProductUseCases.FEEDBACK_HEALTHCARE,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/healthcare-medical-stethoscope-brandqrcodes-com.png",
        description: "Use QR Code to collect feedback from patients about their healthcare experience and suggest improvements.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_HEALTHCARE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "course training feedback qr code",
          "qr code for course training feedback",
        ],
        title: "Course & Training Evaluation",
        type: CoreProductUseCases.FEEDBACK_COURSE,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/books-stack-brandqrcodes-com.png",
        description: "Use QR Code to collect feedback from participants on educational courses or training sessions.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_COURSE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "workshop feedback qr code",
          "qr code for workshop feedback",
          "seminar feedback qr code",
          "qr code for seminar feedback",
        ],
        title: "Workshop & Seminar Evaluation",
        type: CoreProductUseCases.FEEDBACK_WORKSHOP,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/business-finance-corporate-brandqrcodes-com.png",
        description: "Use QR Code to collect feedback from attendees of workshops or seminars.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_WORKSHOP}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "customer support feedback qr code",
          "qr code for customer support feedback",
        ],
        title: "Customer Support Feedback",
        type: CoreProductUseCases.FEEDBACK_SUPPORT,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/contact-chatting-communication-brandqrcodes-com.png",
        description: "Use QR Code to gather feedbacks and comments about the customer support experience.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_SUPPORT}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "website usability feedback qr code",
          "qr code for website usability feedback",
        ],
        title: "Website Usability",
        type: CoreProductUseCases.FEEDBACK_WEBSITE,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/website-connection-communication-brandqrcodes-com.png",
        description: "Use QR Code to allow users to provide feedback on website usability and design.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_WEBSITE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "app performance feedback qr code",
          "qr code for app performance feedback",
        ],
        title: "App Performance",
        type: CoreProductUseCases.FEEDBACK_APP,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/mobile-app-phone-smartphone-brandqrcodes-com.png",
        description: "Use QR Code to allow users to provide feedback on mobile app performance and features.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_APP}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "new product launch feedback qr code",
          "qr code for new product launch feedback",
        ],
        title: "New Product Launch Feedback",
        type: CoreProductUseCases.FEEDBACK_NEW_PRODUCT,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/new-products-label-brandqrcodes-com.png",
        description: "Use QR Code to gather feedbacks on newly launched products.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_NEW_PRODUCT}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "public transport feedback qr code",
          "qr code for public transport feedback",
        ],
        title: "Public Transport Feedback",
        type: CoreProductUseCases.FEEDBACK_TRANSPORT,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/plane-brandqrcodes-com.png",
        description: "Use QR Code to collect feedbacks and suggestions for improving public transportation services.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_TRANSPORT}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "gym feedback qr code",
          "qr code for gym feedback",
          "fitness center feedback qr code",
          "qr code for fitness center feedback",
        ],
        title: "Gym & Fitness Center Feedback",
        type: CoreProductUseCases.FEEDBACK_GYM,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/gym-brandqrcodes-com.png",
        description: "Use QR Code to collect feedbacks and suggestions for improving classes and equipments.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_GYM}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_feedbacks,
          "membership experience feedback qr code",
          "qr code for membership experience feedback",
        ],
        title: "Membership Experience Feedback",
        type: CoreProductUseCases.FEEDBACK_MEMBERSHIP,
        perfect_for: QrGeneratorNavigatorCategories.FEEDBACK,
        thumbnail: "/assets/products/student-card-brandqrcodes-com.png",
        description: "Use QR Code to allow members of clubs or organizations to provide feedback on their membership experience.",
        // url: `/products/${QrGeneratorNavigatorCategories.FEEDBACK}/use-cases/${CoreProductUseCases.FEEDBACK_MEMBERSHIP}`,
      },


      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "customer rating qr code",
          "qr code for customer rating",
        ],
        title: "Customer Satisfaction Surveys",
        type: CoreProductUseCases.RATING_CUSTOMER,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/review-like-message-brandqrcodes-com.png",
        description: "Use QR Code to provide detailed rating on their experience.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_CUSTOMER}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "product rating qr code",
          "qr code for product rating",
        ],
        title: "Product Rating",
        type: CoreProductUseCases.RATING_PRODUCT_REVIEW,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/feedback-brandqrcodes-com.png",
        description: "Use QR Code to allow customers to rate specific products or services.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_PRODUCT_REVIEW}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "service quality rating qr code",
          "qr code for service quality rating",
        ],
        title: "Service Quality Rating",
        type: CoreProductUseCases.RATING_SERVICES,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/feedback-2-brandqrcodes-com.png",
        description: "Use QR Code to allow customers to rate the quality of service received and leave comments.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_SERVICES}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "rental service quality rating qr code",
          "qr code for rental service quality rating",
        ],
        title: "Rental Service Rating",
        type: CoreProductUseCases.RATING_RENTAL,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/rental-brandqrcodes-com.png",
        description: "Use QR Code to allow customers to provide rating of their experience with rental services, such as cars or equipment.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_RENTAL}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "pet service quality rating qr code",
          "qr code for pet service quality rating",
        ],
        title: "Pet Service Rating",
        type: CoreProductUseCases.RATING_PET,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/pet-care-service-pet-grooming-service-brandqrcodes-com.png",
        description: "Use QR Code to allow customers to rate their experience with pet services, such as pet grooming, boarding, and veterinary services.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_PET}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "automotive service quality rating qr code",
          "qr code for automotive service quality rating",
        ],
        title: "Automotive Service Rating",
        type: CoreProductUseCases.RATING_AUTOMOTIVE,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/car-svgrepo-com.png",
        description: "Use QR Code to collect ratings on the quality of automotive repair and maintenance services.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_AUTOMOTIVE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "spa service quality rating qr code",
          "qr code for spa service quality rating",
        ],
        title: "Spa & Salon Rating",
        type: CoreProductUseCases.RATING_SPA,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/spa-brandqrcodes-com.png",
        description: "Use QR Code to allow customers to provide rating on their experience with spa services.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_SPA}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "delivery service quality rating qr code",
          "qr code for delivery service quality rating",
        ],
        title: "Delivery Service Rating",
        type: CoreProductUseCases.RATING_DELIVERY_SERVICES,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/delivery-brandqrcodes-com.png",
        description: "Use QR Code to rate their experience with the delivery service.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_DELIVERY_SERVICES}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "post purchase rating qr code",
          "qr code for post purchase rating",
        ],
        title: "Post-Purchase & Retail Experience Rating",
        type: CoreProductUseCases.RATING_POST_PURCHASE,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/discount-flash-sale-brandqrcodes-com.png",
        description: "Use QR Code to collect rate their buying experience.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_POST_PURCHASE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "event rating qr code",
          "qr code for event rating",
        ],
        title: "Event Rating",
        type: CoreProductUseCases.RATING_EVENT,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/party-popper-brandqrcodes-com.png",
        description: "Use QR Code to collect allow attendees to rate a recent event or workshop.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_EVENT}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "restaurant dining experience rating qr code",
          "qr code for restaurant dining experience rating",
        ],
        title: "Restaurant Dining Experience Rating",
        type: CoreProductUseCases.RATING_RESTAURANT,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/restaurant-menu-brandqrcodes-com.png",
        description: "Use QR Code to allow diners rate their experience and provide suggestions.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_RESTAURANT}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "hotel stay rating qr code",
          "qr code for hotel stay rating",
        ],
        title: "Hotel Stay Rating",
        type: CoreProductUseCases.RATING_HOTEL,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/hotel-room-bed-brandqrcodes-com.png",
        description: "Use QR Code to allow guests rate their stay, including room quality and service.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_HOTEL}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "healthcare rating qr code",
          "qr code for healthcare rating",
        ],
        title: "Healthcare Services Rating",
        type: CoreProductUseCases.RATING_HEALTHCARE,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/healthcare-medical-stethoscope-brandqrcodes-com.png",
        description: "Use QR Code to allow patients rate their healthcare experience and suggest improvements.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_HEALTHCARE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "course training rating qr code",
          "qr code for course training rating",
        ],
        title: "Course & Training Evaluation",
        type: CoreProductUseCases.RATING_COURSE,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/books-stack-brandqrcodes-com.png",
        description: "Use QR Code to allow participants rate educational courses or training sessions.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_COURSE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "workshop rating qr code",
          "qr code for workshop rating",
          "seminar rating qr code",
          "qr code for seminar rating",
        ],
        title: "Workshop & Seminar Evaluation",
        type: CoreProductUseCases.RATING_WORKSHOP,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/business-finance-corporate-brandqrcodes-com.png",
        description: "Use QR Code to collect rating from attendees of workshops or seminars.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_WORKSHOP}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "customer support rating qr code",
          "qr code for customer support rating",
        ],
        title: "Customer Support Rating",
        type: CoreProductUseCases.RATING_SUPPORT,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/contact-chatting-communication-brandqrcodes-com.png",
        description: "Use QR Code to gather ratings and comments about the customer support experience.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_SUPPORT}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "website usability rating qr code",
          "qr code for website usability rating",
        ],
        title: "Website Usability",
        type: CoreProductUseCases.RATING_WEBSITE,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/website-connection-communication-brandqrcodes-com.png",
        description: "Use QR Code to allow users to rate your website usability and design.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_WEBSITE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "app performance rating qr code",
          "qr code for app performance rating",
        ],
        title: "App Performance",
        type: CoreProductUseCases.RATING_APP,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/mobile-app-phone-smartphone-brandqrcodes-com.png",
        description: "Use QR Code to allow users to rate your mobile app performance and features.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_APP}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "new product launch rating qr code",
          "qr code for new product launch rating",
        ],
        title: "New Product Launch Rating",
        type: CoreProductUseCases.RATING_NEW_PRODUCT,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/new-products-label-brandqrcodes-com.png",
        description: "Use QR Code to gather ratings on newly launched products.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_NEW_PRODUCT}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "public transport rating qr code",
          "qr code for public transport rating",
        ],
        title: "Public Transport Rating",
        type: CoreProductUseCases.RATING_TRANSPORT,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/plane-brandqrcodes-com.png",
        description: "Use QR Code to collect ratings and suggestions for improving public transportation services.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_TRANSPORT}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "gym rating qr code",
          "qr code for gym rating",
          "fitness center rating qr code",
          "qr code for fitness center rating",
        ],
        title: "Gym & Fitness Center Rating",
        type: CoreProductUseCases.RATING_GYM,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/gym-brandqrcodes-com.png",
        description: "Use QR Code to collect ratings and suggestions for improving classes and equipments.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_GYM}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_ratings,
          "membership experience rating qr code",
          "qr code for membership experience rating",
        ],
        title: "Membership Experience Rating",
        type: CoreProductUseCases.RATING_MEMBERSHIP,
        perfect_for: QrGeneratorNavigatorCategories.RATING,
        thumbnail: "/assets/products/student-card-brandqrcodes-com.png",
        description: "Use QR Code to allow members of clubs or organizations rate their membership experience.",
        // url: `/products/${QrGeneratorNavigatorCategories.RATING}/use-cases/${CoreProductUseCases.RATING_MEMBERSHIP}`,
      },

      {
        seo_keywords: [
          ...common_seo_keywords_videos,
          "product demonstration videos qr code",
          "qr code for product demonstration videos",
        ],
        title: "Product Demonstrations Videos",
        type: CoreProductUseCases.VIDEOS_PRODUCT_DEMONSTRATION,
        perfect_for: QrGeneratorNavigatorCategories.VIDEOS,
        thumbnail: "/assets/products/playlist-brandqrcodes-com.png",
        description: "Use QR Code to curate a gallery of videos showcasing product features, benefits, and how-to guides to help customers understand and use products effectively.",
        // url: `/products/${QrGeneratorNavigatorCategories.VIDEOS}/use-cases/${CoreProductUseCases.VIDEOS_PRODUCT_DEMONSTRATION}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_videos,
          "product demonstration videos qr code",
          "qr code for product demonstration videos",
        ],
        title: "Customer Testimonials",
        type: CoreProductUseCases.VIDEOS_CUSTOMER_TESTIMONIALS,
        perfect_for: QrGeneratorNavigatorCategories.VIDEOS,
        thumbnail: "/assets/products/review-like-message-brandqrcodes-com.png",
        description: "Use QR Code to display video testimonials from satisfied customers, allowing potential customers to see real-life experiences and reviews.",
        // url: `/products/${QrGeneratorNavigatorCategories.VIDEOS}/use-cases/${CoreProductUseCases.VIDEOS_CUSTOMER_TESTIMONIALS}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_videos,
          "event highlight videos qr code",
          "qr code for event highlight videos",
        ],
        title: "Event Highlights",
        type: CoreProductUseCases.VIDEOS_EVENT_HIGHLIGHT,
        perfect_for: QrGeneratorNavigatorCategories.VIDEOS,
        thumbnail: "/assets/products/wedding-ceremony-marry-wedding-married-brandqrcodes-com.png",
        description: "Use QR Code to showcase compiled highlights from past events, conferences, or trade shows, providing an engaging overview of the events and encouraging participation in future ones.",
        // url: `/products/${QrGeneratorNavigatorCategories.VIDEOS}/use-cases/${CoreProductUseCases.VIDEOS_EVENT_HIGHLIGHT}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_videos,
          "education series qr code",
          "qr code for education series",
        ],
        title: "Educational Series",
        type: CoreProductUseCases.VIDEOS_EDUCATIONAL_SERIES,
        perfect_for: QrGeneratorNavigatorCategories.VIDEOS,
        thumbnail: "/assets/products/books-stack-brandqrcodes-com.png",
        description: "Use QR Code to showcase series of educational videos, tutorials, or webinars on a specific topic, offering valuable knowledge and insights to the audience.",
        // url: `/products/${QrGeneratorNavigatorCategories.VIDEOS}/use-cases/${CoreProductUseCases.VIDEOS_EDUCATIONAL_SERIES}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_videos,
          "brand videos qr code",
          "qr code for brand videos",
        ],
        title: "Brand Stories and Documentaries",
        type: CoreProductUseCases.VIDEOS_BRAND_STORIES,
        perfect_for: QrGeneratorNavigatorCategories.VIDEOS,
        thumbnail: "/assets/products/video-library-brandqrcodes-com.png",
        description: "Use QR Code to showcase videos that tell the story of the brand, its history, mission, and impact, helping to build a deeper connection with the audience.",
        // url: `/products/${QrGeneratorNavigatorCategories.VIDEOS}/use-cases/${CoreProductUseCases.VIDEOS_BRAND_STORIES}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_videos,
          "entertainment videos qr code",
          "qr code for entertainment videos",
          "promotional videos qr code",
          "qr code for promotional videos",
        ],
        title: "Entertainment and Promotional Content",
        type: CoreProductUseCases.VIDEOS_ENTERTAINMENT,
        perfect_for: QrGeneratorNavigatorCategories.VIDEOS,
        thumbnail: "/assets/products/cards-entertainment-funeral-brandqrcodes-com.png",
        description: "Use QR Code to showcase gallery of entertaining or promotional videos, such as commercials, music videos, or behind-the-scenes footage, to engage and entertain the audience while promoting products or services.",
        // url: `/products/${QrGeneratorNavigatorCategories.VIDEOS}/use-cases/${CoreProductUseCases.VIDEOS_ENTERTAINMENT}`,
      },

      {
        seo_keywords: [
          ...common_seo_keywords_vacancies,
          "walk-in interview job listing qr code",
          "qr code for walk-in interview job listing",
          "recruitment event qr code",
          "qr code for recruitment event",
        ],
        title: "Walk-In Interviews & Recruitment Events",
        type: CoreProductUseCases.VACANCY_WALK_IN,
        perfect_for: QrGeneratorNavigatorCategories.VACANCY,
        thumbnail: "/assets/products/walk-brandqrcodes-com.png",
        description: "Use QR Code to advertise upcoming recruitment events, job fairs, or walk-in interview opportunities, providing details about the event and how to register or participate, with links to pre-application forms or event registration pages.",
        // url: `/products/${QrGeneratorNavigatorCategories.VACANCY}/use-cases/${CoreProductUseCases.VACANCY_WALK_IN}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_vacancies,
          "job listing qr code",
          "qr code for job listing",
        ],
        title: "Comprehensive Job Listings",
        type: CoreProductUseCases.VACANCY_COMPREHENSIVE,
        perfect_for: QrGeneratorNavigatorCategories.VACANCY,
        thumbnail: "/assets/products/job-search-brandqrcodes-com.png",
        description: "Use QR Code to display job descriptions, qualifications, benefits, and company information, making it easy for them to understand the role and apply directly from the page.",
        // url: `/products/${QrGeneratorNavigatorCategories.VACANCY}/use-cases/${CoreProductUseCases.VACANCY_COMPREHENSIVE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_vacancies,
          "department job listing qr code",
          "qr code for department job listing",
        ],
        title: "Department-Specific Openings",
        type: CoreProductUseCases.VACANCY_DEPARTMENT,
        perfect_for: QrGeneratorNavigatorCategories.VACANCY,
        thumbnail: "/assets/products/department-brandqrcodes-com.png",
        description: "Use QR Code to display job openings by department, allowing candidates to quickly find and apply for positions that match their skills and interests, with direct links to the application forms.",
        // url: `/products/${QrGeneratorNavigatorCategories.VACANCY}/use-cases/${CoreProductUseCases.VACANCY_DEPARTMENT}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_vacancies,
          "internship job listing qr code",
          "qr code for internship job listing",
        ],
        title: "Internship & Entry-Level Positions",
        type: CoreProductUseCases.VACANCY_INTERNSHIP,
        perfect_for: QrGeneratorNavigatorCategories.VACANCY,
        thumbnail: "/assets/products/student-reading-a-book-drinking-tea-brandqrcodes-com.png",
        description: "Use QR Code to display internship and entry-level job opportunities, including information about training programs, mentorship, and career growth, with an option to apply directly or be redirected to an application portal.",
        // url: `/products/${QrGeneratorNavigatorCategories.VACANCY}/use-cases/${CoreProductUseCases.VACANCY_INTERNSHIP}`,
      },

      {
        seo_keywords: [
          ...common_seo_keywords_apps,
          "cross platform app download qr code",
          "qr code for cross platform app download",
        ],
        title: "Cross-Platform App Downloads",
        type: CoreProductUseCases.APP_CROSS_PLATFORM,
        perfect_for: QrGeneratorNavigatorCategories.APP,
        thumbnail: "/assets/products/mobile-app-phone-smartphone-brandqrcodes-com.png",
        description: "Use QR Code to allow users download the app on various platforms such as iOS (App Store), Android (Google Play), and other app stores, ensuring easy access regardless of the user’s device.",
        // url: `/products/${QrGeneratorNavigatorCategories.APP}/use-cases/${CoreProductUseCases.APP_CROSS_PLATFORM}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_apps,
          "app launch download qr code",
          "qr code for app launch download",
        ],
        title: "App Launch Promotion",
        type: CoreProductUseCases.APP_LAUNCH_PROMOTION,
        perfect_for: QrGeneratorNavigatorCategories.APP,
        thumbnail: "/assets/products/launch-brandqrcodes-com.png",
        description: "Use QR Code to lead users to a promotional page for newly launched apps, featuring download links, app features, screenshots, and user reviews to attract and inform potential users.",
        // url: `/products/${QrGeneratorNavigatorCategories.APP}/use-cases/${CoreProductUseCases.APP_LAUNCH_PROMOTION}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_apps,
          "exclusive app access qr code",
          "qr code for exclusive app access",
        ],
        title: "Exclusive Content Access",
        type: CoreProductUseCases.APP_EXCLUSIVE_ACCESS,
        perfect_for: QrGeneratorNavigatorCategories.APP,
        thumbnail: "/assets/products/reserved-brandqrcodes-com.png",
        description: "Use QR Code to lead users to an exclusive in-app content, bonuses, or premium features upon downloading the app, encouraging more downloads and user engagement.",
        // url: `/products/${QrGeneratorNavigatorCategories.APP}/use-cases/${CoreProductUseCases.APP_EXCLUSIVE_ACCESS}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_apps,
          "exclusive app access qr code",
          "qr code for exclusive app access",
        ],
        title: "Beta Testing & Feedback",
        type: CoreProductUseCases.APP_BETA_TESTING,
        perfect_for: QrGeneratorNavigatorCategories.APP,
        thumbnail: "/assets/products/google-webview-devtools-beta-brandqrcodes-com.png",
        description: "Use QR Code to direct users to a page for beta testing the app, including download links for test versions on various platforms, as well as forms or links for providing feedback and reporting bugs.",
        // url: `/products/${QrGeneratorNavigatorCategories.APP}/use-cases/${CoreProductUseCases.APP_BETA_TESTING}`,
      },

      {
        seo_keywords: [
          ...common_seo_keywords_mp3s,
          "exclusive mp3 access qr code",
          "qr code for exclusive mp3 access",
          "exclusive song access qr code",
          "qr code for exclusive song access",
        ],
        title: "Exclusive Content and Bonuses",
        type: CoreProductUseCases.MP3_EXCLUSIVE_CONTENT,
        perfect_for: QrGeneratorNavigatorCategories.MP3,
        thumbnail: "/assets/products/reserved-brandqrcodes-com.png",
        description: "Use QR Code to grant access to exclusive tracks, live recordings, or bonus content not available elsewhere, along with links to download or stream the music from preferred platforms.",
        // url: `/products/${QrGeneratorNavigatorCategories.MP3}/use-cases/${CoreProductUseCases.MP3_EXCLUSIVE_CONTENT}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_mp3s,
          "album release qr code",
          "qr code for album release",
        ],
        title: "Album Releases",
        type: CoreProductUseCases.MP3_ALBUM_RELEASES,
        perfect_for: QrGeneratorNavigatorCategories.MP3,
        thumbnail: "/assets/products/album-open-brandqrcodes-com.png",
        description: "Use QR Code to showcase a full album, including download links for all tracks in MP3 format and streaming options on popular music platforms, ensuring easy access for fans.",
        // url: `/products/${QrGeneratorNavigatorCategories.MP3}/use-cases/${CoreProductUseCases.MP3_ALBUM_RELEASES}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_mp3s,
          "single track access qr code",
          "qr code for single track access",
        ],
        title: "Single Track Downloads",
        type: CoreProductUseCases.MP3_SINGLE_TRACK_DOWNLOAD,
        perfect_for: QrGeneratorNavigatorCategories.MP3,
        thumbnail: "/assets/products/mp3-file-type-brandqrcodes-com.png",
        description: "Use QR Code to showcase individual MP3 tracks, with options to listen or download the track from various music platforms such as Spotify, Apple Music, and Amazon Music.",
        // url: `/products/${QrGeneratorNavigatorCategories.MP3}/use-cases/${CoreProductUseCases.MP3_SINGLE_TRACK_DOWNLOAD}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_mp3s,
          "pre-release song qr code",
          "qr code for pre-release song",
        ],
        title: "Pre-Release Access",
        type: CoreProductUseCases.MP3_PRE_RELEASE,
        perfect_for: QrGeneratorNavigatorCategories.MP3,
        thumbnail: "/assets/products/announcement-brandqrcodes-com.png",
        description: "Use QR Code to grant early access to new releases or pre-release tracks, allowing fans to download or stream the music before it becomes widely available, and including links to pre-save or pre-order the music on major platforms.",
        // url: `/products/${QrGeneratorNavigatorCategories.MP3}/use-cases/${CoreProductUseCases.MP3_PRE_RELEASE}`,
      },

      {
        seo_keywords: [
          ...common_seo_keywords_long_plain_texts,
          "articles qr code",
          "qr code for articles",
          "essay qr code",
          "qr code for essay",
        ],
        title: "Detailed Articles & Essays",
        type: CoreProductUseCases.LONG_PLAIN_TEXT_DETAILED_ARTICLE,
        perfect_for: QrGeneratorNavigatorCategories.LONG_PLAIN_TEXT,
        thumbnail: "/assets/products/article-brandqrcodes-com.png",
        description: "Use QR Code showcase a web page featuring in-depth articles, research papers, or essays, making it easy to share extensive written content with a broader audience.",
        // url: `/products/${QrGeneratorNavigatorCategories.LONG_PLAIN_TEXT}/use-cases/${CoreProductUseCases.LONG_PLAIN_TEXT_DETAILED_ARTICLE}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_long_plain_texts,
          "instruction manual qr code",
          "qr code for instruction manual",
          "product manual qr code",
          "qr code for product manual",
          "guides qr code",
          "qr code for guides",
        ],
        title: "Instruction Manuals & Guides",
        type: CoreProductUseCases.LONG_PLAIN_TEXT_MANUAL,
        perfect_for: QrGeneratorNavigatorCategories.LONG_PLAIN_TEXT,
        thumbnail: "/assets/products/instructions-instructions-manual-brandqrcodes-com.png",
        description: "Use QR Code showcase comprehensive instruction manuals, user guides, or how-to documents, ensuring users have quick and easy access to detailed information.",
        // url: `/products/${QrGeneratorNavigatorCategories.LONG_PLAIN_TEXT}/use-cases/${CoreProductUseCases.LONG_PLAIN_TEXT_MANUAL}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_long_plain_texts,
          "event agenda qr code",
          "qr code for event agenda",
          "event schedule qr code",
          "qr code for event schedule",
        ],
        title: "Event Agendas & Schedules",
        type: CoreProductUseCases.LONG_PLAIN_TEXT_EVENT_AGENDA,
        perfect_for: QrGeneratorNavigatorCategories.LONG_PLAIN_TEXT,
        thumbnail: "/assets/products/agenda-book-business-brandqrcodes-com.png",
        description: "Use QR Code showcase detailed event agendas, schedules, and descriptions, allowing attendees to view and follow along with all the event details conveniently.",
        // url: `/products/${QrGeneratorNavigatorCategories.LONG_PLAIN_TEXT}/use-cases/${CoreProductUseCases.LONG_PLAIN_TEXT_EVENT_AGENDA}`,
      },
      {
        seo_keywords: [
          ...common_seo_keywords_long_plain_texts,
          "company policy qr code",
          "qr code for company policy",
          "terms and conditions qr code",
          "qr code for terms and conditions",
          "company policy qr code generator",
          "qr code generator for company policy",
          "terms and conditions qr code generator",
          "qr code generator for terms and conditions",
        ],
        title: "Company Policies & Terms",
        type: CoreProductUseCases.LONG_PLAIN_TEXT_POLICIES,
        perfect_for: QrGeneratorNavigatorCategories.LONG_PLAIN_TEXT,
        thumbnail: "/assets/products/health-insurance-finance-brandqrcodes-com.png",
        description: "Use QR Code showcase long-form company policies, terms and conditions, or privacy statements, making it simple for customers or employees to access important legal and procedural information.",
        // url: `/products/${QrGeneratorNavigatorCategories.LONG_PLAIN_TEXT}/use-cases/${CoreProductUseCases.LONG_PLAIN_TEXT_POLICIES}`,
      },

    ]
  }

  findRequiredAction(type: OrganizationRequiredActions = OrganizationRequiredActions.PLAN_SUBSCRIPTION_RENEWAL_FAILED): OrganizationInterfaceRequiredActions {
    return this.session_data.user_details.current_organization_details.required_actions.find(ra => ra.type === type)
  }

  async submitQRResponses(short_id: string, param: any): Promise<boolean> {
    const { data } = await firstValueFrom(this.http.post<{ data: boolean; }>(`${this.config.apiEndpoints.qrs.respond}${short_id}`, param));
    return data;
  }

  async notifyQRResponses(short_id: string): Promise<boolean> {
    const { data } = await firstValueFrom(this.http.put<{ data: boolean; }>(`${this.config.apiEndpoints.qrs.respond}${short_id}`, {}));
    return data;
  }

  async getQRAssets(short_id: string, params: any): Promise<Array<QrAssetResponse>> {
    const response = await firstValueFrom(this.http.get<{ data: Array<QrAssetResponse>; }>(`${this.config.apiEndpoints.qrs.respond}${short_id}`, { params }));
    return response.data;
  }

  onRate($event: { oldValue: number, newValue: number, starRating: StarRatingComponent }): number {
    return $event.newValue
  }

  gotoAccountSettings(profile: UserTabChildPages = UserTabChildPages.BILLING) {
    this.session_data.last_routes.tabs.users.profile = profile
    this.setSessionData(this.session_data)
    this.router.navigateByUrl("user/account-settings")
  }

  supportTickets() {
    return [
      {
        id: 0,
        description: "I would like to inquire about enterprise plan"
      },
      {
        id: 1,
        description: "I want to book a demo"
      },
      {
        id: 2,
        description: "Payment issue"
      },
      {
        id: 3,
        description: "Report a bug"
      },
      {
        id: 4,
        description: "Report abuse"
      },
      {
        id: 5,
        description: "Account deletion"
      },
      {
        id: 6,
        description: "Others"
      },
    ]
  }

  encrypt(message: string): string {
    return CryptoJS.AES.encrypt(message, environment.encryption_key).toString();
  }

  decrypt(message: string): string {
    return CryptoJS.AES.decrypt(message, environment.encryption_key).toString(CryptoJS.enc.Utf8);
  }

  getRequest(url: string) {
    return this.http.get(url).toPromise();
  }

  notifyBackEnd(mode: LandingPageLoggerDataModes, data: any) {
    return lastValueFrom(this.http.post(`${this.config.apiEndpoints.resources.log}${mode}`, data, { headers: { "skip-message": "true", "skip": "true" } }))
  }

}
