export enum CoreSeoPages {
  FAQS = "faqs",
  HOME = "home",
  BLOG = "blog",
  TERMS = "terms",
  PRICING = "pricing",
  REFERRAL = "referral",
  SIGN_IN = "auth/signin",
  SIGN_UP = "auth/signup",
  POPUP_LANDING = "popup",
  PROMOTIONS = "promotions",
  REDEMPTION_LANDING = "redemption",
  PRIVACY_POLICY = "privacy-policy",
  API_DOCUMENTATION = "api-documentation",
}
