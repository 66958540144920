import moment from 'moment';
import { createReducer, on } from '@ngrx/store';
import { AdminState } from '../states/admin.states';
import * as AdminActions from '../actions/admin.actions';
import { StateStatuses } from '../enums/state-statuses.enum';
import { InvoiceStatus, ReferralRecordTypes, SortDirections, Statuses, SystemUserRoleTypes, SystemUserTypes } from 'shared-library';

export const adminKey = 'admin';

let initialState: AdminState = {
  data: {
    qrs: {
      error: "",
      status: StateStatuses.PENDING,
      params: {
        page: 1,
        qr_id: "",
        limit: 100,
        search: "",
        status: null,
        category: null,
        organization_id: "",
        sort: SortDirections.DESC
      },
      response: null
    },
    users: {
      error: "",
      status: StateStatuses.PENDING,
      params: {
        page: 1,
        limit: 100,
        search: "",
        sort: SortDirections.DESC,
      },
      response: null
    },
    admins: {
      error: "",
      status: StateStatuses.PENDING,
      params: {
        page: 1,
        limit: 100,
        search: "",
        sort: SortDirections.DESC,
      },
      new: {
        name: "",
        email: "",
        roles: [],
        password: "",
        mobile_no: "",
        department: "",
        status: Statuses.ACTIVE,
        password_requires_reset: true,
        user_category: SystemUserTypes.ADMIN,
        user_account_role: SystemUserRoleTypes.OWNER,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      response: null
    },
    invoices: {
      error: "",
      status: StateStatuses.PENDING,
      params: {
        page: 1,
        limit: 100,
        search: "",
        invoice_number: "",
        organization_id: "",
        sort: SortDirections.DESC,
        status: InvoiceStatus.PAID,
        year: moment().get('year'),
      },
      response: null
    },
    referrals: {
      error: "",
      status: StateStatuses.PENDING,
      params: {
        page: 1,
        limit: 100,
        search: "",
        sort: SortDirections.DESC,
        year: moment().get('year'),
        record_type: ReferralRecordTypes.CASH_OUT_REQUEST,
      },
      response: null
    },
    checkout_sessions: {
      error: "",
      status: StateStatuses.PENDING,
      params: {
        page: 1,
        limit: 100,
        search: "",
        status: Statuses.ACTIVE,
        sort: SortDirections.DESC,
      },
      response: null
    },
    resources: {
      roles: [],
      prices: [],
      qr_logos: [],
      currencies: [],
      organizations: [],
    }
  }
};

const initialStateCloned = { ...initialState }
const savedState = localStorage.getItem(adminKey) || sessionStorage.getItem(adminKey)
if (savedState) {
  initialState = JSON.parse(savedState)
}

export const adminReducer = createReducer(
  initialState,
  on(AdminActions.fetchQRs, (state, { params, status }) => ({
    ...state,
    data: {
      ...state.data,
      qrs: {
        ...state.data.qrs,
        params,
        error: "",
        status
      }
    }
  })),
  on(AdminActions.fetchQRsSuccess, (state, { response }) => ({
    ...state,
    data: {
      ...state.data,
      qrs: {
        ...state.data.qrs,
        error: "",
        status: StateStatuses.SUCCESS,
        response
      }
    }
  })),
  on(AdminActions.fetchQRsFailure, (state, { error }) => {
    return {
      ...state,
      data: {
        ...state.data,
        qrs: {
          ...state.data.qrs,
          error: error.error,
          status: StateStatuses.FAILED,
          response: null
        }
      }
    }
  }),

  on(AdminActions.fetchInvoices, (state, { params, status }) => ({
    ...state,
    data: {
      ...state.data,
      invoices: {
        ...state.data.invoices,
        status,
        params,
        error: "",
      }
    }
  })),
  on(AdminActions.fetchInvoicesSuccess, (state, { response }) => ({
    ...state,
    data: {
      ...state.data,
      invoices: {
        ...state.data.invoices,
        error: "",
        status: StateStatuses.SUCCESS,
        response
      }
    }
  })),
  on(AdminActions.fetchInvoicesFailure, (state, { error }) => {
    return {
      ...state,
      data: {
        ...state.data,
        invoices: {
          ...state.data.invoices,
          error: error.error,
          status: StateStatuses.FAILED
        }
      }
    }
  }),

  on(AdminActions.fetchReferrals, (state, { params, status }) => ({
    ...state,
    data: {
      ...state.data,
      referrals: {
        ...state.data.referrals,
        status,
        params,
        error: "",
      }
    }
  })),
  on(AdminActions.fetchReferralsSuccess, (state, { response }) => ({
    ...state,
    data: {
      ...state.data,
      referrals: {
        ...state.data.referrals,
        error: "",
        status: StateStatuses.SUCCESS,
        response
      }
    }
  })),
  on(AdminActions.fetchReferralsFailure, (state, { error }) => {
    return {
      ...state,
      data: {
        ...state.data,
        referrals: {
          ...state.data.referrals,
          error: error.error,
          status: StateStatuses.FAILED
        }
      }
    }
  }),

  on(AdminActions.replaceCheckoutSession, (state, { data }) => ({
    ...state,
    data: {
      ...state.data,
      checkout_sessions: {
        ...state.data.checkout_sessions,
        response: {
          ...state.data.checkout_sessions.response,
          data: state.data.checkout_sessions.response.data.map(dd => dd.sortKey === data.sortKey ? data : dd)
        }
      }
    }
  })),
  on(AdminActions.fetchCheckoutSessions, (state, { params, status }) => ({
    ...state,
    data: {
      ...state.data,
      checkout_sessions: {
        ...state.data.checkout_sessions,
        status,
        params,
        error: "",
      }
    }
  })),
  on(AdminActions.fetchCheckoutSessionsSuccess, (state, { response }) => ({
    ...state,
    data: {
      ...state.data,
      checkout_sessions: {
        ...state.data.checkout_sessions,
        error: "",
        status: StateStatuses.SUCCESS,
        response
      }
    }
  })),
  on(AdminActions.fetchCheckoutSessionsFailure, (state, { error }) => {
    return {
      ...state,
      data: {
        ...state.data,
        checkout_sessions: {
          ...state.data.checkout_sessions,
          error: error.error,
          status: StateStatuses.FAILED
        }
      }
    }
  }),

  on(AdminActions.fetchUsers, (state, { params, status }) => ({
    ...state,
    data: {
      ...state.data,
      users: {
        ...state.data.users,
        status,
        params,
        error: "",
      }
    }
  })),
  on(AdminActions.fetchUsersSuccess, (state, { response }) => ({
    ...state,
    data: {
      ...state.data,
      users: {
        ...state.data.users,
        error: "",
        status: StateStatuses.SUCCESS,
        response
      }
    }
  })),
  on(AdminActions.fetchUsersFailure, (state, { error }) => {
    return {
      ...state,
      data: {
        ...state.data,
        users: {
          ...state.data.users,
          error: error.error,
          status: StateStatuses.FAILED
        }
      }
    }
  }),

  on(AdminActions.fetchAdmins, (state, { params, status }) => ({
    ...state,
    data: {
      ...state.data,
      admins: {
        ...state.data.admins,
        status,
        params,
        error: "",
      }
    }
  })),
  on(AdminActions.fetchAdminsSuccess, (state, { response }) => ({
    ...state,
    data: {
      ...state.data,
      admins: {
        ...state.data.admins,
        error: "",
        status: StateStatuses.SUCCESS,
        response
      }
    }
  })),
  on(AdminActions.fetchAdminsFailure, (state, { error }) => {
    return {
      ...state,
      data: {
        ...state.data,
        admins: {
          ...state.data.admins,
          error: error.error,
          status: StateStatuses.FAILED
        }
      }
    }
  }),

  on(AdminActions.createAdmin, (state, { data }) => ({
    ...state,
    data: {
      ...state.data,
      admins: {
        ...state.data.admins,
        new: data,
        status: StateStatuses.CREATING
      }
    }
  })),
  on(AdminActions.createAdminSuccess, (state) => ({
    ...state,
    data: {
      ...state.data,
      admins: {
        ...state.data.admins,
        error: "",
        status: StateStatuses.SUCCESS,
        new: { ...initialState.data.admins.new },
      }
    }
  })),
  on(AdminActions.createAdminFailure, (state, { error }) => {
    return {
      ...state,
      data: {
        ...state.data,
        admins: {
          ...state.data.admins,
          error: error.error,
          status: StateStatuses.FAILED
        }
      }
    }
  }),

  on(AdminActions.fetchResources, (state) => ({ ...state })),
  on(AdminActions.fetchResourcesRolesSuccess, (state, { roles }) => ({
    ...state,
    data: {
      ...state.data,
      resources: {
        ...state.data.resources,
        roles
      }
    }
  })),
  on(AdminActions.fetchResourcesOrganizationsSuccess, (state, { organizations }) => ({
    ...state,
    data: {
      ...state.data,
      resources: {
        ...state.data.resources,
        organizations
      }
    }
  })),
  on(AdminActions.fetchResourcesFailure, (state) => ({ ...state })),

  on(AdminActions.adminTearDown, () => initialStateCloned),

);
